import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { debounce } from "lodash";

import {
  // Grid,
  // Row,
  // Col,
  Table,
  FormControl,
  ButtonToolbar,
} from "react-bootstrap";

import {
  getText,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  Link,
  AxoDateTime,
  AsyncButton,
  LexButton,
  FileViewerModal,
  Icon,
  Dimensions,
  AxoLocal,
  AxoCheckbox,
  UploadButton,
  withRouter,
  AxoSelect,
} from "../../../utilities/LexUtilities";

import {
  PrintService,
  DataActions,
  NumberService,
  ModalService,
  DataStore,
  RoutingService,
  ContactService,
} from "../../../services/AxoServices";

import AccountingEntryEditForm from "../AccountingEntryEditForm";

class AccountingAccountPostingsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      printing: false,
      hideApprovedEntries: false,
      csv: [[]],
      showPostings: true, //Show postings by default
      pageSize: 1000,
      pageSizeIncrement: 1000,
      accountPostingsMap: {},
    };
    this.printRef = React.createRef();
  }

  static propTypes = {
    // entries: PropTypes.array.isRequired,
    contactMap: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
    selectedContact: PropTypes.object.isRequired,
    selectedPlan: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.updateAccountPostingsMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hideApprovedEntries !== this.state.hideApprovedEntries) {
      this.updateAccountPostingsMap();
      return;
    }

    if (prevProps === this.props) {
      return;
    }

    this.updateAccountPostingsMapDebounced();
  }

  updateAccountPostingsMapDebounced = debounce(() => {
    this.updateAccountPostingsMap();
  }, 500);

  updateAccountPostingsMap = () => {
    console.log("Updating postings map");
    let { hideApprovedEntries } = this.state;

    let filteredEntries = this.getFilteredEntries(this.props.entries);
    let accountPostingsMap = this.generateAccountPostings(filteredEntries);
    if (hideApprovedEntries) {
      accountPostingsMap =
        this.filterApprovedAccountPostings(accountPostingsMap);
    }

    this.setState({ accountPostingsMap });
  };

  onPrint = () => {
    this.setState({ printing: true }, () => {
      if (typeof window !== "undefined") {
        PrintService.printElement(document.getElementById("print-report"));
      }
      this.setState({ printing: false });
    });
  };

  onPrintReceiptsToZipArchive = async () => {
    return await this.onPrintReceipts(true);
  };

  onPrintReceipts = async (createZipArchive = false) => {
    let {
      selectedContact,
      selectedFiscalYearId,
      selectedStartDate,
      selectedEndDate,
      accountStartNumber,
      accountEndNumber,
      receiptStartNumber,
      receiptEndNumber,
      includeDraft,
    } = this.props;

    try {
      // this.receiptsModal.open();
      let result = await DataActions.printFilteredReceipts({
        contactId: selectedContact.id,
        label: getText("printReceiptsLabel", "Bilag"),
        filter: includeDraft ? "ApprovedOrPosted" : "Posted",
        fiscalYearId: selectedFiscalYearId,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        accountStartNumber,
        accountEndNumber,
        receiptStartNumber,
        receiptEndNumber,
        createZipArchive,
      });

      // if(result.ok) {
      //   let newFile = await result.json();
      //   this.fileModal.open([newFile]);
      // }

      if (result.ok) {
        ModalService.openAlertModal(
          <>
            <div>
              Bilagsdokumentet er under udarbejdelse og vil kunne findes under
              Dokumenter.
            </div>
            <div>Der vil blive vist en besked når dokumentet er færdigt.</div>
          </>
        );
      }

      return result.ok;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  render() {
    let {
      contactMap,
      handleSelectedClient,
      selectedContact,
      fiscalYears,
      includeDraft,
      onChangeIncludeDraft,
      onSelectFiscalYear,
      selectedFiscalYearId,
      selectedStartDate,
      selectedEndDate,
      onSelectStartDate,
      onSelectEndDate,
      fixedClient,
    } = this.props;

    let { showPostings } = this.state;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    return (
      <div style={{ marginLeft: "15px" }}>
        <Flexbox responsive style={{ marginTop: "15px" }}>
          {!fixedClient && (
            <FlexElement className="rightPadding">
              <ClientSearchBox
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                disabled={false}
                placeholder={getText(
                  "composeSelectClientPlaceholder1",
                  "Vælg klient"
                )}
              />
            </FlexElement>
          )}
          {selectedContact.id && !!fiscalYears ? (
            <React.Fragment>
              <FlexElement flexBasis="150px" className="rightPadding">
                <AxoSelect
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: (
                        <AxoLocal
                          entity="axoidcode100"
                          defaultValue={"Vælg regnskabsår"}
                        />
                      ),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement flexBasis="125px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={startDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectStartDate}
                  isValidDate={(currentDate) => {
                    return (
                      !currentDate.isBefore(
                        moment(selectedFiscalYear.startDate)
                      ) &&
                      !currentDate.isAfter(
                        moment(selectedFiscalYear.endDate)
                      ) &&
                      !currentDate.isAfter(endDateSelection)
                    );
                  }}
                />
              </FlexElement>
              <FlexElement flexBasis="125px" className="rightPadding">
                <AxoDateTime
                  utc
                  value={endDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectEndDate}
                  isValidDate={(currentDate) => {
                    return (
                      !currentDate.isBefore(
                        moment(selectedFiscalYear.startDate)
                      ) &&
                      !currentDate.isAfter(
                        moment(selectedFiscalYear.endDate)
                      ) &&
                      !currentDate.isBefore(startDateSelection)
                    );
                  }}
                />
              </FlexElement>
              <FlexElement className="rightPadding text-center">
                <LexButton
                  title={getText("axoEntityidcode142", "Print journal")}
                  disabled={!this.printRef.current || !showPostings}
                  onClick={this.onPrint}
                >
                  <Icon glyph="icon-fontello-print-3" />
                </LexButton>
              </FlexElement>
              <FlexElement className="rightPadding text-center">
                <AsyncButton
                  title={getText(
                    "axoEntityidcode143",
                    "Print bilagsfiler til PDF"
                  )}
                  disabled={
                    !selectedContact.id ||
                    !this.printRef.current ||
                    !showPostings
                  }
                  onClick={this.onPrintReceipts}
                >
                  <Icon glyph="icon-fontello-print-3" /> PDF
                </AsyncButton>
              </FlexElement>
              <FlexElement className="rightPadding text-center">
                <AsyncButton
                  title={getText(
                    "axoEntityidcode143",
                    "Print bilagsfiler til Zip fil"
                  )}
                  disabled={
                    !selectedContact.id ||
                    !this.printRef.current ||
                    !showPostings
                  }
                  onClick={this.onPrintReceiptsToZipArchive}
                >
                  <Icon glyph="icon-fontello-print-3" /> Zip
                </AsyncButton>
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoCheckbox
                  onChange={(event) =>
                    onChangeIncludeDraft(event.target.checked)
                  }
                  checked={includeDraft}
                />
                <AxoLocal entity="axoidcode33" defaultValue={"Medtag kladde"} />
              </FlexElement>
            </React.Fragment>
          ) : null}
        </Flexbox>
        {!!fiscalYears && selectedContact.id && fiscalYears.length === 0 ? (
          <div style={{ paddingTop: "15px" }}>
            <span style={{ fontSize: "18px" }}>
              <Link to="Setup">
                {!fixedClient ? (
                  <>
                    <AxoLocal
                      entity="axoidcode67"
                      defaultValue={"Opret et regnskabsår for"}
                    />
                    &nbsp;{selectedContact.firstName} {selectedContact.lastName}
                  </>
                ) : (
                  <>
                    {" "}
                    <AxoLocal
                      entity="axoidcode215"
                      defaultValue={"Opret et regnskabsår"}
                    />
                  </>
                )}
              </Link>
            </span>
          </div>
        ) : null}
        {!!fiscalYears && selectedContact.id && fiscalYears.length > 0
          ? this.renderAccountPostings()
          : null}
      </div>
    );
  }

  generateAccountPostings = (filteredEntries) => {
    let { financeAccountMap, taxSpecificationMap, taxSpecificationArray } =
      this.props;

    let { showPostings } = this.state;

    if (!showPostings) {
      return {};
    }

    //ToDo: Allow user to select buy vat account for Reverse Vat
    let buySpec =
      taxSpecificationArray.find((s) => s.taxType === "Ingoing") || {};
    let standardBuyVatAccount = financeAccountMap[buySpec.receivingAccountId];

    let accountPostings = {};
    filteredEntries.forEach((entry) => {
      let mainAccount = financeAccountMap[entry.financeAccountId];
      let hasTax = entry.taxAccountId > 0;
      let taxSpec =
        !!mainAccount && taxSpecificationMap[mainAccount.taxSpecificationId];
      let reverseVat =
        !!taxSpec && //Reverse vat is added to the amount.
        (taxSpec.taxType === "ForeignGoods" ||
          taxSpec.taxType === "ServiceReverseCharge");

      if (this.validateAccountNumber(mainAccount)) {
        let amountWithoutVat =
          hasTax && !reverseVat ? entry.amount - entry.vat : entry.amount;
        let vat = !reverseVat ? entry.vat : 0;
        if (!accountPostings[entry.financeAccountId]) {
          accountPostings[entry.financeAccountId] = [];
        }
        accountPostings[entry.financeAccountId].push({
          entryId: entry.id,
          entry: entry,
          number: mainAccount.number,
          date: entry.creationDate,
          description: entry.description,
          receiptNumber: entry.receiptNumber,
          amount: !entry.isIncome ? amountWithoutVat : -amountWithoutVat,
          vat: !entry.isIncome ? vat : -vat,
          receipt: entry.receipt,
        });
      }

      let vat = !entry.isIncome ? entry.vat : -entry.vat;
      //Reverse vat is posted to sales VAT(Credit), even though it is an expense(Debit) posting. Therefore, the VAT is negative.
      if (reverseVat) {
        vat = -vat;
      }

      //Reverse vat requires a standard sales vat account
      if (hasTax && (!reverseVat || !!standardBuyVatAccount)) {
        let taxAccount = financeAccountMap[entry.taxAccountId];
        if (this.validateAccountNumber(taxAccount)) {
          if (!accountPostings[entry.taxAccountId]) {
            accountPostings[entry.taxAccountId] = [];
          }

          accountPostings[entry.taxAccountId].push({
            entryId: entry.id,
            entry: entry,
            number: taxAccount.number,
            date: entry.creationDate,
            description: entry.description,
            receiptNumber: entry.receiptNumber,
            amount: vat,
            receipt: entry.receipt,
          });
        }
        //Make a counter posting on the buy vat account, so no VAT is paid
        if (hasTax && (!reverseVat || !!standardBuyVatAccount)) {
          if (reverseVat && this.validateAccountNumber(standardBuyVatAccount)) {
            if (!accountPostings[standardBuyVatAccount.id]) {
              accountPostings[standardBuyVatAccount.id] = [];
            }
            accountPostings[standardBuyVatAccount.id].push({
              entryId: entry.id,
              entry: entry,
              number: standardBuyVatAccount.number,
              date: entry.creationDate,
              description: entry.description,
              receiptNumber: entry.receiptNumber,
              amount: -vat,
              receipt: entry.receipt,
            });
          }
        }
      }

      let balanceAccount = financeAccountMap[entry.balanceFinanceAccountId];
      if (this.validateAccountNumber(balanceAccount)) {
        if (!accountPostings[entry.balanceFinanceAccountId]) {
          accountPostings[entry.balanceFinanceAccountId] = [];
        }
        accountPostings[entry.balanceFinanceAccountId].push({
          entryId: entry.id,
          entry: entry,
          number: balanceAccount.number,
          date: entry.creationDate,
          receiptNumber: entry.receiptNumber,
          description: entry.description,
          amount: entry.isIncome ? entry.amount : -entry.amount, //Balance account has opposite sign.
          receipt: entry.receipt,
        });
      }
    });

    return accountPostings;
  };

  filterApprovedAccountPostings = (accountPostingsMap) => {
    let newAccountPostingsMap = {};
    Object.keys(accountPostingsMap).forEach((key) => {
      let amountToEntryIds = {}; //amount to array of ids
      let entryIdsToBeRemoved = new Set();
      let sortedPostings = accountPostingsMap[key].sort((l, r) => {
        return moment(l.date).valueOf() - moment(r.date).valueOf();
      });

      sortedPostings.forEach((posting) => {
        if (entryIdsToBeRemoved.has(posting.entryId)) {
          return;
        }

        let amount = posting.amount;
        if (!!amountToEntryIds[-amount]) {
          let idArray = amountToEntryIds[-amount];
          var matchingEntryId = idArray[0];

          entryIdsToBeRemoved.add(matchingEntryId);
          entryIdsToBeRemoved.add(posting.entryId);

          amountToEntryIds[-amount] = idArray.filter(
            (id) => id !== matchingEntryId
          );
          if (amountToEntryIds[-amount].length === 0) {
            delete amountToEntryIds[-amount];
          }
        } else {
          if (!amountToEntryIds[amount]) {
            amountToEntryIds[amount] = [];
          }
          amountToEntryIds[amount].push(posting.entryId);
        }
      });

      newAccountPostingsMap[key] = accountPostingsMap[key].filter(
        (p) => !entryIdsToBeRemoved.has(p.entryId)
      );
    });
    return newAccountPostingsMap;
  };

  getFilteredEntries = (entries) => {
    let { receiptStartNumber, receiptEndNumber } = this.props;

    // let {
    //   hideApprovedEntries
    // } = this.state;

    let filteredEntries = entries.filter(
      (e) =>
        (!receiptStartNumber || e.receiptNumber >= receiptStartNumber) &&
        (!receiptEndNumber || e.receiptNumber <= receiptEndNumber)
    );

    // if(hideApprovedEntries) {
    //   //Hide matching entries
    //   let amounts = {};
    //   let entryIdsToBeRemoved = new Set();
    //   filteredEntries.forEach(entry => {
    //     let amount = entry.isIncome ? entry.amount : -entry.amount;
    //     if(!!amounts[-amount]) {
    //       entryIdsToBeRemoved.add(amounts[-amount]);
    //       entryIdsToBeRemoved.add(entry.id);
    //       delete amounts[-amount];
    //     }
    //     else {
    //       amounts[amount] = entry.id;
    //     }
    //   });

    //   filteredEntries = filteredEntries.filter(e => !entryIdsToBeRemoved.has(e.id));

    // //Hide entries approved during bank balancing
    // filteredEntries = filteredEntries.filter(e => !e.approved);
    // }

    return filteredEntries;
  };

  validateAccountNumber = (account) => {
    let { accountStartNumber, accountEndNumber } = this.props;

    if (!account) {
      return false;
    }

    return (
      (!accountStartNumber || account.number >= accountStartNumber) &&
      (!accountEndNumber || account.number <= accountEndNumber)
    );
  };

  onShowReceipt = (entryId) => {
    let entry = this.props.entries.find((p) => p.id === entryId);
    this.fileModal.open([entry.receipt]);
  };

  getPostingsCSV = (accountPostingsMap) => {
    let { selectedPlan } = this.props;

    let standardAccounts = selectedPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    let rows = [];
    let fullTotal = 0;
    standardAccounts.forEach((acc) => {
      let posting = accountPostingsMap[acc.id];
      if (!posting) {
        return;
      }

      let postingsArray = posting.sort((p1, p2) => {
        return new Date(p2.date) - new Date(p1.date);
      });

      var total = postingsArray.reduce((acc, posting) => {
        return acc + posting.amount;
      }, 0);

      fullTotal += total || 0;

      var totalVat = postingsArray.reduce((acc, posting) => {
        return acc + posting.vat;
      }, 0);

      let headerRow = [acc.number + " - " + acc.name, "", "", "", ""];

      let headerRow2 = [
        getText("TimeEntryFormntimeEntry", "Dato"),
        getText("axoidcode64", "Nummer"),
        getText("axoidcode77", "Note"),
        getText("InvoiceInvoicesum", "Beløb") +
          " " +
          getText("paymentDate10a", "u. moms"),
        getText("invoiPaymentattheSubtotalVAT", "Moms"),
      ];

      let postingRows = postingsArray.map((posting) => [
        moment(posting.date).format("L"),
        posting.receiptNumber,
        posting.description,
        !!posting.amount ? NumberService.formatDecimal(posting.amount) : "",
        !!posting.vat ? NumberService.formatDecimal(posting.vat) : "",
      ]);

      let totalRow = [
        "",
        "",
        getText("InvoiceInvoicetotal", "Total"),
        !isNaN(total) ? NumberService.formatDecimal(total) : "",
        !isNaN(totalVat) ? NumberService.formatDecimal(totalVat) : "",
      ];

      rows = [...rows, headerRow, headerRow2, ...postingRows, totalRow];
    });

    if (!!fullTotal) {
      rows.push([
        "",
        "",
        getText("InvoiceInvoicetotal", "Total"),
        NumberService.formatDecimal(fullTotal || 0),
        "",
      ]);
    }

    return rows;
  };

  onReceiptSelected = async (entryId, file) => {
    let { selectedContact } = this.props;

    let entry = this.props.entries.find((e) => e.id === entryId);
    if (!entry) {
      return;
    }

    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    let uploadResponse = await DataActions.uploadDocuments([file], {
      clientId: selectedContact.id,
    });
    let addedFiles = [];
    if (uploadResponse.ok) {
      addedFiles = await uploadResponse.json();
      if (addedFiles.length === 0) {
        return;
      }
    } else {
      return this.displayResponseWarnings(uploadResponse);
    }

    let addedFile = addedFiles[0];
    return DataActions.updatePostingEntryReceipt(entry, addedFile);
  };

  showWarning = (warningName) => {
    console.log("Displaying warning message");
    this.setState({ [warningName]: true });
    setTimeout(() => {
      this.setState({ [warningName]: false });
    }, 3000);
  };

  displayResponseWarnings = async (response) => {
    if (response.status === 400) {
      let text = await response.text();
      if (text === "Storage") {
        this.showWarning("showStorageWarning");
        return;
      } else {
        this.showWarning("showFileUploadError");
        return;
      }
    } else {
      this.showWarning("showFileUploadError");
      return;
    }
  };

  FileErrorMessage = () => {
    let { showFileMaxSizeWarning, showStorageWarning, showFileUploadError } =
      this.state;
    if (showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    if (showStorageWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarningA"
            defaultValue={"Ikke mere plads. Slet filer."}
          />
        </div>
      );
    }
    if (showFileUploadError) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesError"
            defaultValue={"Upload fejlede"}
          />
        </div>
      );
    }
    return <span></span>;
  };

  // onRemoveReceipt = (id) => {
  //   let { selectedContact, entries } = this.props;

  //   let entry = entries.find(e => e.id === id);
  //   if(!entry) {
  //     return;
  //   }

  //   if(!!entry.receipt) {
  //     DataStore.updateDocumentState({
  //       ...entry.receipt,
  //       client: selectedContact,
  //       receiptNumber: null
  //     });
  //   }

  //   DataActions.updatePostingEntryReceipt(entry, null);
  // }

  onDeleteReceiptForEntry = (entry) => {
    ModalService.openConfirmModal(
      <>
        {" "}
        <AxoLocal
          entity="axoEntityidcode26"
          defaultValue={"Vil du slette bilagsfilen permanent?"}
        />
      </>,
      async (value) => {
        if (!value) {
          return;
        }
        let receiptId = entry.receiptId;
        let response = await DataActions.updatePostingEntryReceipt(entry, null);
        if (!response.ok) {
          return;
        }
        DataActions.deleteDocument(receiptId);
      }
    );
  };

  sendMailToClient = (messageEntries) => {
    let {
      selectedContact,
      // entries,
      // selectedPlan
    } = this.props;

    if (!selectedContact.eMail) {
      return false;
    }

    // let messageEntries = entries
    //   .filter(e =>
    //     e.financeAccountId === selectedPlan.standardReconAccountId
    //     || e.balanceFinanceAccountId === selectedPlan.standardReconAccountId
    //   );

    let messageText =
      "<p>Kære " +
      (selectedContact.firstName || "") +
      " " +
      (selectedContact.lastName || "") +
      "</p>";
    messageText +=
      "<p> " +
      getText(
        "axoidcode175",
        "Vi anmoder om yderligere information vedrørende følgende posteringer:"
      ) +
      "</p>";
    messageEntries.forEach((entry) => {
      messageText +=
        "<p>" +
        moment(entry.creationDate).format("LL") +
        " - DKK" +
        entry.amount.toString() +
        " - " +
        entry.description +
        "</p>";
    });

    let message = {
      sender: null,
      receiver: null,
      externalMailReceiver: selectedContact.eMail,
      subject: getText(
        "axoidcode176",
        "Anmodning om oplysninger vedrørende bankposteringer"
      ),
      messageText,
      draft: true,
      attachments: [],
      bccUsers: [],
    };

    DataStore.setCachedMessage(message);
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  renderAccountPostings() {
    let {
      entries,
      selectedPlan,
      fiscalYears,
      selectedFiscalYearId,
      selectedStartDate,
      selectedEndDate,
      accountStartNumber,
      accountEndNumber,
      receiptStartNumber,
      receiptEndNumber,
      onUpdateAccountStartNumber,
      onUpdateAccountEndNumber,
      onUpdateReceiptStartNumber,
      onUpdateReceiptEndNumber,
      showControls,
      onToggleControls,
      onDelete,
      confirmDeleteId,
      confirmDelete,
      onCopy,
      confirmCopyId,
      onConfirmCopy,
      onEdit,
      confirmEditId,
      onConfirmEdit,
      showConfirmationMessage,
      containerHeight,
      selectedContact,
      fixedClient,
      financeAccountMap,
      taxSpecificationMap,
    } = this.props;

    let { printing, pageSize, accountPostingsMap } = this.state;

    let selectedFiscalYear = { id: 0 };
    if (!!fiscalYears) {
      selectedFiscalYear = fiscalYears.find(
        (f) => f.id === selectedFiscalYearId
      ) ||
        fiscalYears[0] || { id: 0 };
    }

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    let standardAccounts = selectedPlan.accounts.filter(
      (a) => a.type === "Standard"
    );

    let startAccount = standardAccounts.find(
      (a) => a.number === accountStartNumber
    );
    let endAccount = standardAccounts.find(
      (a) => a.number === accountEndNumber
    );

    console.log("Rendering");
    let confirmingUpdate =
      confirmDeleteId > 0 || confirmCopyId > 0 || confirmEditId > 0;

    let activeEntry = {};
    if (confirmingUpdate) {
      activeEntry = entries.find(
        (e) =>
          e.id === confirmDeleteId ||
          e.id === confirmCopyId ||
          e.id === confirmEditId
      );
    }

    let tableHeight = containerHeight - (showControls ? 330 : 215);

    var fullTotal = 0;

    let accountCount = 0;
    let rowCount = 0;

    //Paginate account postings for performance, if no account interval has been selected
    let pagedAccounts = standardAccounts;
    if (!printing && !accountEndNumber) {
      while (accountCount < standardAccounts.length && rowCount < pageSize) {
        let acc = standardAccounts[accountCount];
        let posting = accountPostingsMap[acc.id];
        rowCount += !!posting ? posting.length : 0;
        accountCount++;
      }

      pagedAccounts = standardAccounts.slice(0, accountCount);
    }

    let removeFileLabel = getText("axoidcode9", "Fjern bilagsfil");
    let uploadLabel = getText("DocumentTabViewUpload", "Upload");
    let deleteLabel = getText("axoidcode179", "Slet");
    let editLabel = getText("presentationMarketing30", "Rediger");
    let copyLabel = getText("CopyContact1", "Kopier");
    let dateLabel = getText("TimeEntryFormntimeEntry", "Dato");
    let numberLabel = getText("axoidcode64", "Nummer");
    let noteLabel = getText("axoidcode77", "Note");
    let amountLabel = getText("InvoiceInvoicesum", "Beløb");
    let withoutVatLabel = getText("axoidcode255", "uden moms");
    let vatLabel = getText("invoiPaymentattheSubtotalVAT", "Moms");
    let receiptLabel = getText(
      "CaseDataTableFixedlabelAddedToCasedocuments",
      "Bilag"
    );
    let totalLabel = getText("InvoiceInvoicetotal", "Total");

    let FileErrorMessage = this.FileErrorMessage;
    return (
      <div className="standardMaxWidth">
        {showControls ? (
          <React.Fragment>
            <Flexbox responsive collapseXS justified>
              <FlexElement className="rightPadding">
                <AxoLocal entity="axoidcode71" defaultValue={"Konto start"} />
                <AxoSelect
                  name="select"
                  menuPlacement="auto"
                  value={
                    !!startAccount
                      ? {
                          value: startAccount.number,
                          label:
                            startAccount.number.toString() +
                            " - " +
                            startAccount.name,
                        }
                      : {
                          value: "",
                          label: getText("axoAccounting6f", "Vælg konto"),
                        }
                  }
                  onChange={(selectedAccount) => {
                    if (!!selectedAccount) {
                      onUpdateAccountStartNumber(selectedAccount.value);
                    }
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "",
                      label: getText("axoAccounting6f", "Vælg konto"),
                    },
                    ...standardAccounts.map((f) => {
                      return {
                        value: f.number,
                        label: f.number.toString() + " - " + f.name,
                      };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoLocal entity="axoidcode72" defaultValue={"Konto slut"} />
                <AxoSelect
                  name="select"
                  menuPlacement="auto"
                  value={
                    !!endAccount
                      ? {
                          value: endAccount.number,
                          label:
                            endAccount.number.toString() +
                            " - " +
                            endAccount.name,
                        }
                      : {
                          value: "",
                          label: getText("axoAccounting6f", "Vælg konto"),
                        }
                  }
                  onChange={(selectedAccount) => {
                    if (!!selectedAccount) {
                      onUpdateAccountEndNumber(selectedAccount.value);
                    }
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "",
                      label: getText("axoAccounting6f", "Vælg konto"),
                    },
                    ...standardAccounts.map((f) => {
                      return {
                        value: f.number,
                        label: f.number.toString() + " - " + f.name,
                      };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoLocal
                  entity="axoidcode73"
                  defaultValue={"Bilagsnummer start"}
                />
                <FormControl
                  type="number"
                  value={
                    !receiptStartNumber || isNaN(receiptStartNumber)
                      ? ""
                      : receiptStartNumber
                  }
                  onChange={(event) =>
                    onUpdateReceiptStartNumber(event.target.value)
                  }
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <AxoLocal
                  entity="axoidcode74"
                  defaultValue={"Bilagsnummer slut"}
                />
                <FormControl
                  type="number"
                  value={
                    !receiptEndNumber || isNaN(receiptEndNumber)
                      ? ""
                      : receiptEndNumber
                  }
                  onChange={(event) =>
                    onUpdateReceiptEndNumber(event.target.value)
                  }
                />
              </FlexElement>
            </Flexbox>
          </React.Fragment>
        ) : null}
        {entries.length > 0 && (
          <Flexbox className="topPadding">
            <div className="axocolorbutton Colorbutton rightPadding">
              <LexButton onClick={onToggleControls}>
                {showControls ? (
                  <span>
                    <AxoLocal
                      key="hide"
                      entity="axoAccounting77"
                      defaultValue={"Skjul kontrolpanel"}
                    />
                  </span>
                ) : (
                  <span>
                    <AxoLocal
                      key="show"
                      entity="axoAccounting79"
                      defaultValue={"Vis kontrolpanel"}
                    />
                  </span>
                )}
              </LexButton>
            </div>
          </Flexbox>
        )}
        {confirmDeleteId > 0 && !!activeEntry.id ? (
          <Flexbox responsive style={{ paddingBottom: "10px" }}>
            <FlexElement className="rightPadding">
              <h4>
                (#{activeEntry.receiptNumber})
                <AxoLocal
                  entity="axoidcode101"
                  defaultValue={
                    " Der vil blive oprettet en modpostering i bogføringskladden."
                  }
                />
              </h4>
            </FlexElement>
            <FlexElement>
              <ButtonToolbar>
                <AsyncButton onClick={() => confirmDelete(activeEntry)}>
                  <AxoLocal
                    key="confirm"
                    entity="axoAccounting21"
                    defaultValue={"Bekræft sletning"}
                  />
                </AsyncButton>
                <LexButton onClick={() => onDelete(0)}>
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </LexButton>
              </ButtonToolbar>
            </FlexElement>
          </Flexbox>
        ) : null}
        {confirmCopyId > 0 && !!activeEntry.id ? (
          <Flexbox responsive style={{ paddingBottom: "10px" }}>
            <FlexElement className="rightPadding">
              <h4>
                (#{activeEntry.receiptNumber}) &nbsp;
                <AxoLocal
                  entity="axoidcode102"
                  defaultValue={
                    "Der vil blive oprettet en kopi i bogføringskladden."
                  }
                />
              </h4>
            </FlexElement>
            <FlexElement>
              <ButtonToolbar>
                <AsyncButton onClick={() => onConfirmCopy(activeEntry)}>
                  <AxoLocal
                    entity="axoidcode75"
                    defaultValue={"Bekræft kopiering"}
                  />
                </AsyncButton>
                <LexButton onClick={() => onCopy(0)}>
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </LexButton>
              </ButtonToolbar>
            </FlexElement>
          </Flexbox>
        ) : null}
        {confirmEditId > 0 && !!activeEntry.id ? (
          <div className="onTop" style={{ paddingBottom: "10px" }}>
            <AccountingEntryEditForm
              key={activeEntry.id}
              entry={activeEntry}
              financeAccountArray={selectedPlan.accounts}
              financeAccountMap={financeAccountMap}
              taxSpecificationMap={taxSpecificationMap}
              onSubmit={(newEntry) => onConfirmEdit(activeEntry, newEntry)}
              onCancel={() => onEdit(0)}
            />
          </div>
        ) : null}
        {confirmDeleteId === 0 &&
        confirmCopyId === 0 &&
        showConfirmationMessage ? (
          <div style={{ paddingBottom: "10px" }}>
            <h4>
              <Link to="Drafts" style={{ textDecoration: "underline" }}>
                <AxoLocal
                  entity="axoidcode76"
                  defaultValue={
                    "Posteringen blev oprettet i bogføringskladden."
                  }
                />
              </Link>
            </h4>
          </div>
        ) : null}
        <div className="text-center">
          <FileErrorMessage />
        </div>
        <div style={{ maxHeight: tableHeight, overflowY: "scroll" }}>
          <div id="print-report" ref={this.printRef}>
            {printing ? (
              <div>
                <div className="leftPadding text-center">
                  <span style={{ fontSize: "18px" }}>
                    {!fixedClient ? (
                      <>
                        <AxoLocal
                          entity="axoidcode65"
                          defaultValue={"Kontooversigt for"}
                        />
                        &nbsp;{selectedContact.firstName}{" "}
                        {selectedContact.lastName}
                      </>
                    ) : (
                      <>Kontokort</>
                    )}
                  </span>
                </div>
                <div
                  className="leftPadding text-center"
                  style={{ fontSize: "18px" }}
                >
                  {startDateSelection.format("L")} -{" "}
                  {endDateSelection.format("L")}
                </div>
              </div>
            ) : null}
            {pagedAccounts.map((acc) => {
              let posting = accountPostingsMap[acc.id];
              if (!posting) {
                return null;
              }

              let postingsArray = posting.sort((p1, p2) => {
                return new Date(p2.date) - new Date(p1.date);
              });

              var total = postingsArray.reduce((acc, posting) => {
                return acc + posting.amount;
              }, 0);

              fullTotal += total || 0;

              var totalVat = postingsArray.reduce((acc, posting) => {
                return acc + posting.vat;
              }, 0);

              return (
                <div key={acc.id}>
                  <h4>
                    {acc.number} - {acc.name} (
                    {acc.isCredit ? <span>C</span> : <span>D</span>})
                  </h4>
                  <Table bordered condensed hover>
                    <tbody>
                      <tr>
                        <th>{dateLabel}</th>
                        <th>{numberLabel}</th>
                        <th>{noteLabel}</th>
                        <th>
                          {amountLabel}&nbsp;
                          {withoutVatLabel}
                        </th>
                        <th>{vatLabel}</th>
                        <th className="hidden-print">{receiptLabel}</th>
                        <th className="hidden-print"></th>
                      </tr>
                      {postingsArray.map((posting) => (
                        <tr
                          key={
                            acc.id +
                            "-" +
                            posting.entryId +
                            "-" +
                            posting.amount
                          }
                        >
                          <td>{moment(posting.date).format("L")}</td>
                          <td>{posting.receiptNumber}</td>
                          <td>{posting.description}</td>
                          <td className="text-right">
                            {!!posting.amount ? (
                              <React.Fragment>
                                {NumberService.formatDecimal(posting.amount)}
                              </React.Fragment>
                            ) : null}
                          </td>
                          <td className="text-right">
                            {!!posting.vat ? (
                              <React.Fragment>
                                {NumberService.formatDecimal(posting.vat)}
                              </React.Fragment>
                            ) : null}
                          </td>
                          <td className="hidden-print">
                            {posting.receipt ? (
                              <>
                                <Icon
                                  className="editable"
                                  role="button"
                                  onClick={() =>
                                    this.onShowReceipt(posting.entryId)
                                  }
                                  glyph="icon-fontello-attach-7"
                                />
                                &nbsp;
                                <Icon
                                  title={removeFileLabel}
                                  // title='Fjern bilagsfil'
                                  className="editable"
                                  role="button"
                                  onClick={() =>
                                    this.onDeleteReceiptForEntry(posting.entry)
                                  }
                                  glyph="icon-fontello-trash-1"
                                />
                              </>
                            ) : (
                              <>
                                <UploadButton
                                  asIcon
                                  onSelected={(file) =>
                                    this.onReceiptSelected(
                                      posting.entryId,
                                      file
                                    )
                                  }
                                >
                                  {uploadLabel}
                                </UploadButton>
                              </>
                            )}
                          </td>
                          <td className="hidden-print">
                            &nbsp;
                            <Icon
                              title={deleteLabel}
                              className="editable"
                              role="button"
                              onClick={() => onDelete(posting.entryId)}
                              glyph="icon-fontello-trash-1"
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Icon
                              title={editLabel}
                              className="editable"
                              role="button"
                              onClick={() => onEdit(posting.entryId)}
                              glyph="icon-fontello-edit"
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Icon
                              title={copyLabel}
                              className="editable"
                              role="button"
                              onClick={() => onCopy(posting.entryId)}
                              glyph="icon-fontello-docs-1"
                            />
                            {/* <a href='Delete' onClick={(event) => {
                              event.preventDefault();
                              onDelete(posting.entryId);
                            }}><Icon glyph='icon-fontello-trash-1'/></a>&nbsp;
                            <a href='Edit' onClick={(event) => {
                              event.preventDefault();
                              onEdit(posting.entryId);
                            }}>
                              {editLabel}</a>&nbsp;
                            <a href='Copy' onClick={(event) => {
                              event.preventDefault();
                              onCopy(posting.entryId);
                            }}>
                              {copyLabel}</a> */}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{totalLabel}</b>
                        </td>
                        <td className="text-right">
                          {!isNaN(total)
                            ? NumberService.formatDecimal(total)
                            : ""}
                        </td>
                        <td className="text-right">
                          {!isNaN(totalVat)
                            ? NumberService.formatDecimal(totalVat)
                            : ""}
                        </td>
                        <td className="hidden-print"></td>
                        <td className="hidden-print"></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              );
            })}

            {!!fullTotal ? (
              <div className="rightPadding" style={{ maxWidth: "600px" }}>
                <Table bordered condensed hover className="text-right">
                  <tbody>
                    <tr>
                      <th>{totalLabel}</th>
                    </tr>
                    <tr>
                      <td>{NumberService.formatDecimal(fullTotal || 0)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : null}
            {pagedAccounts.length < standardAccounts.length && (
              <div className="text-center">
                <LexButton
                  style={{ minWidth: "250px" }}
                  onClick={() =>
                    this.setState((prevState) => ({
                      pageSize:
                        prevState.pageSize + prevState.pageSizeIncrement,
                    }))
                  }
                >
                  <AxoLocal entity="axoidcode218" defaultValue={"Vis mere"} />
                </LexButton>
              </div>
            )}
          </div>
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
      </div>
    );
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function () {
    return window.innerHeight;
  },
})(withRouter(AccountingAccountPostingsView));
