import React from "react";
import PropTypes from "prop-types";
import { Table, Column, Cell } from "fixed-data-table-2";
import moment from "moment";

import Select from "react-select";

import { DataActions, ApiService, ContactService } from "../../../services/AxoServices";

import {
  TableBase,
  Dimensions,
  DataListWrapper,
  SortHeaderCell,
  AxoLocal,
  LexButton,
  getText,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  InlineEdit,
  InlineNumberEdit,
  AsyncButton,
  Link,
  AxoCheckbox,
  DepartmentsModal,
} from "../../../utilities/LexUtilities";

import { ButtonToolbar } from "react-bootstrap";

import FiscalYearForm from "./FiscalYearForm";

const defaultColumnDefs = {
  name: {
    width: 150,
    shown: true,
  },
  startDate: {
    width: 150,
    shown: true,
  },
  endDate: {
    width: 150,
    shown: true,
  },
  vatPeriod: {
    width: 150,
    shown: true,
  },
  receiptNumberStart: {
    width: 150,
    shown: true,
  },
  actions: {
    width: 400,
    shown: true,
  },
};

class AccountingSetupView extends TableBase {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    contactMap: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
    handleSelectedDepartmentClient: PropTypes.func.isRequired,
    onSubmitFiscalYear: PropTypes.func.isRequired,
    selectedContact: PropTypes.object.isRequired,
    hasPendingVatFunc: PropTypes.func.isRequired,
    onTransferBalance: PropTypes.func.isRequired,
    onCancelTransfer: PropTypes.func.isRequired,
    doTransferBalance: PropTypes.func.isRequired,
    toggleSkipReceiptScan: PropTypes.func.isRequired,
    toggleSkipPDFConversion: PropTypes.func.isRequired,
    toggleSkipInvoiceDashboard: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.name = "AccountingSetupView";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = TableBase.generateDefaultSortIndices(
      props.entries
    );
    let tableEntries = AccountingSetupView.addDataStringsToEntries(
      props.entries
    );
    this.state = {
      tableEntries: tableEntries,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {},
      maxColumnWidth: 500,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",

      creatingFiscalYear: false,
      showPendingVatWarning: false,
      confirmDeleteFiscalYearId: 0,
    };

    this.departmentsModal = React.createRef();
  }

  static addDataStringsToEntries = (entries) => {
    return entries;
    // return entries.map( entry => { return {...entry } } );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let { sortedDataList, searchText } = prevState;

    let tableEntries = AccountingSetupView.addDataStringsToEntries(
      nextProps.entries
    );
    let filteredEntries = AccountingSetupView.getFilteredEntries(
      tableEntries,
      searchText
    );

    return TableBase.getNewEntries(
      tableEntries,
      filteredEntries,
      sortedDataList
    );
  }

  updateStateProperty = (propertyName, value) => {
    this.setState({ [propertyName]: value });
  };

  onSearch = (event) => {
    let searchText = event.target.value;
    let { tableEntries } = this.state;
    let entries = AccountingSetupView.getFilteredEntries(
      tableEntries,
      searchText
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  static getFilteredEntries = (entries, searchText) => {
    if (!searchText) {
      return entries;
    }

    let filteredEntries = entries.filter((entry) => {
      return entry.name.includes(searchText);
    });

    return filteredEntries;
  };

  onAddFiscalYear = () => {
    this.updateStateProperty("creatingFiscalYear", true);
  };

  onUpdateFiscalYearProperty = (fiscalYear, propertyName, value) => {
    this.props.onSubmitFiscalYear({
      ...fiscalYear,
      [propertyName]: value,
    });
  };

  onSubmitFiscalYear = (fiscalYear) => {
    this.props.onSubmitFiscalYear({
      ...fiscalYear,
      contactInfoId: this.props.selectedContact.id,
    });

    this.setState({ creatingFiscalYear: false });
  };

  // {year: 2016, month: 3, day: 13, hour: 10}
  getFiscalYearStartValue = () => {
    let { entries } = this.props;
    if (entries.length === 0) {
      let today = new Date();
      return {
        name: "",
        startDate: moment
          .utc({ year: today.getFullYear(), month: 0, day: 1, hour: 0 })
          .format(),
        endDate: moment
          .utc({ year: today.getFullYear(), month: 11, day: 31, hour: 0 })
          .format(),
        // startDate: moment.utc(new Date(today.getFullYear(), 0, 1)).format(),
        // endDate: moment.utc(new Date(today.getFullYear(), 11, 31)).format(),
        vatPeriod: "Quarter",
        receiptNumberStart: 1,
      };
    }

    let latestEntry = entries.sort((e1, e2) => {
      return new Date(e2.startDate) - new Date(e1.startDate);
    })[0];

    var endDate = new Date(latestEntry.endDate);

    return {
      name: "",
      startDate: moment
        .utc({
          year: endDate.getFullYear(),
          month: endDate.getMonth(),
          day: endDate.getDate(),
          hour: 0,
        })
        .add(1, "days")
        .format(),
      endDate: moment
        .utc({
          year: endDate.getFullYear() + 1,
          month: endDate.getMonth(),
          day: endDate.getDate(),
          hour: 0,
        })
        .format(),
      vatPeriod: "Quarter",
      receiptNumberStart: 0,
    };
  };

  deleteSelectedFiscalYear = async () => {
    let { confirmDeleteFiscalYearId } = this.state;
    if (!confirmDeleteFiscalYearId) {
      return false;
    }

    let response = await DataActions.deleteFiscalYear(
      confirmDeleteFiscalYearId
    );
    if (!response.ok) {
      return false;
    }

    this.setState({ confirmDeleteFiscalYearId: 0 });
    return true;
  };

  render() {
    let {
      entries,
      contactMap,
      handleSelectedClient,
      handleSelectedDepartmentClient,
      selectedContact,
      activeFiscalYear,
      onCancelTransfer,
      doTransferBalance,
      showConfirmationMessage,
      toggleSkipReceiptScan,
      toggleSkipPDFConversion,
      toggleSkipInvoiceDashboard,
      fixedClient,
      toggleView,
      clientPlan,
      doRemoveDepartment,
      userProfile
    } = this.props;

    let {
      creatingFiscalYear,
      showPendingVatWarning,
      confirmDeleteFiscalYearId,
    } = this.state;

    return (
      <div>
        <Flexbox responsive style={{ marginTop: "15px" }}>
          {/* {!fixedClient && ( */}
            <FlexElement className="rightPadding">
              <ClientSearchBox
                clients={contactMap.contacts}
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                disabled={fixedClient}
                placeholder={getText(
                  "composeSelectClientPlaceholder1",
                  "Vælg klient"
                )}
              />
            </FlexElement>
          {/* )} */}
          {!!toggleView && selectedContact.id && (
            <FlexElement className="rightPadding">
              <LexButton onClick={toggleView}>
                <AxoLocal
                  entity="axoEntityidcode40"
                  defaultValue={"Vis kontoplan"}
                />
              </LexButton>
            </FlexElement>
          )}
          {/* <FlexElement>
            <div style={{maxWidth: '600px'}}>
              <FormGroup controlId='inputWithIcon'>
                <InputGroup>
                  <AxoLocal componentClass={FormControl}  
                    type='text' 
                    value={this.state.searchText} 
                    onChange={this.onSearch}
                    componentAttribute="placeholder" // Søg...
                    entity='ContactTableViewplaceholderonSearch'/>
                  <FormControl.Feedback>
                    <Icon glyph='icon-fontello-search'/>
                  </FormControl.Feedback>
                  </InputGroup>
              </FormGroup>
            </div>
          </FlexElement> */}
          {(selectedContact.departments || []).length > 0 && (
            <FlexElement className="rightPadding underline">
              <div
                className="largeText"
                role="button"
                title="Se afdelinger"
                onClick={() => this.departmentsModal.current.open()}
              >
                {selectedContact.departments.length} afdeling
                {selectedContact.departments.length > 1 && <>er</>}
              </div>
            </FlexElement>
          )}
          {!!selectedContact.departmentOwner ? (
            <FlexElement className="rightPadding">
              <div className="largeText">
                Afdeling af "
                {ContactService.getContactFullName(
                  selectedContact.departmentOwner
                )}
                "
              </div>
            </FlexElement>
          ) : (
            <>
              <FlexElement className="rightPadding">
                <div className="largeText">Tilføj afdeling:</div>
              </FlexElement>
              <FlexElement className="rightPadding">
                <ClientSearchBox
                  clients={contactMap.contacts}
                  customFilter={(c) =>
                    c.id !== selectedContact.id && !c.departmentOwnerId
                  }
                  count={contactMap.count}
                  handleSelectedClient={(selection) =>
                    handleSelectedDepartmentClient(selectedContact, selection)
                  }
                  clearOnSelect
                  placeholder="Vælg ny afdeling"
                />
              </FlexElement>
            </>
          )}
          {/* {!!selectedContact.id && userProfile.userType === "Admin" && ( */}
          <FlexElement className="rightPadding">
            <LexButton 
              href={ApiService.getSafTExportPath(selectedContact.id)}>
              <AxoLocal entity='axoAccounting4' defaultValue={'Eksporter'}/> SAF-T fil
            </LexButton>
          </FlexElement>
          {/* )} */}
        </Flexbox>
        {selectedContact.id ? (
          <React.Fragment>
            <div className="axobg">
              {!!selectedContact.clientUserName ? (
                <Flexbox className="bottomPadding">
                  <FlexElement className="rightPadding">
                    <AxoCheckbox
                      onChange={toggleSkipReceiptScan}
                      checked={!selectedContact.skipReceiptScan}
                    />
                    <AxoLocal
                      entity="axoidcode34"
                      defaultValue={"Scan bilag på klientsiden"}
                    />
                  </FlexElement>
                  <FlexElement className="rightPadding">
                    <AxoCheckbox
                      onChange={toggleSkipPDFConversion}
                      checked={!selectedContact.skipPDFConversion}
                    />
                    {/* MissingEntity */}
                    Konverter bilag til PDF format
                  </FlexElement>
                  <FlexElement className="rightPadding">
                    <AxoCheckbox
                      onChange={toggleSkipInvoiceDashboard}
                      checked={selectedContact.enableInvoiceDashboard}
                    />
                    <AxoLocal
                      entity="axoEntityidcode41"
                      defaultValue={"Aktiver fakturaer på klientsiden"}
                    />
                  </FlexElement>
                </Flexbox>
              ) : null}
              <Flexbox
                spaceBetween
                alignCenter
                className="rightPadding leftPadding"
              >
                <Flexbox responsive>
                  {!activeFiscalYear.id ? (
                    <React.Fragment>
                      <FlexElement className="rightPadding">
                        <h4>
                          <AxoLocal
                            entity="axoidcode68"
                            defaultValue={"Regnskabsår"}
                          />
                        </h4>
                      </FlexElement>
                      <FlexElement className="rightPadding">
                        <LexButton
                          disabled={creatingFiscalYear}
                          onClick={this.onAddFiscalYear}
                        >
                          <AxoLocal
                            entity="axoidcode86"
                            defaultValue={"Tilføj regnskabsår"}
                          />
                        </LexButton>
                      </FlexElement>
                    </React.Fragment>
                  ) : null}
                  {!!confirmDeleteFiscalYearId ? (
                    <React.Fragment>
                      <FlexElement className="rightPadding axored largeText">
                        <AxoLocal
                          entity="axoEntityidcode42"
                          defaultValue={
                            "Dette vil slette regnskabsåret permanent. Er du sikker?"
                          }
                        />
                      </FlexElement>
                      <FlexElement className="rightPadding">
                        <AsyncButton onClick={this.deleteSelectedFiscalYear}>
                          <AxoLocal
                            entity="friendRequestTimelineonApproveRequest"
                            defaultValue={"Godkend"}
                          />
                        </AsyncButton>
                      </FlexElement>
                      <FlexElement className="rightPadding">
                        <LexButton
                          onClick={() =>
                            this.setState({ confirmDeleteFiscalYearId: 0 })
                          }
                        >
                          <AxoLocal
                            entity="axoAccounting22"
                            defaultValue={"Fortryd"}
                          />
                        </LexButton>
                      </FlexElement>
                    </React.Fragment>
                  ) : null}
                  {showPendingVatWarning ? (
                    <FlexElement className="axored">
                      <h4>
                        <AxoLocal
                          entity="axoidcode103"
                          defaultValue={
                            "Du skal bogføre moms før regnskabsåret kan afsluttes."
                          }
                        />
                      </h4>
                    </FlexElement>
                  ) : null}
                  {activeFiscalYear.id ? (
                    <React.Fragment>
                      <FlexElement className="rightPadding">
                        <h4>
                          <AxoLocal
                            entity="axoidcode104"
                            defaultValue={
                              "Overfør balancetal fra regnskabsåret."
                            }
                          />
                          &nbsp;
                          {moment(activeFiscalYear.startDate).format("L")}{" "}
                          -&nbsp;
                          {moment(activeFiscalYear.endDate).format("L")}?
                        </h4>
                      </FlexElement>
                      <FlexElement>
                        <ButtonToolbar>
                          <AsyncButton
                            onClick={() =>
                              doTransferBalance(selectedContact.id)
                            }
                          >
                            <AxoLocal
                              entity="friendRequestTimelineonApproveRequest"
                              defaultValue={"Godkend"}
                            />
                          </AsyncButton>
                          <LexButton onClick={onCancelTransfer}>
                            <AxoLocal
                              entity="axoAccounting22"
                              defaultValue={"Fortryd"}
                            />
                          </LexButton>
                        </ButtonToolbar>
                      </FlexElement>
                    </React.Fragment>
                  ) : null}
                  {!activeFiscalYear.id && showConfirmationMessage ? (
                    <div>
                      <h4>
                        <Link
                          to="Drafts"
                          style={{ textDecoration: "underline" }}
                        >
                          <AxoLocal
                            entity="axoidcode105"
                            defaultValue={
                              "Balanceposteringerne blev oprettet i bogføringskladden."
                            }
                          />
                        </Link>
                      </h4>
                    </div>
                  ) : null}
                </Flexbox>
              </Flexbox>
              {!clientPlan.resultAccountId && (
                <div className="axored largeText leftPadding bottomPadding">
                  <AxoLocal
                    entity="axoEntityidcode44"
                    defaultValue={
                      "Konto for Resultat af tidligere år skal vælges i kontoplanen."
                    }
                  />
                </div>
              )}
            </div>
            {creatingFiscalYear ? (
              <div>
                <FiscalYearForm
                  onSubmit={this.onSubmitFiscalYear}
                  startValue={this.getFiscalYearStartValue()}
                  isFirst={entries.length === 0}
                  getVatPeriodName={this.getVatPeriodName}
                  onCancel={() => this.setState({ creatingFiscalYear: false })}
                />
              </div>
            ) : null}
            {!!entries ? this.renderTable() : null}
          </React.Fragment>
        ) : null}
        <DepartmentsModal
          ref={this.departmentsModal}
          departmentOwner={selectedContact}
          doRemoveDepartment={doRemoveDepartment}
        />
      </div>
    );
  }

  getVatPeriodName(vatPeriod) {
    switch (vatPeriod) {
      case "Quarter":
        return getText("axoidcode147", "Kvartal");
      case "HalfYear":
        return getText("axoidcode148", "Halvårlig");
      case "Month":
        return getText("axoidcode149", "Månedlig");
      case "Year":
        return getText("axoidcode150", "Årlig");
      default:
        return getText("axoidcode147", "Kvartal");
    }
  }

  onGenerateStartBalanceForNextYear = async (fiscalYearId) => {
    let { hasPendingVatFunc, selectedContact, onTransferBalance, entries } =
      this.props;

    let hasPendingVat = await hasPendingVatFunc(
      selectedContact.id,
      fiscalYearId
    );
    if (hasPendingVat) {
      this.setState({ showPendingVatWarning: true });
      setTimeout(() => {
        this.setState({ showPendingVatWarning: false });
      }, 4000);

      return false;
    }

    onTransferBalance(entries.find((e) => e.id === fiscalYearId));

    return true;
  };

  getVatPeriodOptions = () => {
    return [
      { value: "Quarter", label: this.getVatPeriodName("Quarter") },
      { value: "HalfYear", label: this.getVatPeriodName("HalfYear") },
      { value: "Month", label: this.getVatPeriodName("Month") },
      { value: "Year", label: this.getVatPeriodName("Year") },
    ];
  };

  renderTable = () => {
    let { sortedDataList, colSortDirs, columnDefs, confirmDeleteFiscalYearId } =
      this.state;
    let { containerHeight, containerWidth, activeFiscalYear } = this.props;
    let tableWidth = containerWidth;

    let vatPeriodOptions = this.getVatPeriodOptions();

    return (
      <Table
        rowHeight={40}
        rowsCount={sortedDataList.getSize()}
        width={tableWidth}
        height={containerHeight}
        isColumnResizing={false}
        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
        headerHeight={40}
      >
        <Column
          columnKey="name"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.name}
            >
              <AxoLocal entity="axoAccounting7" defaultValue={"Navn"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                <InlineEdit
                  value={entry.name || "---"}
                  change={(input) =>
                    this.onUpdateFiscalYearProperty(entry, "name", input.value)
                  }
                />
              </Cell>
            );
          }}
          width={columnDefs.name.width}
          isResizable={true}
        />
        <Column
          columnKey="startDate"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.startDate}
            >
              <AxoLocal entity="axoidcode87" defaultValue={"Startdato"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {moment(entry.startDate).format("L")}
              </Cell>
            );
          }}
          width={columnDefs.startDate.width}
          isResizable={true}
        />
        <Column
          columnKey="endDate"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.endDate}
            >
              <AxoLocal entity="axoidcode88" defaultValue={"Slutdato"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {moment(entry.endDate).format("L")}
              </Cell>
            );
          }}
          width={columnDefs.endDate.width}
          isResizable={true}
        />
        <Column
          columnKey="vatPeriod"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.vatPeriod}
            >
              <AxoLocal entity="axoidcode106" defaultValue={"Momsperiode"} />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                {!!entry.locked ? (
                  <div className="leftPadding">
                    {this.getVatPeriodName(entry.vatPeriod)}
                  </div>
                ) : (
                  <Select
                    name="vatPeriodSelect"
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    // style={{ width: '200px' }}
                    value={vatPeriodOptions.find(
                      (v) => v.value === entry.vatPeriod
                    )}
                    onChange={(selection) => {
                      if (!!selection) {
                        this.onUpdateFiscalYearProperty(
                          entry,
                          "vatPeriod",
                          selection.value
                        );
                      }
                    }}
                    noOptionsMessage={() => ""}
                    options={vatPeriodOptions}
                  />
                )}
              </Cell>
            );
          }}
          width={columnDefs.vatPeriod.width}
          isResizable={true}
        />
        <Column
          columnKey="receiptNumberStart"
          header={
            <SortHeaderCell
              onSortChange={this._onSortChange}
              sortDir={colSortDirs.receiptNumberStart}
            >
              <AxoLocal
                entity="axoidcode107"
                defaultValue={"Nummerserie start"}
              />
            </SortHeaderCell>
          }
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            let startValue = parseInt(entry.receiptNumberStart, 10);
            return (
              <Cell key={entry.id} {...props}>
                {entry.locked ? (
                  <span>
                    {!startValue ? (
                      <React.Fragment>
                        <AxoLocal
                          entity="axoidcode108"
                          defaultValue={"Fortsæt"}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>#{startValue}</React.Fragment>
                    )}
                  </span>
                ) : (
                  <InlineNumberEdit
                    value={entry.receiptNumberStart}
                    format={(value) => {
                      let intValue = parseInt(value, 10);
                      return (
                        <span>
                          {!intValue ? (
                            <React.Fragment>
                              <AxoLocal
                                entity="axoidcode108"
                                defaultValue={"Fortsæt"}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>#{intValue}</React.Fragment>
                          )}
                        </span>
                      );
                    }}
                    change={(input) =>
                      this.onUpdateFiscalYearProperty(
                        entry,
                        "receiptNumberStart",
                        parseInt(input.value, 10)
                      )
                    }
                  />
                )}
              </Cell>
            );
          }}
          width={columnDefs.receiptNumberStart.width}
          isResizable={true}
        />
        <Column
          columnKey="actions"
          header={<Cell></Cell>}
          cell={(props) => {
            let entry = sortedDataList.getObjectAt(props.rowIndex);
            return (
              <Cell key={entry.id} {...props}>
                <ButtonToolbar>
                  <LexButton
                    onClick={() =>
                      this.onUpdateFiscalYearProperty(
                        entry,
                        "locked",
                        !entry.locked
                      )
                    }
                  >
                    {entry.locked ? (
                      <span>
                        <AxoLocal
                          key="activate"
                          entity="axoidcode89"
                          defaultValue={"Aktiver regnskabsår"}
                        />
                      </span>
                    ) : (
                      <span>
                        <AxoLocal
                          key="lock"
                          entity="axoidcode90"
                          defaultValue={"Lås regnskabsår"}
                        />
                      </span>
                    )}
                  </LexButton>
                  {props.rowIndex > 0 ? (
                    <AsyncButton
                      disabled={activeFiscalYear.id || entry.locked}
                      onClick={() =>
                        this.onGenerateStartBalanceForNextYear(entry.id)
                      }
                      hideOkIcon
                    >
                      <AxoLocal
                        entity="axoidcode91"
                        defaultValue={"Overfør ultimo balance"}
                      />
                    </AsyncButton>
                  ) : null}
                  <LexButton
                    disabled={confirmDeleteFiscalYearId === entry.id}
                    onClick={() =>
                      this.setState({ confirmDeleteFiscalYearId: entry.id })
                    }
                  >
                    {" "}
                    <AxoLocal entity="axoidcode179" defaultValue={"Slet"} />
                  </LexButton>
                </ButtonToolbar>
              </Cell>
            );
          }}
          width={columnDefs.actions.width}
          isResizable={true}
        />
      </Table>
    );
  };
}

export default Dimensions({
  elementResize: true,
  getHeight: function (element) {
    return window.innerHeight - 260;
  },
})(AccountingSetupView);
