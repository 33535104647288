import React from "react";

import {
  ApiService,
  DataStore,
  DataActions,
  ModalService,
  ContactService,
} from "../../../services/AxoServices";

import { Consumer, withRTKData } from "../../../utilities/LexUtilities";

import AccountingSetupView from "./AccountingSetupView";

class AccountingSetupContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeFiscalYear: {},
      showConfirmationMessage: false,
    };
  }

  handleSelectedClient = (selectedClient) => {
    DataStore.setSelectedContact(selectedClient);
    DataStore.fetchFiscalYears();
  };

  handleSelectedDepartmentClient = async (
    selectedClient,
    selectedDepartmentClient
  ) => {
    let { updateContact } = this.props;

    let departmentName = ContactService.getContactFullName(
      selectedDepartmentClient
    );
    let clientName = ContactService.getContactFullName(selectedClient);

    ModalService.openConfirmModal(
      `Gør "${departmentName}" til en afdeling af "${clientName}"?`,
      async (value) => {
        if (!value) return;

        try {
          await updateContact({
            ...selectedDepartmentClient,
            departmentOwner: selectedClient,
            departmentOwnerId: selectedClient.id,
          }).unwrap();

          updateContact({
            ...selectedClient,
            departments: [
              ...(selectedClient.departments || []),
              selectedDepartmentClient,
            ], //Sending this list to the server is not necessary, but we need the optimistic updating
          });
        } catch {
          return false;
        }
      }
    );

    return true;
  };

  doRemoveDepartment = async (selectedClient, selectedDepartmentClient) => {
    let { updateContact } = this.props;

    try {
      await updateContact({
        ...selectedDepartmentClient,
        departmentOwner: null,
        departmentOwnerId: null,
      }).unwrap();

      updateContact({
        ...selectedClient,
        departments: (selectedClient.departments || []).filter(
          (d) => d.id !== selectedDepartmentClient.id
        ), //Sending this list to the server is not necessary, but we need the optimistic updating
      });
    } catch {
      return false;
    }

    return true;
  };

  onSubmitFiscalYear = (fiscalYear) => {
    if (fiscalYear.id) {
      return DataActions.updateFiscalYear(fiscalYear);
    }
    return DataActions.createFiscalYear(fiscalYear);
  };

  hasPendingVat = async (clientId, fiscalYearId) => {
    let vatResult = await ApiService.getVatReport({ clientId, fiscalYearId });
    return vatResult.ingoing > 0 || vatResult.outgoing > 0;
  };

  onTransferBalance = (activeFiscalYear) => {
    this.setState({
      activeFiscalYear,
    });
  };

  onCancelTransfer = () => {
    this.setState({
      activeFiscalYear: {},
    });
  };

  doTransferBalance = async (clientId) => {
    let { activeFiscalYear } = this.state;

    let response = await DataActions.transferBalance({
      clientId,
      fiscalYearId: activeFiscalYear.id,
    });

    if (!!response && !!response.ok) {
      let newEntries = await response.json();
      DataStore.setState({
        importantEntryIds: new Set([
          ...DataStore.getStore().importantEntryIds,
          ...newEntries.map((e) => e.id),
        ]),
      });

      this.setState({
        showConfirmationMessage: true,
        activeFiscalYear: {},
      });
      this.timeout = setTimeout(
        () => this.setState({ showConfirmationMessage: false }),
        4000
      );
      return true;
    }
    return false;
  };

  toggleSkipReceiptScan = () => {
    let { selectedContact, updateContact } = this.props;

    updateContact({
      ...selectedContact,
      skipReceiptScan: !selectedContact.skipReceiptScan,
    });
  };

  toggleSkipPDFConversion = () => {
    let { selectedContact, updateContact } = this.props;

    updateContact({
      ...selectedContact,
      skipPDFConversion: !selectedContact.skipPDFConversion,
    });
  };

  toggleSkipInvoiceDashboard = () => {
    let { selectedContact, updateContact } = this.props;

    updateContact({
      ...selectedContact,
      enableInvoiceDashboard: !selectedContact.enableInvoiceDashboard,
    });
  };

  render() {
    return (
      <div style={{ maxWidth: "1200px", marginLeft: "15px" }}>
        <AccountingSetupView
          {...this.props}
          {...this.state}
          hasPendingVatFunc={this.hasPendingVat}
          onTransferBalance={this.onTransferBalance}
          onCancelTransfer={this.onCancelTransfer}
          doTransferBalance={this.doTransferBalance}
          handleSelectedClient={this.handleSelectedClient}
          handleSelectedDepartmentClient={this.handleSelectedDepartmentClient}
          onSubmitFiscalYear={this.onSubmitFiscalYear}
          toggleSkipReceiptScan={this.toggleSkipReceiptScan}
          toggleSkipPDFConversion={this.toggleSkipPDFConversion}
          toggleSkipInvoiceDashboard={this.toggleSkipInvoiceDashboard}
          doRemoveDepartment={this.doRemoveDepartment}
        />
      </div>
    );
  }
}

class AccountingSetupConsumer extends React.PureComponent {
  componentDidMount() {
    DataStore.fetchFiscalYears();
    DataStore.fetchClientPlan();
  }

  mapStoreToProps = (store) => {
    return {
      entries: store.fiscalYears || [],
      clientPlan: store.clientPlan,
      userProfile: store.userProfile
    };
  };

  render() {
    return (
      <Consumer>
        {(store) => (
          <AccountingSetupContainer
            {...this.mapStoreToProps(store)}
            {...this.props}
          />
        )}
      </Consumer>
    );
  }
}

export default withRTKData(AccountingSetupConsumer);
