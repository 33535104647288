import React from "react";

import { withRouter } from "../../utilities/LexUtilities";

import CreateContactForm from "./CreateContactForm";
import QueryString from "query-string";

class EmbeddedCreateContactForm extends React.Component {
  render() {
    let { toEmail, userName } = this.props.match.params;
    let { location } = this.props;

    let query = QueryString.parse(location.search);

    if (!toEmail) {
      return <div>Der skal angives en email adresse i linket.</div>;
    }
    if (!userName) {
      return <div>Der skal angives et brugernavn i linket</div>;
    }

    let decodedEmail = decodeURIComponent(toEmail);
    let decodedUserName= decodeURIComponent(userName);

    return (
      <CreateContactForm
        // messageLabel=''
        sendLabel="Send tilmelding"
        toEmail={decodedEmail}
        toUserName={decodedUserName}
        singleName={!!query.singleName}
        showMemberTypeField={!!query.showMemberTypeField}
        showCityField={!!query.showCityField}
        showGenderField={!!query.showGenderField}
        hideCountry
        externalStyle={{
          background: query.background || "",
          color: query.color || "",
        }}
        // attachFiles
      />
    );
  }
}

export default withRouter(EmbeddedCreateContactForm);
