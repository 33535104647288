import React from "react";
import moment from "moment";
import CaseTabView from "./Cases/CaseTabView";
import { withRouter } from "react-router-dom";

import {
  DataStore,
  DataActions,
  RoutingService,
} from "../services/AxoServices";

import {
  withRTKData,
  withRTKMessageData,
  withRTKCaseData,
} from "../utilities/LexUtilities";

class CaseContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false, //Show all buttons on small screens
      userName: "",
      userProfile: {},
      userSettings: {},

      caseMap: {
        cases: [],
        saved: [],
        savedCount: 0,
        drafts: [],
        draftCount: 0,
        closed: [],
        closedCount: 0,
        trashed: [],
        trashedCount: 0,
      },

      thisWeek: [],
      thisMonth: [],

      openCases: [],
      labels: [],

      documentMap: {},
      userGroup: {},
      sharedCases: [],
      friends: [],
      showSharedCasesDetails: false,
      hideSidebar: false,
    };
    if (typeof Storage !== "undefined") {
      this.state.showSharedCasesDetails = JSON.parse(
        localStorage.getItem("showSharedCasesDetails")
      );
    }
    if (props.match.params.id) {
      this.selectFromRoute = true;
    }

    this.name = "CaseContainer";
  }

  handleActiveState = (eventKey) => {
    this.props.history.push(RoutingService.getPath("Cases/" + eventKey));
  };

  isCaseThisWeek = (currentYear, currentWeek, caseModel) => {
    var caseWeek = moment(caseModel.actionDate).format("W");
    var caseYear = new Date(caseModel.actionDate).getFullYear();
    return currentYear === caseYear && currentWeek === caseWeek;
  };

  isCaseThisMonth = (currentYear, currentMonth, caseModel) => {
    var caseMonth = new Date(caseModel.actionDate).getMonth();
    var caseYear = new Date(caseModel.actionDate).getFullYear();
    return currentYear === caseYear && currentMonth === caseMonth;
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    DataStore.subscribe(this.name, (store) => {
      let currentWeek = moment(Date.now()).format("W");
      let currentMonth = new Date(Date.now()).getMonth();
      let currentYear = new Date(Date.now()).getFullYear();

      this.setState({
        financeAccountPlans: store.financeAccountPlans,
        userName: store.userName,
        userProfile: store.userProfile,
        userSettings: store.userSettings,
        labels: store.labels.filter((l) => l.type === 1),
        documentMap: store.documentMap,
        userGroup: store.userGroup,
        sharedCases: store.sharedCases,
        friends: store.friends,
        caseMap: store.caseMap,
        // important: sortedCases.filter( c => c.priority > 1 && !c.closed && !c.trashed),
        // active: sortedCases.filter( c => c.status === 'Active' && !c.closed && !c.trashed),
        thisWeek: store.caseMap.saved.filter(
          (c) =>
            this.isCaseThisWeek(currentYear, currentWeek, c) &&
            !c.closed &&
            !c.trashed
        ),
        thisMonth: store.caseMap.saved.filter(
          (c) =>
            this.isCaseThisMonth(currentYear, currentMonth, c) &&
            !c.closed &&
            !c.trashed
        ),
        openCases: this.state.openCases.filter(
          (caseId) =>
            store.caseMap.cases.find((c) => c.id === caseId) ||
            this.state.sharedCases.find((c) => c.id === caseId)
        ),
        showDropdowns: store.showDropdowns,
        hideSidebar: store.hideSidebar,
      });

      if (this.selectFromRoute && store.caseMap.cases.length > 0) {
        var routeCase = store.caseMap.cases.find(
          (c) => c.id === parseInt(this.props.match.params.id, 10)
        );
        this.selectFromRoute = false;
        if (!routeCase) {
          this.handleActiveState("List");
          return;
        }
        this.onOpenCase(routeCase.id);
      }
    });

    DataStore.fetchUserName();
    DataStore.initializeUserProfile();
    DataStore.initializeUserSettings();
    DataStore.initializeCases();
    DataStore.initializeSharedCases();
    DataStore.initializeDocuments();
    DataStore.initializeLabels();
    DataStore.initializeUserGroup();
    DataStore.initializeFriends();
    // DataStore.initializeFinanceAccountPlans();
  }

  componentWillUnmount() {
    DataStore.unsubscribe(this.name);
    DataStore.selectCase(-1);

    DataStore.resetCasePageSize();
  }

  onCloseCreate = (caseId) => {
    this.handleActiveState("List");
  };

  onFinishDraftFromCreate = async (caseId) => {
    await DataStore.fetchCases();

    //Wait until cases have been fetched before redirecting
    setTimeout(() => {
      this.onOpenCase(caseId);
    }, 5000);
  };

  onCloseEdit = (caseId) => {
    this.removeOpenCase(caseId);
  };

  onOpenCase = (caseId) => {
    caseId = parseInt(caseId, 10);
    if (this.state.openCases.indexOf(caseId) === -1) {
      this.state.openCases.push(caseId);
      this.setState({ openCases: this.state.openCases });
    }
    this.handleActiveState(this.getCaseKey(caseId));
    DataStore.selectCase(caseId);
  };

  onRemoveCaseTab = (caseId, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.removeOpenCase(caseId);
  };

  getCaseKey = (caseId) => {
    return "Single/" + caseId;
  };

  removeOpenCase = (caseId) => {
    var openCases = this.state.openCases;
    var caseIndex = openCases.indexOf(caseId);
    if (caseIndex > -1) {
      //Can occur if the case has been deleted
      openCases.splice(caseIndex, 1);
      this.setState({ openCases: openCases });
    }

    this.handleActiveState("List");
  };

  onToggleCards = () => {
    var userSettings = Object.assign({}, this.state.userSettings);
    userSettings.showCaseCards = !userSettings.showCaseCards;
    DataActions.updateUserSettings(userSettings);
  };

  onToggleSharedCasesDetails = () => {
    var newValue = !this.state.showSharedCasesDetails;
    if (typeof Storage !== "undefined") {
      localStorage.setItem("showSharedCasesDetails", JSON.stringify(newValue));
    }
    this.setState({ showSharedCasesDetails: newValue });
  };

  expandTabs = () => {
    this.setState({ expanded: true });
  };

  getActiveTab = (props) => {
    let path = props.location.pathname.toLowerCase();
    if (path.includes("list")) {
      return "List";
    }
    if (path.includes("sharings")) {
      return "Sharings";
    }
    if (path.includes("create")) {
      return "Create";
    }
    if (path.includes("draft")) {
      return "Draft";
    }
    if (path.includes("week")) {
      return "Week";
    }
    if (path.includes("month")) {
      return "Month";
    }
    if (path.includes("closed")) {
      return "Closed";
    }
    if (path.includes("trash")) {
      return "Trash";
    }
    if (path.includes("single")) {
      return "Single/" + props.match.params.id;
    }
    return "List";
  };

  render() {
    return (
      <CaseTabView
        activeTab={this.getActiveTab(this.props)}
        {...this.props}
        {...this.state}
        key={this.state.hideSidebar.toString()}
        onToggleSharedCasesDetails={this.onToggleSharedCasesDetails}
        getCaseKey={this.getCaseKey}
        handleActiveState={this.handleActiveState}
        onFinishDraftFromCreate={this.onFinishDraftFromCreate}
        onCloseCreate={this.onCloseCreate}
        onCloseEdit={this.onCloseEdit}
        onOpenCase={this.onOpenCase}
        onRemoveCaseTab={this.onRemoveCaseTab}
        removeOpenCase={this.removeOpenCase}
        onToggleCards={this.onToggleCards}
        expandTabs={this.expandTabs}
      />
    );
  }
}
export default withRTKCaseData(
  withRTKMessageData(withRTKData(withRouter(CaseContainer)))
);
