import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import {
  Row,
  Col,
  Grid,
  Button,
  FormControl,
  ButtonGroup,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";

import {
  ApiService,
  RoutingService,
  DataStore,
  DataActions,
  ModalService,
  UserInfoService,
  LocalizationService,
  MessageService,
  FileInfoService,
} from "../../services/AxoServices";

import {
  Icon,
  FileViewerModal,
  ctx,
  AxoLocal,
  AsyncButton,
  LoadingIndicator,
  AxoCheckbox,
  FileViewer,
  Flexbox,
  FlexElement,
  LexButton,
  DownloadButton,
} from "../../utilities/LexUtilities";
import { fakeBaseQuery } from "@reduxjs/toolkit/dist/query";

const ImageDisplayStatus = {
  NONE: 0,
  CONFIRM: 1,
  SHOW: 2,
};

class MailView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      printing: false,
    };
  }

  getPath = (path) => {
    return RoutingService.getPath(path);
  };

  handleNewClick = () => {
    this.props.history.push(this.getPath("mailbox/compose"));
  };

  handleReplyClick = (e) => {
    let { message } = this.props;

    DataStore.setCachedMessage(
      MessageService.makeReplyMessage({ message, replyAll: false })
    );
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  handleReplyAllClick = (e) => {
    let { message } = this.props;

    DataStore.setCachedMessage(
      MessageService.makeReplyMessage({ message, replyAll: true })
    );
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  handleBackClick = (e) => {
    this.props.history.goBack();
  };

  onForwardMessage = () => {
    DataStore.setCachedMessage(
      MessageService.makeForwardMessage(this.props.message)
    );
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  handleTextareaClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleReplyClick();
  };

  onDelete = (e) => {
    this.deleteMessage(this.props.message);
  };

  deleteMessage = (currentMessage) => {
    let moveToTrash =
      !currentMessage.trashed && !currentMessage.draft && !currentMessage.spam;

    ModalService.openConfirmModal(
      moveToTrash ? (
        <AxoLocal
          key="trashed"
          entity="DocumentmessageDataActionstrashed"
          defaultValue={"Flyt mail til skraldespanden?"}
        />
      ) : (
        <AxoLocal
          key="trash"
          entity="DocumentmessageDataActions"
          defaultValue={"Vil du slette denne mail permanent?"}
        />
      ),
      (value) => {
        if (value) {
          this.handleDeletion(currentMessage, moveToTrash)
            .then(() => {
              this.props.history.push(this.getPath("mailbox/inbox"));
            })
            .catch((reason) => {
              console.log(reason);
            });
        }
      }
    );
  };

  handleDeletion = (currentMessage, moveToTrash) => {
    let { updateMessageFromHeader, deleteMessage } = this.props;

    if (moveToTrash) {
      return updateMessageFromHeader({
        ...currentMessage,
        trashed: true,
      }).unwrap();
    } else {
      if (currentMessage.externalUniqueId) {
        return this.props.deleteExternal(currentMessage).then((response) => {
          if (response.ok) {
            return deleteMessage(currentMessage.id).unwrap();
          }
          return Promise.reject("External mail could not be deleted");
        });
      } else {
        return deleteMessage(currentMessage.id).unwrap();
      }
    }
  };

  onPrint = () => {
    this.setState({ printing: true }, () => {
      if (typeof window !== "undefined") {
        window.print();
      }
      this.setState({ printing: false });
    });
  };

  onListBack = () => {
    this.props.onListBack();
  };

  onListFront = () => {
    this.props.onListFront();
  };

  onListForward = () => {
    this.props.onListForward();
  };

  componentDidMount() {
    this.handleShowMessage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message.id !== this.props.message.id) {
      this.handleShowMessage();
    }
  }

  handleShowMessage = () => {
    let { message, shown, updateMessageFromHeader } = this.props;

    // var receiverId = currentMessage.receiver ? currentMessage.receiver.id : 0;
    if (!message || message.read) {
      return false;
    }
    // if(this.props.userProfile.id === receiverId) {
    if (shown === "inbox" || shown === "spam") {
      updateMessageFromHeader({
        ...message,
        read: true,
      });
    }
    if (message.externalUniqueId) {
      this.props.markExternalAsRead(message);
    }
  };

  onFileClick(index) {
    let { message } = this.props;
    if (!message.externalUniqueId) {
      this.fileModal.open(message.attachments, {
        messageId: message.id,
        fileIndex: index,
      });
    } else {
      this.fileModal.open(message.attachments, {
        messageId: null,
        fileIndex: index,
      });
    }
  }

  renderAttachmentSummary = (currentMessage) => {
    if (currentMessage.attachments.length === 0) {
      return null;
    }
    var totalSize = currentMessage.attachments.reduce((acc, attachment) => {
      return acc + attachment.size;
    }, 0);
    var fileString =
      currentMessage.attachments.length > 1 ? (
        <AxoLocal
          key="Files"
          entity="Searchattachmentsfiles"
          defaultValue={"filer"}
        />
      ) : (
        <AxoLocal
          key="File"
          entity="Searchattachmentsfile"
          defaultValue={"fil"}
        />
      );

    return (
      <p>
        <strong>
          <AxoLocal
            entity="currentMessageAttachedfiles"
            defaultValue={"Vedhæftede filer"}
          />
        </strong>
        &nbsp;
        <span className="editable">
          {currentMessage.attachments.length} {fileString}, (
          {Math.floor(totalSize / 1024) + " KB"})
        </span>
      </p>
    );
  };

  renderAttachmentNodes = (currentMessage) => {
    let { selectedAttachmentId, onSelectAttachment } = this.props;

    return currentMessage.attachments.map((attachment, index) => {
      return (
        <div key={attachment.id} className="paragraph standardMaxWidth">
          <AxoCheckbox
            checked={selectedAttachmentId === attachment.id}
            onChange={() => onSelectAttachment(attachment.id)}
          />
          &nbsp;
          <span
            className="attachment-link"
            role="button"
            onClick={this.onFileClick.bind(this, index)}
          >
            <Icon glyph="icon-fontello-attach" />
            &nbsp;
            <span>
              <strong>{attachment.fileName}</strong>&nbsp;
              <span className="editable">({attachment.sizeString})</span>
            </span>
          </span>
          <a
            className="pull-right"
            href={ApiService.getAttachmentDownloadPath(
              attachment.id,
              currentMessage.id
            )}
          >
            <AxoLocal entity="axolexDownload6" defaultValue={"Download"} />
          </a>
        </div>
      );
    });
  };

  getReceiverName = (currentMessage) => {
    if (currentMessage.externalMailReceiver) {
      return this.getDisplayString(
        currentMessage.externalMailReceiver,
        currentMessage.externalReceiverDisplayName
      );
    }

    if (currentMessage.primaryReceiver) {
      return UserInfoService.getDisplayName(currentMessage.primaryReceiver);
    }
    return UserInfoService.getDisplayName(currentMessage.receiver);
  };

  getCCReceiverLabel = (currentMessage) => {
    return this.getDisplayString(
      currentMessage.externalMailCCReceiver,
      currentMessage.externalCCReceiverDisplayName
    );
  };

  getBCCReceiverLabel = (currentMessage) => {
    return this.getDisplayString(
      currentMessage.externalMailBCCReceiver,
      currentMessage.externalBCCReceiverDisplayName
    );
  };

  getDisplayString(emails, displayNames) {
    let hasDisplayNames = !!(displayNames || "")
      .replace(/;/g, "")
      .replace(/ /g, "");
    if (hasDisplayNames) {
      let names = displayNames.split(";");
      let mails = emails.replace(/ /g, "").split(";");
      let receiverLabel = "";
      mails.forEach((mail, index) => {
        if (!!names[index]) {
          receiverLabel += `"${names[index]}" <${mail}>`;
        } else {
          receiverLabel += mail;
        }
        if (index < mails.length - 1) {
          receiverLabel += ";";
        }
      });
      return receiverLabel;
    }
    return emails;
  }

  onLabelSelected = (labelId, event) => {
    var currentMessage = this.props.message;
    DataActions.addLabelToMessage(currentMessage.id, labelId);
  };

  PreviewImage = (props) => {
    // let previewSource = MessageService.secureImageSource(props.message.previewImageSource);
    // var src = previewSource || UserInfoService.getProfileImageSource(props.message.sender);
    var src = UserInfoService.getProfileImageSource(props.message.sender);
    if (!src) {
      return <div style={{ display: "inline-block", width: "40px" }}></div>;
    }
    return (
      <img
        alt=""
        src={src}
        width="40"
        height="40"
        className="border-#4CAF50 hidden-xs"
      />
    );
  };

  onGoToLabels = () => {
    this.props.history.push("Labels");
  };

  showFirstButton = () => {
    let { messages, messageIndex } = this.props;
    return messageIndex > 0 && messages.length > 1;
  };

  showBackButton = () => {
    return this.props.messageIndex >= 1;
  };

  showNextButton = () => {
    let { messageIndex } = this.props;
    if (messageIndex < 0) {
      return false;
    }
    return messageIndex <= this.props.messages.length - 2;
  };

  getExternalSenderName(message) {
    if (message.externalSenderDisplayName) {
      return (
        '"' +
        message.externalSenderDisplayName +
        '" <' +
        message.externalSender +
        ">"
      );
    }
    return message.externalSender;
  }

  saveAttachments = async (message) => {
    try {
      for (let file of message.attachments) {
        //Process files in sequence
        let response = await DataActions.copyDocument(file.id);
        if (!response.ok) {
          throw new Error("File could not be saved");
        }
      }
      return true;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  };

  onSummarize = async () => {
    let {message} = this.props;
    if(!message.messageText) {
      return false;
    }

    let newText =  message.messageText.replace(/img[^>]*>/g,"");
    newText = newText.substr(0, Math.min(newText.length - 1, 10000))

    let response = await ApiService.summarizeCollaborationString(`Skriv en kort opsummering af teksten: ${newText}`);
    if(!response.ok) {
      return false;
    }

    let summary = await response.text();

    ModalService.openAlertModal(summary);
    return true;
  }

  onSummarizeAsList = async () => {
    let {message} = this.props;
    if(!message.messageText) {
      return false;
    }

    let newText =  message.messageText.replace(/img[^>]*>/g,"");
    newText = newText.substr(0, Math.min(newText.length - 1, 10000))

    let response = await ApiService.summarizeCollaborationString(`Skriv en liste over tekstens vigtigste pointer: ${newText}`);
    if(!response.ok) {
      return false;
    }

    let summary = await response.text();
    ModalService.openAlertModal(summary);
    return true;
  }

  onGenerateAnswer = async () => {
    let {message} = this.props;
    if(!message.messageText) {
      return false;
    }

    let newText =  message.messageText.replace(/img[^>]*>/g,"");
    newText = newText.substr(0, Math.min(newText.length - 1, 10000))
    let fromIndex = newText.indexOf('Fra:');
    if(fromIndex === -1) {
      fromIndex = newText.indexOf('From:');
    }
    if(fromIndex > 10) {
      newText = newText.substr(0, fromIndex);
    }

    let response = await ApiService.summarizeCollaborationString(`Skriv et svar på den øverste mail: ${newText}`);
    if(!response.ok) {
      return false;
    }

    let summary = await response.text();
    ModalService.openAlertModal(summary);
    return true;
  }

  render() {
    var {
      message,
      userProfile,
      selectedAttachmentId,
      imageDisplayStatus,
      onShowExternalImages,
      onTrustSender,
    } = this.props;

    let { printing } = this.state;

    var currentMessage = message;

    let selectedAttachment = null;
    if (!!selectedAttachmentId) {
      selectedAttachment = message.attachments.find(
        (a) => a.id === selectedAttachmentId
      );
    }

    var unusedLabels = this.props.labels.filter((l) => {
      return !l.messageIds.find((id) => id === parseInt(currentMessage.id, 10));
    });
    var labelNodes = unusedLabels.map((label) => {
      return (
        <MenuItem
          onSelect={this.onLabelSelected}
          key={label.id}
          eventKey={label.id}
        >
          {label.name}
        </MenuItem>
      );
    });
    var labelDropDown = (
      <DropdownButton
        title={
          <AxoLocal entity="LabelInboxPanelLabelA" defaultValue="Mapper" />
        }
        id="dropdown-hover-headers"
        className="axoDropdownButton"
      >
        <MenuItem style={{ fontSize: "15px", fontWeight: "normal" }} header>
          {
            <AxoLocal
              entity="inboxSelectafolder"
              defaultValue={"Vælg en mappe"}
            />
          }
        </MenuItem>
        {labelNodes}
        <MenuItem divider />
        <MenuItem header>
          <AxoLocal
            entity="inboxCreateLabelManage"
            defaultValue={"Administrer"}
            style={{ fontSize: "15px", fontWeight: "normal" }}
          />{" "}
        </MenuItem>
        <MenuItem onSelect={this.onGoToLabels} eventKey="GoToLabels">
          <AxoLocal
            entity="inboxGotomailfolders"
            defaultValue={"Gå til mailmapper"}
          />
        </MenuItem>
      </DropdownButton>
    );

    let multipleReceivers = !!currentMessage.externalMailCCReceiver;
    multipleReceivers =
      multipleReceivers ||
      (!!currentMessage.externalMailReceiver &&
        currentMessage.externalMailReceiver.split(";").length > 1);

    let PreviewImage = this.PreviewImage;
    return (
      <div>
        <Grid fluid>
          <Row className="hidden-print">
            <Col xs={8} style={{ paddingTop: "10px" }}>
              <ButtonToolbar>
                <ButtonGroup>
                  <Button
                    style={{ width: "100px" }}
                    className="Lex-button-2"
                    onClick={this.handleBackClick}
                  >
                    <Icon glyph="icon-fontello-left-bold-1" />
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    className="Lex-button-2"
                    onClick={this.handleNewClick}
                  >
                    <Icon glyph=" icon-fontello-pencil-alt-1" />
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="hidden-xs">
                  <Button
                    className="Lex-button-2"
                    onClick={this.handleReplyClick}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-reply"
                      componentAttribute="title"
                      entity="inboxhandleReplyClick"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="inboxhandleReplyClick"
                      defaultValue="Besvar"
                    />
                  </Button>
                  <Button
                    className="Lex-button-2"
                    disabled={!multipleReceivers}
                    onClick={this.handleReplyAllClick}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-reply"
                      componentAttribute="title"
                      entity="inboxhandleReplyClick"
                    />
                    {/* MissingEntity */}
                    &nbsp;Besvar alle
                  </Button>
                  <Button
                    className="Lex-button-2"
                    onClick={this.onForwardMessage}
                  >
                    <AxoLocal
                      componentClass={Icon}
                      glyph="icon-fontello-forward"
                      componentAttribute="title"
                      entity="DocumentinvoiceFileattachmentparamsforward"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="inboxForwardButton"
                      defaultValue="Videresend"
                    />
                  </Button>
                  { userProfile.userType === "Admin" && 
                    <>
                      <AsyncButton
                        onClick={this.onSummarize}
                      >
                        Opsummer
                      </AsyncButton>
                      <AsyncButton
                        onClick={this.onSummarizeAsList}
                      >
                        Liste
                      </AsyncButton>
                      <AsyncButton
                        onClick={this.onGenerateAnswer}
                      >
                        AI Svar
                      </AsyncButton>
                    </>
                  } 
                </ButtonGroup>
                <ButtonGroup className="hidden-xs">
                  <Button
                    disabled={!this.showBackButton()}
                    className="Lex-button-2"
                    onClick={this.onListBack}
                  >
                    <AxoLocal
                      entity="DragDropCalendarPrevious"
                      defaultValue={"Forrige"}
                    />
                  </Button>
                  <Button
                    disabled={!this.showFirstButton()}
                    className="Lex-button-2"
                    onClick={this.onListFront}
                  >
                    <AxoLocal
                      entity="DragDropCalendarfirst"
                      defaultValue={"Første"}
                    />
                  </Button>
                  <Button
                    disabled={!this.showNextButton()}
                    className="Lex-button-2"
                    onClick={this.onListForward}
                  >
                    <AxoLocal
                      entity="DragDropCalendarNext"
                      defaultValue={"Næste"}
                    />
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="hidden-xs">
                  <Button className="Lex-button-2" onClick={this.onDelete}>
                    <Icon glyph="icon-fontello-trash-1" />
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="hidden-xs hidden-sm">
                  <Button className="Lex-button-2" onClick={this.onPrint}>
                    <Icon glyph="icon-fontello-print-1" />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
            <Col xs={4} className="text-right">
              <div className="flexbox-right">
                <div style={{ paddingTop: "10px" }}>
                  {labelDropDown}
                  &nbsp; &nbsp;
                </div>
                <img
                  alt=""
                  className="img-circle"
                  src={UserInfoService.getMyProfileImageSource(
                    this.props.userProfile
                  )}
                  width="40"
                  height="40"
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <hr style={{ margin: 0 }} />
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <div className="flexbox-layout">
                <div className="flexbox-standard">
                  <PreviewImage message={currentMessage} />
                  <div>
                    <div className="axoblack">
                      {" "}
                      &nbsp; &nbsp;
                      <strong className="axoblack">
                        <AxoLocal
                          entity="MailContainerComposeFrom"
                          defaultValue={"Fra"}
                        />
                        :
                      </strong>
                      &nbsp;
                      {currentMessage.externalSender
                        ? this.getExternalSenderName(message)
                        : UserInfoService.getDisplayName(currentMessage.sender)}
                    </div>
                    <div className="axoblack">
                      {" "}
                      &nbsp; &nbsp;
                      <strong className="axoblack">
                        <AxoLocal
                          entity="MailContainerComposeTo"
                          defaultValue={"Til"}
                        />
                        :
                      </strong>
                      &nbsp;{this.getReceiverName(currentMessage)}{" "}
                      {currentMessage.external ? "(Ekstern mail)" : ""}
                    </div>
                    {!!currentMessage.externalMailCCReceiver && (
                      <div className="axoblack">
                        {" "}
                        &nbsp; &nbsp;
                        <strong className="axoblack">CC:</strong>
                        &nbsp;{this.getCCReceiverLabel(currentMessage)}
                      </div>
                    )}
                    {!!currentMessage.externalMailBCCReceiver && (
                      <div className="axoblack">
                        {" "}
                        &nbsp; &nbsp;
                        <strong className="axoblack">BCC:</strong>
                        &nbsp;{this.getBCCReceiverLabel(currentMessage)}
                      </div>
                    )}
                    {currentMessage.primaryReceiver ? (
                      <div className="axoblack">
                        {" "}
                        &nbsp; &nbsp;
                        <strong className="axoblack">
                          <AxoLocal
                            entity="MailViewBCCLabel"
                            defaultValue={"BCC til mig"}
                          />
                        </strong>
                      </div>
                    ) : null}
                    <div className="axoblack">
                      <strong>
                        {" "}
                        &nbsp; &nbsp;
                        <AxoLocal entity="axoidcode151" defaultValue={"Emne"} />
                        :{" "}
                      </strong>
                      {LocalizationService.getMessageSubject(currentMessage)}
                      {imageDisplayStatus === ImageDisplayStatus.CONFIRM ? (
                        <>
                          &nbsp;
                          <LexButton onClick={onShowExternalImages}>
                            Vis eksterne billeder
                          </LexButton>
                          {!!currentMessage.externalSender && (
                            <>
                              &nbsp;
                              <LexButton onClick={onTrustSender}>
                                Vis altid billeder fra{" "}
                                {currentMessage.externalSender}
                              </LexButton>
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                    {currentMessage.caseModel ? (
                      <div className="axoblack">
                        <strong>
                          {" "}
                          &nbsp; &nbsp;
                          <AxoLocal
                            entity="MailViewCaseLabel"
                            defaultValue={"Sag"}
                          />
                          :{" "}
                        </strong>
                        {currentMessage.caseModel.caseNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="axoblack text-right hidden-xs">
                  <div style={{ position: "relative", top: 5 }}>
                    <div>
                      <AxoLocal
                        entity="MailContainerComposePosted"
                        defaultValue={"Sendt"}
                      />
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <small>{moment(currentMessage.date).format("L LT")}</small>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
        <hr style={{ marginTop: 0 }} />
        <Flexbox className="leftPadding">
          <FlexElement flex={1}>
            <div>
              {!message.messageText && message.messageText !== "" ? (
                <LoadingIndicator />
              ) : (
                <div
                  style={
                    !printing
                      ? { maxHeight: "550px", overflowY: "scroll" }
                      : null
                  }
                >
                  <div className="standardMaxWidth">
                    {LocalizationService.getMessageText(
                      currentMessage,
                      printing
                    )}
                  </div>
                </div>
              )}
            </div>
          </FlexElement>
          {!!selectedAttachment ? (
            <FlexElement
              flex={1}
              style={{ maxHeight: "550px", overflowY: "scroll" }}
            >
              <FileViewer file={selectedAttachment} maxHeight={550} />
            </FlexElement>
          ) : null}
        </Flexbox>
        <hr />
        <Grid fluid className="hidden-print">
          <Row>
            <Col xs={12}>
              {!!currentMessage.attachments ? (
                <div className="AttachmentSummarya">
                  {this.renderAttachmentSummary(currentMessage)}
                  {this.renderAttachmentNodes(currentMessage)}
                  {currentMessage.attachments.length > 0 ? (
                    <ButtonToolbar style={{ marginBottom: "10px" }}>
                      <AsyncButton
                        onClick={() => this.saveAttachments(currentMessage)}
                      >
                        <AxoLocal
                          entity="axoidcode49"
                          defaultValue={"Gem alle internt"}
                        />
                      </AsyncButton>
                      <DownloadButton
                        title="Download alle filer som en Zip fil"
                        href={ApiService.getAttachmentZipDownloadPath(
                          currentMessage.id
                        )}
                      >
                        Zip
                      </DownloadButton>
                      {!!currentMessage.attachments.find((a) =>
                        FileInfoService.isImage(a)
                      ) && (
                        <DownloadButton
                          href={ApiService.getAttachmentPDFDownloadPath(
                            currentMessage.id
                          )}
                          title="Download billeder som en samlet PDF"
                        >
                          {/* MissingEntity */}
                          Download som PDF
                        </DownloadButton>
                      )}
                    </ButtonToolbar>
                  ) : null}
                </div>
              ) : null}
              <FileViewerModal
                size="large"
                targetCase={
                  !!currentMessage.caseModel &&
                  currentMessage.caseModel.userName === userProfile.userName
                    ? currentMessage.caseModel
                    : null
                } //The owner of the case can save the document to the case
                ref={(c) => (this.fileModal = c)}
              />
            </Col>
          </Row>
          {!currentMessage.draft && !currentMessage.trashed ? (
            <Row>
              <Col xs={12}>
                <FormControl
                  componentClass="textarea"
                  className="hidden-print"
                  rows={5}
                  onClick={this.handleTextareaClick}
                  onFocus={this.handleTextareaClick}
                  placeholder={ctx.getSync(
                    "messageIndexplaceholdeClickheretoreply"
                  )}
                  style={{ marginBottom: "25px" }}
                />
              </Col>
            </Row>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default withRouter(MailView);
