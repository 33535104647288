import React from "react";
// import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import $ from "jquery";
import { Table, Column, Cell } from "fixed-data-table-2";
import { ExtraSmall, SmallOrLarger } from "../../../utilities/Responsive";
import BookkeepingGridView from "./BookkeepingGridView";
import { debounce } from "lodash";
import QueryString from "query-string";

import moment from "moment";

import ReactSelect from "react-select";
import { CSVLink } from "react-csv";

import {
  // ButtonGroup,
  FormGroup,
  InputGroup,
  FormControl,
  ButtonToolbar,
} from "react-bootstrap";

import { Table as BootstrapTable, DropdownButton } from "react-bootstrap";

import {
  ScannerService,
  ApiService,
  DataStore,
  DataActions,
  NumberService,
  FileInfoService,
  UserInfoService,
  RoutingService,
  ModalService,
  ContactService,
} from "../../../services/AxoServices";

import {
  LexButton,
  TableBase,
  SortHeaderCell,
  DataListWrapper,
  Dimensions,
  Icon,
  FileViewerModal,
  InlineEdit,
  InlineNumberEdit,
  LoadingIcon,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  PrintReceiptsModal,
  AxoLocal,
  getText,
  AsyncButton,
  Link,
  AxoCheckbox,
  AxoDropzone,
  FileViewer,
  AxoDateTime,
  AxoDateTimeEdit,
  InlineAutoComplete,
  Uniqueomat,
  TextFilterModal,
  NumberFilterModal,
  FilterTypes,
  TextFilter,
  TextFilterTypes,
  NumberFilter,
  NumberFilterTypes,
  DateFilter,
  DateFilterTypes,
  DateFilterModal,
  AxoSelect,
} from "../../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
    index: 0,
  },
  receiptNumber: {
    width: 65,
    shown: true,
    index: 1,
  },
  isIncome: {
    width: 120,
    shown: true,
    index: 2,
  },
  description: {
    width: 185,
    shown: true,
    index: 3,
  },
  vatNumber: {
    width: 150,
    shown: true,
    index: 4,
  },
  account: {
    width: 200,
    shown: true,
    index: 5,
  },
  amount: {
    width: 100,
    shown: true,
    index: 6,
  },
  vat: {
    width: 100,
    shown: true,
    index: 7,
  },
  balanceAccount: {
    width: 200,
    shown: true,
    index: 8,
  },
  creationDate: {
    width: 165,
    shown: true,
    index: 9,
  },
  receipt: {
    width: 125,
    shown: true,
    index: 10,
  },
  actions: {
    width: 100,
    shown: true,
    index: 11,
  },
  department: {
    width: 100,
    shown: true,
    index: 12,
  },
  invoiceNumber: {
    width: 95,
    shown: true,
    index: 13,
  },
  userName: {
    width: 95,
    shown: true,
    index: 14,
  },
  exchangeRate: {
    width: 80,
    shown: true,
    index: 15,
  },
};

const HistoryFilter = {
  ALL: 0,
  POSTED: 1,
  DRAFT: 2,
};

const ViewType = {
  NORMAL: 0,
  SCAN: 1,
  TRASH: 2,
};

class BookkeepingView extends TableBase {
  static propTypes = {
    contactMap: PropTypes.object.isRequired,
    entries: PropTypes.array.isRequired,
    selectedContact: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
    onToggleCards: PropTypes.func.isRequired,
    onCSVChange: PropTypes.func.isRequired,
    readingCSV: PropTypes.bool.isRequired,
    showCSVError: PropTypes.bool.isRequired,
    showCSVSuccess: PropTypes.bool.isRequired,
    fiscalYears: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.name = "BookkeepingView";

    this._defaultSortIndexes = [];
    if (
      !!props.sortIndexes &&
      props.sortIndexes.length === props.entries.length
    ) {
      this._defaultSortIndexes = props.sortIndexes;
    } else {
      this._defaultSortIndexes = this.generateDefaultSortIndexes(props.entries);
    }

    let tableEntries = props.entries;

    this.defaultColumnDefs = defaultColumnDefs;

    this.state = {
      tableEntries,
      selectedEntries: new Set(),
      displayedEntryId: 0,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        actions: 200,
      },
      maxColumnWidth: 400,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      entryFilterTexts: {},
      selectedEntryColumn: "",
      deletingId: 0,
      uploadingExpense: false,
      scanningExpense: false,
      uploadingIncome: false,
      scanningIncome: false,
      documentsUploaded: 0,
      documentsToBeUploaded: 0,
      documentsScanned: 0,
      documentsToBeScanned: 0,
      showStorageWarning: false,
      showFileUploadError: false,
      showFileMaxSizeWarning: false,
      showInvalidDatesWarning: false,
      showLockedFiscalYearsWarning: false,
      invalidDateEntryIds: new Set(),
      showImbalanceWarning: false,
      vatErrorEntries: new Set(),

      confirmPosting: false,
      showAllAccounts: true,
      showAllBalanceAccounts: true,

      selectedEntryId: 0,
      receiptsWhoseDatesCouldNotBeScanned: [], //Ids of receipts whose dates could not be scanned
      multiPageUpload: false,
      cachedPageFiles: [],
      multiPageIsIncome: false,
      ...this.getTranslatedTexts(),
      showAccountHistoryId: 0,
      accountHistoryFilter: HistoryFilter.ALL,

      showMissingCurrentFiscalYearError: false,

      hideApprovedSimulationRows: false,

      confirmDeleteEntryId: 0,
      confirmDeleteSelected: false,

      addingPageToId: 0,
      addedPageToId: 0,

      csv: [[]],

      finalizationStartDate: moment.utc(),
      finalizationEndDate: moment.utc(),

      confirmBankBalancing: false,

      //Ensures that active column is fully in view when being edited
      scrollToColumnIndex: 0,

      columnFilters: {}, //Map from column names to filters,

      creditDebitFilter: 0, //0: All, 1: Credit. 2: Debit
      hasReceiptFilter: 0,
    };

    this.selectedEntryId = 0;
    this.finishingComposite = false;
    this.addPagesControl = React.createRef();
    this.tableContainer = React.createRef();
    this.textFilterModal = React.createRef();
    this.numberFilterModal = React.createRef();
    this.dateFilterModal = React.createRef();
  }

  onSelectEntryColumn = (columnName) => {
    let { selectedEntryColumn } = this.state;

    this.setState((prevState) => ({
      selectedEntryColumn:
        prevState.selectedEntryColumn !== columnName ? columnName : "",
    }));

    if (selectedEntryColumn === columnName) {
      this.updateEntryFilter({}, this.state.columnFilters);
    }
  };

  updateEntryFilterText = (value) => {
    let { entries } = this.props;

    let { entryFilterTexts, selectedEntryColumn } = this.state;

    let newFilter = {
      ...entryFilterTexts,
      [selectedEntryColumn || "description"]: value,
    };

    this.setState({
      entryFilterTexts: newFilter,
    });

    if (entries.length > 300) {
      this.updateEntryFilterDebounced(newFilter);
    } else {
      this.updateEntryFilterNow(newFilter);
    }
  };

  updateEntryFilterNow = (newFilter) => {
    this.updateEntryFilter(newFilter, this.state.columnFilters);
  };

  updateEntryFilterDebounced = debounce((newFilter) => {
    this.updateEntryFilter(newFilter, this.state.columnFilters);
  }, 500);

  updateEntryFilter = (entryFilterTexts, columnFilters) => {
    let { tableEntries, searchText } = this.state;
    let entries = this.getFilteredEntries(
      tableEntries,
      searchText,
      entryFilterTexts,
      columnFilters
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);

    this.setState({
      entryFilterTexts,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
      selectedEntries: new Set(),
    });
  };

  onDisplayEntry = (entryId) => {
    this.setState((prevState) => ({
      displayedEntryId: prevState.displayedEntryId !== entryId ? entryId : 0,
    }));

    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === entryId);
    if (!!entry && !entry.inspected) {
      DataActions.updateBookkeepingDraftEntry({
        ...entry,
        inspected: true,
      });
    }
  };

  clearSelectedEntries = () => {
    let { clearSelectedEntries } = this.props;
    if (!!clearSelectedEntries) {
      clearSelectedEntries();
      return;
    }

    this.setState({ selectedEntries: new Set() });
  };

  onSelectEntry = (entryId, event) => {
    let { onSelectEntry } = this.props;
    if (!!onSelectEntry) {
      onSelectEntry(entryId, event);
      return;
    }

    let selectedEntries = new Set(this.state.selectedEntries);
    if (event.target.checked) {
      selectedEntries.add(entryId);
    } else {
      selectedEntries.delete(entryId);
    }
    this.setState({ selectedEntries });
  };

  onSelectAll = () => {
    let { onSelectAll } = this.props;
    let { selectedEntries, sortedDataList } = this.state;

    if (!!onSelectAll) {
      onSelectAll(sortedDataList);
      return;
    }

    let allSelected = selectedEntries.size === sortedDataList.getSize();

    if (allSelected) {
      this.setState({ selectedEntries: new Set() });
    } else {
      this.setState({
        selectedEntries: new Set(sortedDataList._data.map((d) => d.id)),
      });
    }
  };

  componentDidMount = () => {
    this.checkForCurrentFiscalYear();

    let query = QueryString.parse(this.props.location.search);
    if (!!query.finalize) {
      this.onFinalizeDrafts();
    }
  };

  componentDidUpdate = (prevProps) => {
    let {
      locale,
      selectedFiscalYearId,
      selectedStartDate,
      selectedEndDate,
      updateSortIndexes,
    } = this.props;

    let { sortedDataList } = this.state;

    if (prevProps.locale !== locale) {
      this.setState(this.getTranslatedTexts());
    }

    if (
      selectedFiscalYearId !== prevProps.selectedFiscalYearId ||
      selectedStartDate !== prevProps.selectedStartDate ||
      selectedEndDate !== prevProps.selectedEndDate
    ) {
      //Ensure that entries outside current time interval cannot be selected.
      this.setState({ selectedEntries: new Set() });
    }

    if (!!updateSortIndexes) {
      //Cache sorting in parent component
      updateSortIndexes(sortedDataList.getIndexMap());
    }

    this.checkForCurrentFiscalYear();

    if (prevProps !== this.props) {
      if (prevProps.entries.length !== this.props.entries.length) {
        this.updateFiltering();
        return;
      }
      for (let i = 0; i < prevProps.entries.length; i++) {
        if (prevProps.entries[i] !== this.props.entries[i]) {
          this.updateFiltering();
          return;
        }
      }
      this.debouncedFiltering();
    }
  };

  debouncedFiltering = debounce(() => {
    this.updateFiltering();
  }, 500);

  updateFiltering = () => {
    let { entries } = this.props;
    let { searchText, entryFilterTexts, columnFilters } = this.state;
    let filteredEntries = this.getFilteredEntries(
      entries,
      searchText,
      entryFilterTexts,
      columnFilters
    );

    this.updateEntries(entries, filteredEntries);
  };

  checkForCurrentFiscalYear = () => {
    let { fiscalYears, selectedContact } = this.props;

    if (!fiscalYears || !selectedContact.id) {
      return;
    }

    let now = moment.utc();

    if (
      !fiscalYears.find(
        (f) =>
          moment.utc(f.startDate).isSameOrBefore(now) &&
          moment.utc(f.endDate).isSameOrAfter(now)
      )
    ) {
      this.setState({ showMissingCurrentFiscalYearError: true });
    } else {
      this.setState({ showMissingCurrentFiscalYearError: false });
    }
  };

  getTranslatedTexts = () => {
    return {
      creditLabel: getText("AccountingTabViewEntity41"),
      debitLabel: getText("AccountingTabViewEntity42"),
      uploadLabel: getText("DocumentTabViewUpload"),
      deleteLabel: getText("axoidcode179"),
      restoreLabel: getText("DocumentTrashTableViewrestore", "Gendan"),
      copyLabel: getText("axoAccounting24"),
      editLabel: getText("presentationMarketing6"),
      chooseLabel: getText("ClientBookingSelectLawyer"),
    };
  };

  // componentWillReceiveProps(nextProps) {
  //   this.debouncedFiltering()
  //   let tableEntries = nextProps.entries;

  //   let { entryFilterTexts, columnFilters } = this.state;
  //   let filteredEntries = this.getFilteredEntries(tableEntries, entryFilterTexts, columnFilters)

  //   this.updateEntries(tableEntries, filteredEntries);
  // }

  onSearch = (event) => {
    let { tableEntries, entryFilterTexts, columnFilters } = this.state;

    let entries = this.getFilteredEntries(
      tableEntries,
      event.target.value,
      entryFilterTexts,
      columnFilters
    );
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (
    entries,
    searchText,
    entryFilterTexts,
    columnFilters
  ) => {
    let { financeAccountMap } = this.props;

    let filteredEntries = entries;
    if (!!entryFilterTexts.receiptNumber) {
      filteredEntries = filteredEntries.filter((e) =>
        e.receiptNumber
          .toString()
          .includes(entryFilterTexts.receiptNumber.toLowerCase())
      );
    }
    if (!!entryFilterTexts.description) {
      filteredEntries = filteredEntries.filter((e) =>
        (e.description || "")
          .toLowerCase()
          .includes(entryFilterTexts.description.toLowerCase())
      );
    }

    if (!!entryFilterTexts.creationDate) {
      filteredEntries = filteredEntries.filter((e) =>
        moment
          .utc(e.creationDate)
          .format("L")
          .includes(entryFilterTexts.creationDate.toLowerCase())
      );
    }
    if (!!entryFilterTexts.account) {
      filteredEntries = filteredEntries.filter((e) => {
        let account = financeAccountMap[e.financeAccountId];
        if (!account) {
          return false;
        }
        return (account.number.toString() + " - " + account.name)
          .toLowerCase()
          .includes(entryFilterTexts.account.toLowerCase());
      });
    }
    if (!!entryFilterTexts.amount) {
      let filterText = entryFilterTexts.amount.toLowerCase().replace(/,/g, ".");

      filteredEntries = filteredEntries.filter((e) =>
        e.amount.toFixed(2).includes(filterText)
      );
    }
    if (!!entryFilterTexts.vat) {
      let filterText = entryFilterTexts.vat.toLowerCase().replace(/,/g, ".");

      filteredEntries = filteredEntries.filter((e) =>
        e.vat.toFixed(2).includes(filterText)
      );
    }
    if (!!entryFilterTexts.balanceAccount) {
      filteredEntries = filteredEntries.filter((e) => {
        let account = financeAccountMap[e.balanceFinanceAccountId];
        if (!account) {
          return false;
        }
        return (account.number.toString() + " - " + account.name)
          .toLowerCase()
          .includes(entryFilterTexts.balanceAccount.toLowerCase());
      });
    }
    if (!!entryFilterTexts.vatNumber) {
      filteredEntries = filteredEntries.filter((e) =>
        (e.vatNumber || "")
          .toLowerCase()
          .includes(entryFilterTexts.vatNumber.toLowerCase())
      );
    }

    if (!!searchText) {
      let defaultAccount = { number: 0, name: "" };

      filteredEntries = filteredEntries.filter((d) => {
        let financeAccount =
          financeAccountMap[d.financeAccountId] || defaultAccount;
        let balanceAccount =
          financeAccountMap[d.balanceFinanceAccountId] || defaultAccount;

        let filterText = `${d.receiptNumber} ${d.invoiceNumber} ${
          d.description || ""
        } ${d.amount} ${d.vat} ${financeAccount.number} ${
          financeAccount.name
        } ${balanceAccount.number} ${balanceAccount.name}`;
        return filterText.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    filteredEntries = this.applyColumnFilters(filteredEntries, columnFilters);

    return filteredEntries;
  };

  applyColumnFilters = (entries, columnFilters, skipFilterName = "") => {
    let skipFilter = columnFilters[skipFilterName]; //Apply only other filters, to determine available options for this filter
    let filteredEntries = entries;
    if (!!columnFilters.isIncome && columnFilters.isIncome !== 0) {
      filteredEntries = filteredEntries.filter((e) =>
        columnFilters.isIncome === 1 ? e.isIncome : !e.isIncome
      );
    }
    if (!!columnFilters.receipt && columnFilters.receipt !== 0) {
      filteredEntries = filteredEntries.filter((e) =>
        columnFilters.receipt === 1 ? !!e.receiptId : !e.receiptId
      );
    }
    if (
      !!columnFilters.description &&
      columnFilters.description !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "description"
      );
    }
    if (!!columnFilters.vatNumber && columnFilters.vatNumber !== skipFilter) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "vatNumber"
      );
    }
    if (
      !!columnFilters.financeAccountId &&
      columnFilters.financeAccountId !== skipFilter
    ) {
      filteredEntries = this.applyAccountFilter(
        filteredEntries,
        columnFilters,
        "financeAccountId"
      );
    }
    if (
      !!columnFilters.balanceFinanceAccountId &&
      columnFilters.balanceFinanceAccountId !== skipFilter
    ) {
      filteredEntries = this.applyAccountFilter(
        filteredEntries,
        columnFilters,
        "balanceFinanceAccountId"
      );
    }
    if (
      !!columnFilters.receiptNumber &&
      columnFilters.receiptNumber !== skipFilter
    ) {
      filteredEntries = this.applyNumberFilter(
        filteredEntries,
        columnFilters,
        "receiptNumber"
      );
    }
    if (!!columnFilters.amount && columnFilters.amount !== skipFilter) {
      filteredEntries = this.applyNumberFilter(
        filteredEntries,
        columnFilters,
        "amount"
      );
    }
    if (!!columnFilters.vat && columnFilters.vat !== skipFilter) {
      filteredEntries = this.applyNumberFilter(
        filteredEntries,
        columnFilters,
        "vat"
      );
    }
    if (!!columnFilters.exchangeRate && columnFilters.exchangeRate !== skipFilter) {
      filteredEntries = this.applyNumberFilter(
        filteredEntries,
        columnFilters,
        "exchangeRate"
      );
    }
    if (
      !!columnFilters.creationDate &&
      columnFilters.creationDate !== skipFilter
    ) {
      filteredEntries = this.applyDateFilter(
        filteredEntries,
        columnFilters,
        "creationDate"
      );
    }
    if (
      !!columnFilters.invoiceNumber &&
      columnFilters.invoiceNumber !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "invoiceNumber"
      );
    }
    if (
      !!columnFilters.userName &&
      columnFilters.userName !== skipFilter
    ) {
      filteredEntries = this.applyTextFilter(
        filteredEntries,
        columnFilters,
        "userName"
      );
    }

    return filteredEntries;
  };

  applyTextFilter = (entries, columnFilters, propertyName) => {
    let filteredEntries = entries;

    // if(columnFilters[propertyName].selectedOptions.size > 0) {
    //   filteredEntries = filteredEntries.filter(e => columnFilters[propertyName]
    //     .selectedOptions.has(e[propertyName] || ''));
    // }

    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(e[propertyName] || "")
      );
    }

    if (!!columnFilters[propertyName].filterString) {
      let search = columnFilters[propertyName].filterString.toLowerCase();
      switch (columnFilters[propertyName].filterStringType) {
        case TextFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => (f[propertyName] || "").toLowerCase() === search
          );
          break;
        case TextFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => (f[propertyName] || "").toLowerCase() !== search
          );
          break;
        case TextFilterTypes.STARTSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            (f[propertyName] || "").toLowerCase().startsWith(search)
          );
          break;
        case TextFilterTypes.ENDSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            (f[propertyName] || "").toLowerCase().endsWith(search)
          );
          break;
        case TextFilterTypes.CONTAINS:
          filteredEntries = filteredEntries.filter((f) =>
            (f[propertyName] || "").toLowerCase().includes(search)
          );
          break;
        case TextFilterTypes.NOTCONTAINS:
          filteredEntries = filteredEntries.filter(
            (f) => !(f[propertyName] || "").toLowerCase().includes(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyAccountFilter = (entries, columnFilters, propertyName) => {
    let { financeAccountMap } = this.props;
    const getName = (entry) => {
      if (!entry[propertyName]) {
        return "";
      }

      let account = financeAccountMap[entry[propertyName]];
      if (!account) {
        return "";
      }

      return account.name || "";
    };

    let filteredEntries = entries;
    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(e[propertyName])
      );

      // filteredEntries = filteredEntries.filter(e =>
      //   columnFilters[propertyName].selectedOptions.has(e[propertyName]));
    }
    if (!!columnFilters[propertyName].filterString) {
      let search = columnFilters[propertyName].filterString.toLowerCase();
      switch (columnFilters[propertyName].filterStringType) {
        case TextFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => getName(f).toLowerCase() === search
          );
          break;
        case TextFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => getName(f).toLowerCase() !== search
          );
          break;
        case TextFilterTypes.STARTSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            getName(f).toLowerCase().startsWith(search)
          );
          break;
        case TextFilterTypes.ENDSWITH:
          filteredEntries = filteredEntries.filter((f) =>
            getName(f).toLowerCase().endsWith(search)
          );
          break;
        case TextFilterTypes.CONTAINS:
          filteredEntries = filteredEntries.filter((f) =>
            getName(f).toLowerCase().includes(search)
          );
          break;
        case TextFilterTypes.NOTCONTAINS:
          filteredEntries = filteredEntries.filter(
            (f) => !getName(f).toLowerCase().includes(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyNumberFilter = (entries, columnFilters, propertyName) => {
    let filteredEntries = entries;

    // if(columnFilters[propertyName].selectedOptions.size > 0) {
    //   filteredEntries = filteredEntries.filter(e => columnFilters[propertyName].selectedOptions.has(e[propertyName]));
    // }

    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(e[propertyName])
      );
    }

    if (
      columnFilters[propertyName].filterNumberType !== NumberFilterTypes.NONE
    ) {
      let search = columnFilters[propertyName].filterNumber;
      switch (columnFilters[propertyName].filterNumberType) {
        case NumberFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] === search
          );
          break;
        case NumberFilterTypes.NOTEQUEALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] !== search
          );
          break;
        case NumberFilterTypes.GREATERTHAN:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] > search
          );
          break;
        case NumberFilterTypes.GREATERTHANEQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] >= search
          );
          break;
        case NumberFilterTypes.LESSTHAN:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] < search
          );
          break;
        case NumberFilterTypes.LESSTHANEQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => f[propertyName] <= search
          );
          break;
        case NumberFilterTypes.BETWEEN:
          // filteredEntries = filteredEntries.filter(f => !(f[propertyName] || '').toLowerCase().includes(search))
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  applyDateFilter = (entries, columnFilters, propertyName) => {
    let filteredEntries = entries;

    // if(columnFilters[propertyName].selectedOptions.size > 0) {
    //   filteredEntries = filteredEntries.filter(e => columnFilters[propertyName]
    //     .selectedOptions.has(moment.utc(e[propertyName]).format('L')));
    // }

    if (columnFilters[propertyName].selectedOptions.length > 0) {
      let selectedOptionSet = new Set(
        columnFilters[propertyName].selectedOptions
      );

      filteredEntries = filteredEntries.filter((e) =>
        selectedOptionSet.has(moment.utc(e[propertyName]).format("L"))
      );
    }

    if (columnFilters[propertyName].filterMonth > -1) {
      filteredEntries = filteredEntries.filter(
        (e) =>
          moment.utc(e.creationDate).month() ===
          columnFilters[propertyName].filterMonth
      );
    }
    if (columnFilters[propertyName].filterMonthArray.length > 0) {
      let filterMonthMap = new Set(
        columnFilters[propertyName].filterMonthArray
      );
      filteredEntries = filteredEntries.filter((e) =>
        filterMonthMap.has(moment.utc(e.creationDate).month())
      );
    }
    if (columnFilters[propertyName].filterDateType !== DateFilterTypes.NONE) {
      let search = moment.utc(columnFilters[propertyName].filterDate);
      switch (columnFilters[propertyName].filterDateType) {
        case DateFilterTypes.EQUALS:
          filteredEntries = filteredEntries.filter(
            (f) => moment.utc(f[propertyName]).valueOf() === search.valueOf()
          );
          break;
        case DateFilterTypes.BEFORE:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isBefore(search)
          );
          break;
        case DateFilterTypes.BEFOREORSAME:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isSameOrBefore(search)
          );
          break;
        case DateFilterTypes.AFTER:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isAfter(search)
          );
          break;
        case DateFilterTypes.AFTERORSAME:
          filteredEntries = filteredEntries.filter((f) =>
            moment.utc(f[propertyName]).isSameOrAfter(search)
          );
          break;
        default:
          break;
      }
    }

    return filteredEntries;
  };

  onUploadIncome = () => {
    $("#incomeUpload").trigger("click");
  };

  onUploadExpense = () => {
    $("#expenseUpload").trigger("click");
  };

  onUploadReceiptForExistingEntry = (entryId) => {
    this.selectedEntryId = entryId;
    $("#receiptUpload").trigger("click");
  };

  onIncomeSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, true);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onExpenseSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, false);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  finishMultiPageUpload = async () => {
    let { cachedPageFiles, multiPageIsIncome } = this.state;

    this.finishingComposite = true;
    await this.processAllReceipts(cachedPageFiles, multiPageIsIncome);
    this.setState({
      cachedPageFiles: [],
    });
    this.finishingComposite = false;
    return true;
  };

  onReceiptSelected = async (event) => {
    let { selectedContact, actions, convertToPDF } = this.props;

    let entry = this.props.entries.find((e) => e.id === this.selectedEntryId);
    if (!entry) {
      return;
    }

    let files = event.target.files;
    if (files.length === 0) {
      return;
    }

    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    let uploadResponse = await actions.uploadDocuments(files, {
      clientId: selectedContact.id,
      convertToPDF,
      skipOptimisticUpdate: true,
    });

    let addedFiles = [];
    if (uploadResponse.ok) {
      addedFiles = await uploadResponse.json();
      if (addedFiles.length === 0) {
        return;
      }
    } else {
      return this.displayResponseWarnings(uploadResponse);
    }

    let addedFile = addedFiles[0];
    entry.receiptId = addedFile.id;
    entry.receipt = addedFile;
    return actions.updateBookkeepingDraftEntry(entry);
  };

  async processAllReceipts(files, isIncome) {
    let {
      // scanReceipts,
      actions,
    } = this.props;

    let { multiPageUpload } = this.state;

    if (files.length === 0) {
      return;
    }

    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    let fileArray = Array.from(files);

    if (multiPageUpload && !this.finishingComposite) {
      this.setState((prevState) => ({
        multiPageIsIncome: isIncome,
        cachedPageFiles: prevState.cachedPageFiles.concat(fileArray),
      }));
      return;
    }

    if (isIncome) {
      this.setState({ uploadingIncome: true });
    } else {
      this.setState({ uploadingExpense: true });
    }

    let { selectedContact, splitPDF, convertToPDF } = this.props;

    this.setState({
      documentsToBeUploaded: fileArray.length,
      documentsUploaded: 0,
    });

    if (this.finishingComposite) {
      let response = await actions.uploadCompositeImage(fileArray, {
        clientId: selectedContact.id,
      });
      this.setState((oldState) => ({
        documentsUploaded: oldState.documentsUploaded + 1,
      }));
      await this.processFileResponse(response, isIncome);
    } else {
      for (let file of fileArray) {
        //Process files in sequence
        let response = await actions.uploadDocuments([file], {
          clientId: selectedContact.id,
          splitPDF,
          convertToPDF,
          skipOptimisticUpdate: true,
        });

        await this.processFileResponse(response, isIncome);
        this.setState((oldState) => ({
          documentsUploaded: oldState.documentsUploaded + 1,
        }));
      }
    }

    // let addedFiles = [];
    // if(response.ok) {
    //   addedFiles = await response.json();
    // }
    // else {
    //   return this.displayResponseWarnings(response);
    // }

    this.setState({
      documentsToBeUploaded: 0,
      documentsUploaded: 0,
      uploadingIncome: false,
      uploadingExpense: false,
    });

    // if(scanReceipts) {
    //   this.setState( {
    //     scanningIncome: isIncome,
    //     scanningExpense: !isIncome,
    //     documentsScanned: 0,
    //     documentsToBeScanned: addedFiles.length
    //   });
    // }
    // for( let file of addedFiles ) { //Process files in sequence
    //   if(scanReceipts) {
    //     await this.processReceipt(file, isIncome);
    //     this.setState(oldState => ({ documentsScanned: oldState.documentsScanned + 1 }))
    //   }
    //   else {
    //     await this.processReceiptWithoutScan(file, isIncome);
    //   }
    // }

    // this.setState({
    //   documentsScanned: 0,
    //   documentsToBeScanned: 0,
    //   uploadingIncome: false,
    //   uploadingExpense: false,
    //   scanningIncome: false,
    //   scanningExpense: false
    // });
  }

  processFileResponse = async (response, isIncome) => {
    let addedFiles = [];
    if (response.ok) {
      addedFiles = await response.json();
    } else {
      return this.displayResponseWarnings(response);
    }
    for (let file of addedFiles) {
      await this.processFile(file, isIncome);
    }
  };

  processFile = async (file, isIncome) => {
    let { scanReceipts } = this.props;
    if (scanReceipts) {
      this.setState({
        scanningIncome: isIncome,
        scanningExpense: !isIncome,
        // documentsScanned: 0,
        // documentsToBeScanned: 1
      });
    }

    if (scanReceipts) {
      await this.processReceipt(file, isIncome);
      // this.setState(oldState => ({ documentsScanned: oldState.documentsScanned + 1 }))
    } else {
      await this.processReceiptWithoutScan(file, isIncome);
    }

    this.setState({
      documentsScanned: 0,
      documentsToBeScanned: 0,
      // uploadingIncome: false,
      // uploadingExpense: false,
      scanningIncome: false,
      scanningExpense: false,
    });
  };

  displayResponseWarnings = async (response) => {
    if (response.status === 400) {
      let text = await response.text();
      if (text === "Storage") {
        this.showWarning("showStorageWarning");
        return;
      } else {
        this.showWarning("showFileUploadError");
        return;
      }
    } else {
      this.showWarning("showFileUploadError");
      return;
    }
  };

  getStartAndEndDate = () => {
    let { selectedFiscalYear, selectedStartDate, selectedEndDate } = this.props;

    let startDate =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDate = selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    return { startDate, endDate };
  };

  getNewCreationDate = () => {
    let { startDate, endDate } = this.getStartAndEndDate();
    let newCreationDate = moment.utc();
    newCreationDate = moment.max([startDate, newCreationDate]);
    newCreationDate = moment.min([newCreationDate, endDate]);

    return newCreationDate;
  };

  processReceipt = (file, isIncome) => {
    let { selectedContact } = this.props;

    let newCreationDate = this.getNewCreationDate();

    return ApiService.scanText(file.id)
      .then((scannedText) => {
        return {
          fileId: file.id,
          scannedText,
        };
      })
      .then((scanResult) => {
        let scanProcessingResult = ScannerService.scanReceiptText(
          scanResult.scannedText,
          parseInt(selectedContact.identityCode, 10)
        );
        let entry = {
          isIncome,
          description:
            scanProcessingResult.cvr || file.fileName.replace(/\.[^/.]+$/, ""),
          amount: scanProcessingResult.total,
          vat: scanProcessingResult.vat,
          creationDate: !!scanProcessingResult.date
            ? scanProcessingResult.date.format()
            : newCreationDate.format(),
          receipt: file,
          receiptId: scanResult.fileId,
          contactInfoId: selectedContact.id,
          balanceFinanceAccountId: this.getDefaultPaymentAccount(),
        };
        if (!scanProcessingResult.date) {
          this.setState((prevState) => ({
            receiptsWhoseDatesCouldNotBeScanned: [
              ...prevState.receiptsWhoseDatesCouldNotBeScanned,
              entry.receiptId,
            ],
          }));
        }
        if (!!scanProcessingResult.cvr) {
          return ApiService.getCVRName(scanProcessingResult.cvr)
            .then((companyInfo) => {
              entry.vatNumber = scanProcessingResult.cvr || "";
              entry.description = companyInfo.name;
              return this.createNewEntry(entry);
            })
            .catch((reason) => {
              console.log(reason);
              entry.description = "";
              return this.createNewEntry(entry);
            });
        } else {
          return this.createNewEntry(entry);
        }
      })
      .catch((reason) => {
        console.error(reason);
        this.setState({
          uploadingIncome: false,
          uploadingExpense: false,
          scanningIncome: false,
          scanningExpense: false,
        });
      });
  };

  processReceiptWithoutScan = (file, isIncome) => {
    let { selectedContact } = this.props;

    let entry = {
      description: file.fileName.replace(/\.[^/.]+$/, ""), //Regex removes file extension
      isIncome,
      receipt: file,
      receiptId: file.id,
      contactInfoId: selectedContact.id,
      balanceFinanceAccountId: this.getDefaultPaymentAccount(),
      creationDate: this.getNewCreationDate().format(),
    };

    return this.createNewEntry(entry);
  };

  showWarning = (warningName) => {
    this.setState({ [warningName]: true });
    setTimeout(() => {
      this.setState({ [warningName]: false });
    }, 3000);
  };

  onDeleteEntry = (entry) => {
    let { viewType } = this.props;

    if (viewType !== ViewType.TRASH) {
      this.doDeleteEntry(entry);
      return;
    }

    this.setState({ confirmDeleteEntryId: entry.id });
  };

  doDeleteSelectedEntry = () => {
    let { entries } = this.props;
    let entry = entries.find((e) => e.id === this.state.confirmDeleteEntryId);
    if (!entry) {
      return;
    }

    this.doDeleteEntry(entry);
  };

  doDeleteEntry = async (entry, reload = true) => {
    let { viewType } = this.props;

    this.setState({ deletingId: entry.id });

    let response = null;

    if (viewType !== ViewType.TRASH) {
      let newEntry = { ...entry, trashed: true };
      if (reload) {
        response = await this.props.actions.updateBookkeepingDraftEntry(
          newEntry
        );
      } else {
        response = await ApiService.updateBookkeepingDraftEntry(newEntry);
      }
    } else if (viewType === ViewType.TRASH) {
      if (reload) {
        response =
          await this.props.actions.deleteBookkeepingDraftEntryAndReceipt(entry);
      } else {
        response = await ApiService.deleteBookkeepingDraftEntryAndReceipt(
          entry.id
        );
      }
    }

    if (reload) {
      this.setState({
        selectedEntries: new Set(),
        deletingId: 0,
        confirmDeleteEntryId: 0,
      });
    }

    return response;
  };

  onShowReceiptForEntry = (entryId) => {
    let entry = this.props.entries.find((p) => p.id === entryId);
    this.onShowReceipt(entry.receipt);
  };

  onShowReceipt = (receipt) => {
    this.fileModal.open([receipt]);
  };

  onChangePropertyValue = async (id, propertyName, value, reload = true) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    let newEntry = {
      ...entry,
      [propertyName]: value,
    };

    if (reload) {
      return this.props.actions.updateBookkeepingDraftEntry(newEntry);
    } else {
      return ApiService.updateBookkeepingDraftEntry(newEntry);
    }
  };

  onChangePropertyValueDebounced = (id, propertyName, value) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    let newValue = {
      ...entry,
      [propertyName]: value,
    };

    this.props.actions.updateBookkeepingDraftEntryState(newValue);
    this.debouncedUpdate(newValue);
  };

  debouncedUpdate = debounce((model) => {
    this.props.actions.updateBookkeepingDraftEntry(model);
  }, 1000);

  onChangeVatNumber = (id, value) => {
    let { actions, autoAccounts, viewType } = this.props;

    let newEntry = this.getEntryById(id);
    newEntry.vatNumber = value;
    actions.updateBookkeepingDraftEntry(newEntry);

    if (
      autoAccounts &&
      (viewType === ViewType.SCAN || !newEntry.financeAccountId)
    ) {
      this.fetchStoredAccount(newEntry, newEntry.vatNumber);
    }

    if (!newEntry.description && ScannerService.isValidCVR(value)) {
      return ApiService.getCVRName(value)
        .then((companyInfo) => {
          newEntry.description = companyInfo.name;
          actions.updateBookkeepingDraftEntry(newEntry);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };

  onChangeDescription = (id, value) => {
    let { actions, autoAccounts, viewType } = this.props;

    let newEntry = this.getEntryById(id);
    newEntry.description = value;
    actions.updateBookkeepingDraftEntry(newEntry);

    if (
      autoAccounts &&
      (viewType === ViewType.SCAN || !newEntry.financeAccountId)
    ) {
      this.fetchStoredAccount(newEntry, newEntry.description);
    }
  };

  getEntryById = (id) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }
    let newEntry = { ...entry };
    return newEntry;
  };

  fetchStoredAccount = (entry, key) => {
    let { selectedContact, actions } = this.props;

    ApiService.getStoredAccount({ clientId: selectedContact.id, key })
      .then((accountId) => {
        let accountNumber = parseInt(accountId, 10);
        if (!accountNumber || isNaN(accountNumber)) {
          return;
        }
        actions.updateBookkeepingDraftEntry({
          ...entry,
          financeAccountId: accountNumber,
        });
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  getFinanceAccountPlan = () => {
    let { clientPlan } = this.props;

    return clientPlan;
  };

  updateVat = (entry) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;
    var account = financeAccountMap[entry.financeAccountId];
    if (!account) {
      return entry;
    }

    if (entry.amount <= 0) {
      entry.vat = 0;
      return entry;
    }

    var taxSpec = taxSpecificationMap[account.taxSpecificationId];
    if (!taxSpec) {
      entry.vat = 0;
      return entry;
    }

    let isReverseVat =
      taxSpec.taxType === "ForeignGoods" ||
      taxSpec.taxType === "ServiceReverseCharge";

    if (isReverseVat) {
      //Amount is without vat
      entry.vat = (entry.amount * taxSpec.taxPercentage) / 100;
    } else {
      entry.vat =
        entry.amount - entry.amount / (1 + taxSpec.taxPercentage / 100);
    }

    return entry;
  };

  isReverseVat = (entry) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;
    let account = financeAccountMap[entry.financeAccountId];
    if (!account) {
      return false;
    }

    let taxSpec = taxSpecificationMap[account.taxSpecificationId];
    if (
      !!taxSpec &&
      (taxSpec.taxType === "ForeignGoods" ||
        taxSpec.taxType === "ServiceReverseCharge")
    ) {
      return true;
    }

    return false;
  };

  updateMainAccountAndVat = async (entry, accountId, reload = true) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;

    let newEntry = { ...entry };
    newEntry.financeAccountId = accountId;
    let account = financeAccountMap[accountId];
    if (!!account && account.taxSpecificationId) {
      newEntry.taxAccountId = (
        taxSpecificationMap[account.taxSpecificationId] || {}
      ).receivingAccountId;
    }

    newEntry = this.updateVat(newEntry);

    let { autoAccounts } = this.props;

    let { vatErrorEntries } = this.state;

    if (vatErrorEntries.has(entry.id)) {
      this.setState({
        vatErrorEntries: new Set(
          [...vatErrorEntries].filter((id) => id !== entry.id)
        ),
      });
    }

    let response = {};
    if (reload) {
      response = await this.props.actions.updateBookkeepingDraftEntry(newEntry);
    } else {
      response = await ApiService.updateBookkeepingDraftEntry(newEntry);
    }

    if (!!autoAccounts && !!accountId && reload) {
      if (!!newEntry.vatNumber) {
        this.updateLinkAccounts(
          "vatNumber",
          newEntry.id,
          newEntry.vatNumber,
          accountId
        );
      } else if (!!newEntry.description) {
        this.updateLinkAccounts(
          "description",
          newEntry.id,
          newEntry.description,
          accountId
        );
      }
    }

    return response;
  };

  updateLinkAccounts = async (key, currentEntryId, value, accountId) => {
    let { entries, viewType } = this.props;

    let updateEntries = entries.filter(
      (e) => e[key] === value && e.id !== currentEntryId
    );

    if (viewType === ViewType.NORMAL) {
      updateEntries = updateEntries.filter((e) => !e.financeAccountId);
    }

    if (updateEntries.length > 0) {
      console.log("Link update");
      await ApiService.updateBookkeepingDraftEntryList({
        entryIds: [...updateEntries.map((e) => e.id)],
        newAccountId: accountId,
      });

      DataStore.fetchBookkeepingDraftEntries();
    }

    // let promises = [];
    // updateEntries.forEach(entry => {
    //   console.log("Link update")
    //   promises.push(ApiService.updateBookkeepingDraftEntry({
    //     ...entry,
    //     financeAccountId: accountId
    //   }))
    // })

    // await Promise.all(promises);
    // if(promises.length > 0) {
    //   DataStore.fetchBookkeepingDraftEntries();
    // }
  };

  updateAmountAndVat = (entryId, amount) => {
    let { entries, actions } = this.props;

    let entry = entries.find((e) => e.id === entryId);
    if (!entry) {
      return;
    }

    let newEntry = { ...entry };
    newEntry.amount = amount;
    newEntry = this.updateVat(newEntry);

    let { vatErrorEntries } = this.state;

    if (vatErrorEntries.has(entry.id)) {
      this.setState({
        vatErrorEntries: new Set(
          [...vatErrorEntries].filter((id) => id !== entry.id)
        ),
      });
    }

    actions.updateBookkeepingDraftEntry(newEntry);
  };

  getDefaultPaymentAccount = () => {
    let accountPlan = this.getFinanceAccountPlan();

    let standardAccounts = accountPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    if (accountPlan.customerBankAccountId) {
      let bankAccount = standardAccounts.find(
        (a) => a.id === accountPlan.customerBankAccountId
      );
      if (!!bankAccount) {
        return bankAccount.id;
      }
    }

    let standardPaymentAccounts =
      accountPlan.bankAccountIntervalStart > 0
        ? standardAccounts.filter(
            (a) =>
              a.number >= accountPlan.bankAccountIntervalStart &&
              a.number < accountPlan.bankAccountIntervalEnd
          )
        : [];

    return standardPaymentAccounts.length > 0
      ? standardPaymentAccounts[0].id
      : null;
  };

  getAccountOptions = () => {
    let { showAllAccounts, showAllBalanceAccounts } = this.state;

    let accountPlan = this.getFinanceAccountPlan();
    let standardAccounts = accountPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    let incomeAccounts = standardAccounts;
    let expenseAccounts = standardAccounts;
    let incomePaymentAccounts = standardAccounts;
    let expensePaymentAccounts = standardAccounts;

    if (!showAllAccounts || !showAllBalanceAccounts) {
      //Only show the most common accounts
      let resultAccounts = standardAccounts.filter(
        (a) =>
          a.number >= accountPlan.resultStart &&
          a.number <= accountPlan.resultEnd
      );
      let balanceAccounts = standardAccounts.filter(
        (a) =>
          a.number >= accountPlan.balanceStart &&
          a.number <= accountPlan.balanceEnd
      );

      if (!showAllAccounts) {
        incomeAccounts = resultAccounts.filter((a) => a.isCredit);
        expenseAccounts = resultAccounts.filter((a) => !a.isCredit);
      }
      if (!showAllBalanceAccounts) {
        expensePaymentAccounts = balanceAccounts.filter(
          (a) =>
            a.number >= accountPlan.bankAccountIntervalStart &&
            a.number <= accountPlan.bankAccountIntervalEnd
        );
        incomePaymentAccounts = expensePaymentAccounts;
        if (
          !!accountPlan.customerReceivablesAccountId &&
          !incomePaymentAccounts.find(
            (a) => a.id === accountPlan.customerReceivablesAccountId
          )
        ) {
          incomePaymentAccounts = [
            ...expensePaymentAccounts,
            balanceAccounts.find(
              (a) => a.id === accountPlan.customerReceivablesAccountId
            ),
          ];
        }
        if (
          !!accountPlan.customerPrivateAccountId &&
          !expensePaymentAccounts.find(
            (a) => a.id === accountPlan.customerPrivateAccountId
          )
        ) {
          expensePaymentAccounts = [
            ...expensePaymentAccounts,
            balanceAccounts.find(
              (a) => a.id === accountPlan.customerPrivateAccountId
            ),
          ];
        }
      }
    }

    return {
      standardAccounts,
      incomeAccounts,
      expenseAccounts,
      incomePaymentAccounts,
      expensePaymentAccounts,
    };
  };

  onSelectReceiptEntry = (selectedEntryId, checked) => {
    this.setState({ selectedEntryId: checked ? selectedEntryId : 0 });
  };

  updateDate = (entry, inputDate) => {
    if (!inputDate) {
      return;
    }

    let newDate = moment.utc(inputDate);
    if (newDate.year() > 9999) {
      return;
    }

    let { invalidDateEntryIds } = this.state;
    if (invalidDateEntryIds.has(entry.id)) {
      this.setState({
        invalidDateEntryIds: new Set(
          [...invalidDateEntryIds].filter((id) => id !== entry.id)
        ),
      });
    }
    // this.onChangePropertyValueDebounced(entry.id, 'creationDate', newDate.format());
    this.onChangePropertyValue(entry.id, "creationDate", newDate.format());
    this.setState((prevState) => ({
      receiptsWhoseDatesCouldNotBeScanned:
        prevState.receiptsWhoseDatesCouldNotBeScanned.filter(
          (id) => id !== entry.receiptId
        ),
    }));
  };

  updateDateFromMoment = (entry, newDate) => {
    let { invalidDateEntryIds } = this.state;
    if (invalidDateEntryIds.has(entry.id)) {
      this.setState({
        invalidDateEntryIds: new Set(
          [...invalidDateEntryIds].filter((id) => id !== entry.id)
        ),
      });
    }
    // this.onChangePropertyValueDebounced(entry.id, 'creationDate', newDate.format());
    this.onChangePropertyValue(entry.id, "creationDate", newDate.format());
    this.setState((prevState) => ({
      receiptsWhoseDatesCouldNotBeScanned:
        prevState.receiptsWhoseDatesCouldNotBeScanned.filter(
          (id) => id !== entry.receiptId
        ),
    }));
  };

  //If main account amounts are balanced, balance accounts are not needed.
  getPrimaryAccountBalance = () => {
    let { entries } = this.props;

    return entries.reduce((acc, entry) => {
      return acc + (entry.isIncome ? entry.amount : -entry.amount);
    }, 0);
  };

  sortByAccount = (l, r) => {
    let { financeAccountMap } = this.props;

    let acc1 = financeAccountMap[l.financeAccountId];
    let acc2 = financeAccountMap[r.financeAccountId];

    return (!!acc1 ? acc1.number : 0) - (!!acc2 ? acc2.number : 0);
  };

  sortByBalanceAccount = (l, r) => {
    let { financeAccountMap } = this.props;

    let acc1 = financeAccountMap[l.balanceFinanceAccountId];
    let acc2 = financeAccountMap[r.balanceFinanceAccountId];

    return (!!acc1 ? acc1.number : 0) - (!!acc2 ? acc2.number : 0);
  };

  sortByDepartment = (departmentIdMap, l, r) => {
    if (l.contactInfoId === r.contactInfoId) {
      return 0;
    }

    let ldep = departmentIdMap[l.contactInfoId] || "";
    let rdep = departmentIdMap[r.contactInfoId] || "";

    return ldep < rdep ? -1 : 1;
  };

  filterConfig = {
    matchFrom: "label",
  };

  colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let { financeAccountMap } = this.props;

      let accountPlan = this.getFinanceAccountPlan();
      let account = financeAccountMap[data.value];
      let isBalance =
        !!account &&
        account.number >= accountPlan.balanceStart &&
        account.number <= accountPlan.balanceEnd;

      let background = styles.backgroundColor;
      if (isBalance && !isDisabled && !isFocused && !isSelected) {
        background = "lightblue";
      }
      return {
        ...styles,
        backgroundColor: background,
      };
    },
  };

  editTextFilter = (propertyName) => {
    let { tableEntries, columnFilters } = this.state;

    let availableEntries = this.applyColumnFilters(
      tableEntries,
      columnFilters,
      propertyName
    );
    let textOptions = new Set(
      availableEntries.map((e) => e[propertyName] || "")
    );
    let arrayOptions = [...textOptions]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => (l.name || "").localeCompare(r.name || ""));

    let existingFilter = columnFilters[propertyName];
    let textFilter;
    if (!!existingFilter) {
      textFilter = new TextFilter(existingFilter);
      textFilter.options = arrayOptions;
    } else {
      textFilter = new TextFilter({
        options: arrayOptions,
        selectedOptions: [],
      });
    }

    this.textFilterModal.current.open(textFilter, (textFilter) => {
      this.updateColumnFilter(propertyName, textFilter);
    });
  };

  //Text filter using the full name of the account
  editAccountFilter = (propertyName) => {
    let { financeAccountMap } = this.props;

    let { tableEntries, columnFilters } = this.state;

    let availableEntries = this.applyColumnFilters(
      tableEntries,
      columnFilters,
      propertyName
    );

    let accountOptions = new Set(
      availableEntries
        .filter((e) => !!e[propertyName] && financeAccountMap[e[propertyName]])
        .map((e) => e[propertyName])
    );

    let arrayOptions = [...accountOptions]
      .map((id) => ({
        id,
        name:
          financeAccountMap[id].number.toString() +
          " - " +
          financeAccountMap[id].name,
      }))
      .sort((l, r) => (l.name || "").localeCompare(r.name || ""));

    let existingFilter = columnFilters[propertyName];
    let textFilter;
    if (!!existingFilter) {
      textFilter = new TextFilter(existingFilter);
      textFilter.options = arrayOptions;
    } else {
      textFilter = new TextFilter({
        options: arrayOptions,
        selectedOptions: [],
      });
    }

    this.textFilterModal.current.open(textFilter, (textFilter) => {
      this.updateColumnFilter(propertyName, textFilter);
    });
  };

  editNumberFilter = (propertyName) => {
    let { tableEntries, columnFilters } = this.state;

    let availableEntries = this.applyColumnFilters(
      tableEntries,
      columnFilters,
      propertyName
    );
    let options = new Set(availableEntries.map((e) => e[propertyName]));
    let arrayOptions = [...options]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => l.name - r.name);

    let existingFilter = columnFilters[propertyName];
    let numberFilter;
    if (!!existingFilter) {
      numberFilter = new NumberFilter(existingFilter);
      numberFilter.options = arrayOptions;
    } else {
      numberFilter = new NumberFilter({
        options: arrayOptions,
        selectedOptions: [],
      });
    }

    this.numberFilterModal.current.open(numberFilter, (numberFilter) => {
      this.updateColumnFilter(propertyName, numberFilter);
    });
  };

  editDateFilter = (propertyName) => {
    let { tableEntries, columnFilters } = this.state;

    let availableEntries = this.applyColumnFilters(
      tableEntries,
      columnFilters,
      propertyName
    );
    let options = new Set(
      availableEntries.map((e) => moment(e[propertyName]).format("L"))
    );

    let arrayOptions = [...options]
      .map((t) => ({ id: t, name: t }))
      .sort((l, r) => l.name - r.name);

    let existingFilter = columnFilters[propertyName];
    let filter;
    if (!!existingFilter) {
      filter = new DateFilter(existingFilter);
      filter.options = arrayOptions;
    } else {
      filter = new DateFilter({
        options: arrayOptions,
        selectedOptions: [],
      });
    }

    this.dateFilterModal.current.open(filter, (filter) => {
      this.updateColumnFilter(propertyName, filter);
    });
  };

  removeColumnFilter = (propertyName) => {
    let { columnFilters, entryFilterTexts } = this.state;

    let { [propertyName]: property, ...newFilter } = columnFilters;

    this.setState({ columnFilters: newFilter });

    this.updateEntryFilter(entryFilterTexts, newFilter);
  };

  updateColumnFilter = (propertyName, filter) => {
    let { entryFilterTexts, columnFilters } = this.state;

    delete filter.options; //Options should not be saved, as they are recalculated when opening the modal.

    let newColumnFilters = {
      ...columnFilters,
      [propertyName]: filter,
    };

    this.setState({ columnFilters: newColumnFilters });

    this.updateEntryFilter(entryFilterTexts, newColumnFilters);
  };

  ColumnFilterButtons = ({ propertyName, filterType = FilterTypes.TEXT }) => {
    let { columnFilters } = this.state;

    let func = () => {
      switch (filterType) {
        case FilterTypes.TEXT:
          return this.editTextFilter(propertyName);
        case FilterTypes.ACCOUNT:
          return this.editAccountFilter(propertyName);
        case FilterTypes.NUMBER:
          return this.editNumberFilter(propertyName);
        case FilterTypes.DATE:
          return this.editDateFilter(propertyName);
        default:
          return;
      }
    };

    return (
      <span onClick={(event) => event.stopPropagation()}>
        <span title={getText("axoEntityidcode117", "Filtrer")} onClick={func}>
          &nbsp;
          <Icon className="editable" glyph="icon-fontello-filter" />
          &nbsp;
        </span>
        &nbsp;
        {!!columnFilters[propertyName] && (
          <span
            title={getText("axoEntityidcode207", "Fjern filter")}
            onClick={() => this.removeColumnFilter(propertyName)}
          >
            &nbsp;
            <Icon className="axored" glyph="icon-fontello-trash" />
            &nbsp;
          </span>
        )}
      </span>
    );
  };

  // renderTable = (params = { allowSpaceForPicture: false, hasMissingAccount: false, hasMissingAmount: false} ) => {
  renderTable = (
    params = { allowSpaceForPicture: false, hasMissingAccount: false }
  ) => {
    let {
      sortedDataList,
      colSortDirs,
      columnDefs,
      showAllAccounts,
      showAllBalanceAccounts,
      // selectedEntryId,
      receiptsWhoseDatesCouldNotBeScanned,
      creditLabel,
      debitLabel,
      uploadLabel,
      restoreLabel,
      deleteLabel,
      copyLabel,
      // chooseLabel,
      vatErrorEntries,
      // selectedEntryColumn,
      addingPageToId,
      addedPageToId,
      tableEntries,
      scrollToColumnIndex,
      columnFilters,
      // creditDebitFilter
      // columnFilters
    } = this.state;

    const {
      containerHeight,
      containerWidth,
      onCopy,
      locale,
      viewType,
      selectedContact,
      userProfile,
      // onlyTable
    } = this.props;

    let tableHeight = containerHeight;
    let tableWidth = Math.max(750, containerWidth - 25);

    if (params.allowSpaceForPicture) {
      tableWidth = (containerWidth - 25) * 0.66;
    }

    let {
      standardAccounts,
      incomeAccounts,
      expenseAccounts,
      incomePaymentAccounts,
      expensePaymentAccounts,
    } = this.getAccountOptions();

    let balanceControl = this.getPrimaryAccountBalance();
    let showBalanceAccountWarnings = Math.abs(balanceControl) > 0.1;

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;

    let isTrashCan = viewType === ViewType.TRASH;

    // let textOptions = tableEntries
    //   .filter(e => !!e.description)
    //   .map(e => ({ value: e.id, label: e.description }));

    let readonly = this.isReadOnly();

    let allSelected =
      selectedEntries.size > 0 &&
      selectedEntries.size === sortedDataList.getSize();

    let departmentIdMap = {};
    let departmentOptions = [];
    if (
      !!selectedContact.departments &&
      selectedContact.departments.length > 0
    ) {
      selectedContact.departments.forEach((d) => {
        let name = ContactService.getContactFullName(d);
        departmentIdMap[d.id] = name;
        departmentOptions.push({
          value: d.id,
          label: name,
        });
      });
    }

    let ColumnFilterButtons = this.ColumnFilterButtons;
    return (
      <div ref={this.tableContainer}>
        <Table
          rowHeight={40}
          onRowDoubleClick={(event, index) =>
            this.onDisplayEntry(sortedDataList.getObjectAt(index).id)
          }
          rowsCount={sortedDataList.getSize()}
          rowClassNameGetter={this._rowClassNameGetter}
          height={tableHeight}
          width={tableWidth}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          scrollToColumn={scrollToColumnIndex}
          headerHeight={40}
        >
          {!readonly && (
            <Column
              columnKey="select"
              header={
                <Cell className="text-center">
                  <AxoCheckbox
                    checked={allSelected}
                    onChange={this.onSelectAll}
                  />
                </Cell>
              }
              cell={(props) => {
                var entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} className="text-center" {...props}>
                    <AxoCheckbox
                      checked={selectedEntries.has(entry.id)}
                      onChange={this.onSelectEntry.bind(this, entry.id)}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.select.width}
              isResizable={true}
            />
          )}
          <Column
            columnKey="receiptNumber"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.receiptNumber}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;#
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="receiptNumber"
                      filterType={FilterTypes.NUMBER}
                    />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="text-center">
                  {readonly ? (
                    <div>#{entry.receiptNumber}</div>
                  ) : (
                    <InlineNumberEdit
                      value={entry.receiptNumber || "---"}
                      format={(value) => {
                        return <span>#{value}</span>;
                      }}
                      change={(input) =>
                        this.onChangePropertyValue(
                          entry.id,
                          "receiptNumber",
                          parseInt(input.value, 10)
                        )
                      }
                      onEdit={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onFinish={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.receiptNumber.width}
            isResizable={true}
          />
          <Column
            columnKey="isIncome"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.isIncome}
              >
                <Flexbox alignCenter>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    {/* Type */}
                  </FlexElement>
                  <FlexElement
                    flexGrow={1}
                    className="text-right"
                    style={{ paddingRight: "5px" }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {columnFilters.isIncome > 0 && (
                      <span
                        title={getText("axoEntityidcode207", "Fjern filter")}
                        onClick={() => this.updateColumnFilter("isIncome", 0)}
                      >
                        &nbsp;
                        <Icon className="axored" glyph="icon-fontello-trash" />
                        &nbsp;
                      </span>
                    )}
                    <select
                      value={columnFilters.isIncome}
                      onChange={(event) =>
                        this.updateColumnFilter(
                          "isIncome",
                          parseInt(event.target.value, 10)
                        )
                      }
                    >
                      <option value={0}>Alle</option>
                      <option value={1}>Kredit</option>
                      <option value={2}>Debet</option>
                    </select>
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>
                      {entry.isIncome ? (
                        <span>{creditLabel}</span>
                      ) : (
                        <span>{debitLabel}</span>
                      )}
                    </div>
                  ) : (
                    <div>
                      <select
                        value={entry.isIncome ? "credit" : "debit"}
                        onChange={async (event) => {
                          let isCredit =
                            event.target.value === "credit" ? true : false;
                          await this.onChangePropertyValue(
                            entry.id,
                            "isIncome",
                            isCredit
                          );
                          //Set account on all selected entries
                          if (selectedEntries.size > 0) {
                            let promises = [];
                            [...selectedEntries]
                              .filter((id) => id !== entry.id)
                              .forEach((id) => {
                                let selectedEntry = tableEntries.find(
                                  (e) => e.id === id
                                );
                                if (!selectedEntry) {
                                  return;
                                }

                                promises.push(
                                  this.onChangePropertyValue(
                                    selectedEntry.id,
                                    "isIncome",
                                    isCredit,
                                    false
                                  )
                                );
                              });
                            await Promise.all(promises);
                            DataStore.fetchBookkeepingDraftEntries();
                          }
                        }}
                      >
                        <option value="credit">{creditLabel}</option>
                        <option value="debit">{debitLabel}</option>
                      </select>
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.isIncome.width}
            isResizable={true}
          />
          <Column
            columnKey="description"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.description}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons propertyName="description" />
                    {/*)}
                    <span onClick={(event) => { event.stopPropagation() }}>
                      <AxoCheckbox
                        checked={selectedEntryColumn === 'description'}
                        onChange={() => this.onSelectEntryColumn('description')}
                      />
                    </span> */}
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="axoTableText">
                  {/* { entry.status === 'Approved' ? (
                  <div>{entry.description}</div>
                ) : (
                  <InlineEdit
                    value={entry.description || "---"}
                    change={input => this.onChangeDescription(entry.id, input.value)}
                  />
                ) } */}
                  {/* <ReactSelect
                  name="select"
                  menuPortalTarget={document.body}
                  menuPlacement='auto'
                  closeMenuOnScroll
                  styles={this.colourStyles}
                  // menuPlacement='top'
                  // value={}
                  intputValue={entry.description}
                  onInputChange={input => this.onChangeDescription(entry.id, input )}
                  // onChange={(selectedAccount) => {
                  //   if(!!selectedAccount) {
                  //     this.updateMainAccountAndVat(entry, selectedAccount.value);
                  //   }
                  // }}
                  noOptionsMessage={() => ''}
                  options={textOptions}
                  filterOption={this.filterValues}
                /> */}
                  {readonly ? (
                    <span>{entry.description}</span>
                  ) : (
                    <InlineAutoComplete
                      id={entry.id}
                      value={entry.description || "---"}
                      suggestions={Uniqueomat.unique(
                        tableEntries
                          .filter((e) => !!e.description)
                          .map((e) => e.description)
                      )}
                      onChange={(value) => {
                        this.onChangeDescription(entry.id, value);
                      }}
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.description.width}
            isResizable={true}
          />
          <Column
            columnKey="invoiceNumber"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                altIcon={
                  <Icon title="Faktura nummer" glyph="icon-fontello-doc" />
                }
                showAltIcon={columnDefs.invoiceNumber.width < 90}
                sortDir={colSortDirs.invoiceNumber}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp; Fakt. #
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="invoiceNumber"
                      filterType={FilterTypes.TEXT}
                    />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>#{entry.invoiceNumber}&nbsp;</div>
                  ) : (
                    <InlineEdit
                      value={entry.invoiceNumber || "---"}
                      change={(input) =>
                        this.onChangePropertyValue(
                          entry.id,
                          "invoiceNumber",
                          input.value
                        )
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.invoiceNumber.width}
            isResizable={true}
          />
          <Column
            columnKey="creationDate"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.creationDate}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="TimeEntryFormntimeEntry"
                      defaultValue={"Dato"}
                    />
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="creationDate"
                      filterType={FilterTypes.DATE}
                    />
                    {/* <span onClick={(event) => { event.stopPropagation() }}>
                      <AxoCheckbox
                        checked={selectedEntryColumn === 'creationDate'}
                        onChange={() => this.onSelectEntryColumn('creationDate')}
                      />
                    </span> */}
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              // let contentRect = this['cell-' + entry.id] ? this['cell-' + entry.id].getBoundingClientRect(): null;
              let showScanwarning =
                receiptsWhoseDatesCouldNotBeScanned.includes(entry.receiptId);

              // let tableRect = !!this.tableContainer.current ? this.tableContainer.current.getBoundingClientRect(): null;

              // let renderDateTime = !!contentRect;
              // if(!!contentRect && !!tableRect) {
              //   if(contentRect.top < tableRect.top + 60 //Make space for the header column
              //     || contentRect.bottom > tableRect.bottom - 25) {
              //       renderDateTime = false;
              //     }
              // }

              return (
                <Cell key={entry.id} {...props}>
                  {/* { entry.status === 'Approved' ? (
                  <div>{moment.utc(entry.creationDate).format('L')}</div>
                ) : (
                  <input type="date" style={{ border: showScanwarning ? '1px solid red' : 'none' }} 
                    // onChange={event => this.updateDate(entry, event.target.valueAsDate)} 
                    value={moment.utc(entry.creationDate).format('YYYY-MM-DD')}
                  />
                ) } */}

                  {readonly ? (
                    <div>{moment.utc(entry.creationDate).format("L")}</div>
                  ) : (
                    <div
                      style={{
                        border: showScanwarning ? "1px solid red" : "none",
                      }}
                    >
                      <AxoDateTimeEdit
                        locale={locale}
                        date={moment.utc(entry.creationDate)}
                        onChange={(newMoment) =>
                          this.updateDateFromMoment(entry, newMoment)
                        }
                      />
                    </div>
                  )}

                  {/* <div ref={(element) => this['cell-' + entry.id] = element}>
                { renderDateTime && <>
                  { ReactDOM.createPortal(
                    <div style={ contentRect && { position: 'fixed', 
                      top: (contentRect.top - 15), 
                      left: contentRect.left,
                      // maxHeight: contentRect.bottom - contentRect.top,
                      maxWidth: contentRect.right - contentRect.left,
                      border: showScanwarning ? '1px solid red' : 'none',
                      zIndex: 900 + props.rowIndex //Ensures that lower popups cover the higher ones
                      // overflow: 'hidden'
                  }}>
                    <AxoDateTime 
                      className='rdtPickerOpenUpwards'
                      utc
                      value={moment.utc(entry.creationDate)}
                      onChange={(newDate) => this.updateDateFromMoment(entry, newDate)}
                      dateFormat={true} 
                      timeFormat={false} 
                    /></div>,
                    document.body
                  ) }
                </>}
                </div> */}
                </Cell>
              );
            }}
            width={columnDefs.creationDate.width}
            isResizable={true}
          />
          <Column
            columnKey="account"
            header={
              <SortHeaderCell
                key={showAllAccounts.toString()}
                onSortChange={(columnKey, sortDir) =>
                  this._onSortChange(columnKey, sortDir, this.sortByAccount)
                }
                sortDir={colSortDirs.account}
              >
                <Flexbox>
                  <FlexElement flexGrow={1}>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="AccountingTabViewEntity13"
                      defaultValue={"Konto"}
                    />
                    {params.hasMissingAccount ? (
                      <span>
                        &nbsp;
                        <Icon glyph="icon-fontello-circle" className="axored" />
                      </span>
                    ) : null}
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="financeAccountId"
                      filterType={FilterTypes.ACCOUNT}
                    />
                  </FlexElement>
                  {/* <FlexElement flexGrow={1} className='text-right'>
                      <span onClick={(event) => { event.stopPropagation() }}>
                        <AxoCheckbox
                          checked={selectedEntryColumn === 'account'}
                          onChange={() => this.onSelectEntryColumn('account')}
                        />
                      </span>
                    </FlexElement> */}
                  {/* <FlexElement className='almfontcolor' onClick={event => event.stopPropagation()}>
                      <input
                        id='accountCheck'
                        type='checkbox' 
                        checked={showAllAccounts}
                        onChange={event => this.setState({ showAllAccounts: event.target.checked })}
                      />
                      <label htmlFor="accountCheck" style={{ margin: '0px' }}>
                        &nbsp;<AxoLocal entity='axoidcode124' defaultValue={'Vis alle'}/>
                      </label>
                      &nbsp;
                    </FlexElement> */}
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let account = standardAccounts.find(
                (a) => a.id === entry.financeAccountId
              );
              let accountOptions = entry.isIncome
                ? incomeAccounts
                : expenseAccounts;

              //Ensure current account remains an option
              if (
                !!account &&
                !accountOptions.find((a) => a.id === account.id)
              ) {
                accountOptions = [...accountOptions, account];
              }

              let selectOptions = accountOptions.map((f) => {
                return {
                  value: f.id,
                  label: f.number.toString() + " - " + f.name,
                };
              });

              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>
                      {!!account
                        ? account.number.toString() + " - " + account.name
                        : ""}
                    </div>
                  ) : (
                    <AxoSelect
                      name="select"
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      closeMenuOnScroll
                      styles={this.colourStyles}
                      // menuPlacement='top'
                      className={!entry.financeAccountId ? "selectBorder" : ""}
                      value={
                        entry.financeAccountId
                          ? selectOptions.find(
                              (o) => o.value === entry.financeAccountId
                            )
                          : {
                              value: "",
                              label: getText("axoAccounting6f", "Vælg konto"),
                            }
                      }
                      onChange={async (selectedAccount) => {
                        if (!!selectedAccount) {
                          this.updateMainAccountAndVat(
                            entry,
                            selectedAccount.value
                          );

                          //Set account on all selected entries
                          if (selectedEntries.size > 0) {
                            let updateFunc = async () => {
                              await ApiService.updateBookkeepingDraftEntryList({
                                entryIds: [...selectedEntries],
                                newAccountId: selectedAccount.value,
                              });
                              DataStore.fetchBookkeepingDraftEntries();
                            };

                            if (allSelected) {
                              ModalService.openConfirmModal(
                                "Opdater alle posteringer?",
                                (value) => {
                                  if (value) {
                                    updateFunc();
                                  }
                                }
                              );
                            } else {
                              updateFunc();
                            }
                          }
                        }
                      }}
                      noOptionsMessage={() => ""}
                      options={[
                        {
                          value: "",
                          label: getText("axoAccounting6f", "Vælg konto"),
                        },
                        ...selectOptions,
                      ]}
                      onFocus={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onBlur={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.account.width}
            isResizable={true}
          />
          <Column
            columnKey="amount"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.amount}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
                    {/* { params.hasMissingAmount ? (
                      <span>&nbsp;<Icon glyph='icon-fontello-circle' className='axored'/></span>
                    ) : null } */}
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="amount"
                      filterType={FilterTypes.NUMBER}
                    />
                    {/* <span onClick={(event) => { event.stopPropagation() }}>
                      <AxoCheckbox
                        checked={selectedEntryColumn === 'amount'}
                        onChange={() => this.onSelectEntryColumn('amount')}
                      />
                    </span> */}
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="text-right">
                  {readonly ? (
                    <div>{entry.amount}</div>
                  ) : (
                    <InlineNumberEdit
                      decimal
                      value={entry.amount || 0}
                      change={(input) =>
                        this.updateAmountAndVat(
                          entry.id,
                          parseFloat(input.value)
                        )
                      }
                      onEdit={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onFinish={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.amount.width}
            isResizable={true}
          />
          <Column
            columnKey="vat"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.vat}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="invoiPaymentattheSubtotalVAT"
                      defaultValue={"Moms"}
                    />
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="vat"
                      filterType={FilterTypes.NUMBER}
                    />
                    {/* <span onClick={(event) => { event.stopPropagation() }}>
                      <AxoCheckbox
                        checked={selectedEntryColumn === 'vat'}
                        onChange={() => this.onSelectEntryColumn('vat')}
                      />
                    </span> */}
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let isReverseVat = this.isReverseVat(entry);

              return (
                <Cell key={entry.id} {...props} className="text-right">
                  <>
                    {isReverseVat && <>((</>}
                    {readonly ? (
                      <div>{entry.vat}</div>
                    ) : (
                      <InlineNumberEdit
                        decimal
                        className={
                          !!entry.taxAccountId && !entry.vat
                            ? "selectBorder"
                            : ""
                        }
                        value={entry.vat || 0}
                        change={(input) => {
                          this.onChangePropertyValue(
                            entry.id,
                            "vat",
                            parseFloat(input.value)
                          );
                          if (vatErrorEntries.has(entry.id)) {
                            this.setState({
                              vatErrorEntries: new Set(
                                [...vatErrorEntries].filter(
                                  (id) => id !== entry.id
                                )
                              ),
                            });
                          }
                        }}
                        onEdit={() =>
                          this.setState({
                            scrollToColumnIndex:
                              columnDefs[props.columnKey].index,
                          })
                        }
                        onFinish={() =>
                          this.setState({ scrollToColumnIndex: null })
                        }
                      />
                    )}
                    {isReverseVat && <>))</>}
                  </>
                </Cell>
              );
            }}
            width={columnDefs.vat.width}
            isResizable={true}
          />
          <Column
            columnKey="balanceAccount"
            header={
              <SortHeaderCell
                key={showAllBalanceAccounts.toString()}
                onSortChange={(columnKey, sortDir) =>
                  this._onSortChange(
                    columnKey,
                    sortDir,
                    this.sortByBalanceAccount
                  )
                }
                sortDir={colSortDirs.balanceAccount}
              >
                <Flexbox>
                  <FlexElement flexGrow={1}>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="updategetAccountName1"
                      defaultValue={"Modkonto"}
                    />
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="balanceFinanceAccountId"
                      filterType={FilterTypes.ACCOUNT}
                    />
                    {/* <span onClick={(event) => { event.stopPropagation() }}>
                      <AxoCheckbox
                        checked={selectedEntryColumn === 'balanceAccount'}
                        onChange={() => this.onSelectEntryColumn('balanceAccount')}
                      />
                    </span> */}
                  </FlexElement>
                  {/* <FlexElement className='almfontcolor' onClick={event => event.stopPropagation()}>
                    <input
                      id='balanceAccountCheck'
                      type='checkbox' 
                      checked={showAllBalanceAccounts}
                      onChange={event => {
                        event.stopPropagation();
                        this.setState({ showAllBalanceAccounts: event.target.checked });
                      }}
                    />
                    <label htmlFor="balanceAccountCheck" style={{ margin: '0px' }}>
                      &nbsp;<AxoLocal entity='axoidcode124' defaultValue={'Vis alle'}/>
                    </label>
                    &nbsp;
                  </FlexElement> */}
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let account = standardAccounts.find(
                (a) => a.id === entry.balanceFinanceAccountId
              );
              let accountOptions = entry.isIncome
                ? incomePaymentAccounts
                : expensePaymentAccounts;

              //Ensure current account remains an option
              if (
                !!account &&
                !accountOptions.find((a) => a.id === account.id)
              ) {
                accountOptions = [...accountOptions, account];
              }

              let selectOptions = accountOptions
                .filter((a) => a.id !== entry.financeAccountId)
                .map((f) => {
                  return {
                    value: f.id,
                    label: f.number.toString() + " - " + f.name,
                  };
                });

              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>
                      {!!account
                        ? account.number.toString() + " - " + account.name
                        : ""}
                    </div>
                  ) : (
                    <AxoSelect
                      name="select"
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      styles={this.colourStyles}
                      // menuPlacement='top'
                      className={
                        !entry.balanceFinanceAccountId &&
                        showBalanceAccountWarnings
                          ? "selectBorder"
                          : ""
                      }
                      value={
                        !!entry.balanceFinanceAccountId
                          ? selectOptions.find(
                              (o) => o.value === entry.balanceFinanceAccountId
                            )
                          : {
                              value: "",
                              label: getText("axoAccounting6f", "Vælg konto"),
                            }
                      }
                      onChange={async (selectedAccount) => {
                        if (!!selectedAccount) {
                          this.onChangePropertyValue(
                            entry.id,
                            "balanceFinanceAccountId",
                            selectedAccount.value
                          );

                          //Set balance account on all selected entries
                          if (selectedEntries.size > 0) {
                            let updateFunc = async () => {
                              await ApiService.updateBookkeepingDraftEntryList({
                                entryIds: [...selectedEntries],
                                newBalanceAccountId: selectedAccount.value,
                              });
                              DataStore.fetchBookkeepingDraftEntries();
                            };

                            if (allSelected) {
                              ModalService.openConfirmModal(
                                "Opdater alle posteringer?",
                                (value) => {
                                  if (value) {
                                    updateFunc();
                                  }
                                }
                              );
                            } else {
                              updateFunc();
                            }
                          }
                        }
                      }}
                      noOptionsMessage={() => ""}
                      options={[
                        {
                          value: "",
                          label: getText("axoAccounting6f", "Vælg konto"),
                        },
                        ...selectOptions,
                      ]}
                      onFocus={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onBlur={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.balanceAccount.width}
            isResizable={true}
          />
          <Column
            columnKey="receipt"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.receipt}
              >
                <Flexbox alignCenter>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    {/* Type */}
                  </FlexElement>
                  <FlexElement
                    flexGrow={1}
                    className="text-right"
                    style={{ paddingRight: "5px" }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {columnFilters.receipt > 0 && (
                      <span
                        title={getText("axoEntityidcode207", "Fjern filter")}
                        onClick={() => this.updateColumnFilter("receipt", 0)}
                      >
                        &nbsp;
                        <Icon className="axored" glyph="icon-fontello-trash" />
                        &nbsp;
                      </span>
                    )}
                    <select
                      value={columnFilters.receipt}
                      onChange={(event) =>
                        this.updateColumnFilter(
                          "receipt",
                          parseInt(event.target.value, 10)
                        )
                      }
                    >
                      <option value={0}>Alle</option>
                      <option value={1}>Med</option>
                      <option value={2}>Uden</option>
                    </select>
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {!!entry.receipt ? (
                    <div className="inlineBlock">
                      {readonly ? (
                        <>
                          <div className="inlineBlock">
                            #{entry.receiptNumber}
                          </div>
                          &nbsp;
                          <Icon
                            className="editable"
                            role="button"
                            onClick={() => this.onShowReceiptForEntry(entry.id)}
                            glyph="icon-fontello-attach-7"
                          />
                        </>
                      ) : (
                        <>
                          <InlineNumberEdit
                            value={entry.receiptNumber || "---"}
                            format={(value) => {
                              return <span>#{value}</span>;
                            }}
                            change={(input) =>
                              this.onChangePropertyValue(
                                entry.id,
                                "receiptNumber",
                                input.value
                              )
                            }
                            onEdit={() =>
                              this.setState({
                                scrollToColumnIndex:
                                  columnDefs[props.columnKey].index,
                              })
                            }
                            onFinish={() =>
                              this.setState({ scrollToColumnIndex: null })
                            }
                          />
                          &nbsp;
                          <Icon
                            className="editable"
                            role="button"
                            onClick={() => this.onShowReceiptForEntry(entry.id)}
                            glyph="icon-fontello-attach-7"
                          />
                          {FileInfoService.isImage(entry.receipt) ? (
                            <React.Fragment>
                              &nbsp;
                              {(() => {
                                if (addingPageToId === entry.id) {
                                  return (
                                    <LoadingIcon
                                      show={addingPageToId === entry.id}
                                    />
                                  );
                                } else if (addedPageToId === entry.id) {
                                  return (
                                    <Icon
                                      className="editable"
                                      glyph="icon-fontello-check"
                                    />
                                  );
                                }
                                return (
                                  <Icon
                                    role="button"
                                    className="editable"
                                    glyph="icon-fontello-plus-2"
                                    onClick={() =>
                                      this.onAddImagePage(entry.id)
                                    }
                                    title={getText(
                                      "axoidcode209",
                                      "Tilføj side"
                                    )}
                                  />
                                );
                              })()}
                            </React.Fragment>
                          ) : null}
                          &nbsp;
                          <Icon
                            title={getText("axoidcode9", "Fjern bilagsfil")}
                            className="editable"
                            role="button"
                            onClick={() => this.onDeleteReceiptForEntry(entry)}
                            glyph="icon-fontello-trash-1"
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {!readonly && (
                        <div className="inlineBlock">
                          &nbsp;
                          <Icon
                            title={uploadLabel}
                            className="editable"
                            role="button"
                            onClick={() =>
                              this.onUploadReceiptForExistingEntry(entry.id)
                            }
                            glyph="icon-fontello-upload-4"
                          />
                        </div>
                        // <div className='inlineBlock'>
                        //   <LexButton onClick={() => this.onUploadReceiptForExistingEntry(entry.id)}>
                        //     {uploadLabel}
                        //   </LexButton>
                        // </div>
                      )}
                      {/* { !onlyTable ? (
                      <React.Fragment>
                        &nbsp;
                        <input 
                          type='checkbox' 
                          checked={selectedEntryId === entry.id}
                          disabled={!!selectedEntryId && selectedEntryId !== entry.id}
                          onChange={(event) => this.onSelectReceiptEntry(entry.id, event.target.checked)}
                        />
                        {chooseLabel}
                      </React.Fragment>
                    ) : null } */}
                    </>
                  )}
                  {!!entry.invoiceId && (
                    <div className="inlineBlock">
                      &nbsp;
                      <Icon
                        title={getText("axoEntityidcode28", "Gå til faktura")}
                        className="editable"
                        role="button"
                        onClick={() =>
                          this.props.history.push(
                            RoutingService.getPath(
                              "Invoices/Single/" + entry.invoiceId
                            )
                          )
                        }
                        glyph="icon-simple-line-icons-user-following"
                      />
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.receipt.width}
            isResizable={true}
          />
          <Column
            columnKey="vatNumber"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.vatNumber}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="SidebarRightContainerCompanyCode"
                      defaultValue={"CVR"}
                    />
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    {/* { !!userProfile && userProfile.userType === 'Admin' && ( */}
                    <ColumnFilterButtons propertyName="vatNumber" />
                    {/* )}
                    <span onClick={(event) => { event.stopPropagation() }}>
                      <AxoCheckbox
                        checked={selectedEntryColumn === 'vatNumber'}
                        onChange={() => this.onSelectEntryColumn('vatNumber')}
                      />
                    </span> */}
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="axoTableText">
                  {readonly ? (
                    <div>{entry.vatNumber}</div>
                  ) : (
                    <InlineEdit
                      value={entry.vatNumber || "---"}
                      change={(input) =>
                        this.onChangeVatNumber(entry.id, input.value)
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.vatNumber.width}
            isResizable={true}
          />
          {!!selectedContact.departments &&
            selectedContact.departments.length > 0 && (
              <Column
                columnKey="department"
                header={
                  <SortHeaderCell
                    onSortChange={(columnKey, sortDir) =>
                      this._onSortChange(columnKey, sortDir, (l, r) =>
                        this.sortByDepartment(departmentIdMap, l, r)
                      )
                    }
                    sortDir={colSortDirs.department}
                  >
                    <Flexbox>
                      <FlexElement>
                        <Icon
                          className="editable"
                          glyph="icon-fontello-arrow-combo"
                        />
                        &nbsp; Afdeling
                      </FlexElement>
                    </Flexbox>
                  </SortHeaderCell>
                }
                cell={(props) => {
                  let entry = sortedDataList.getObjectAt(props.rowIndex);
                  let departmentName =
                    departmentIdMap[entry.contactInfoId] || "";

                  return (
                    <Cell key={entry.id} {...props}>
                      {readonly ? (
                        <>{departmentName}</>
                      ) : (
                        <AxoSelect
                          name="select"
                          menuPortalTarget={document.body}
                          menuPlacement="auto"
                          closeMenuOnScroll
                          styles={this.colourStyles}
                          // menuPlacement='top'
                          className={
                            !entry.financeAccountId ? "selectBorder" : ""
                          }
                          value={
                            !!departmentName
                              ? departmentOptions.find(
                                  (o) => o.value === entry.contactInfoId
                                )
                              : { value: "", label: "Vælg afdeling" }
                          }
                          onChange={async (selectedDepartment) => {
                            if (
                              !!selectedDepartment &&
                              !!selectedDepartment.value
                            ) {
                              DataActions.updateBookkeepingDraftEntry({
                                ...entry,
                                contactInfoId: selectedDepartment.value,
                              });
                            }
                          }}
                          noOptionsMessage={() => ""}
                          options={[
                            { value: "", label: "Vælg afdeling" },
                            ...departmentOptions,
                          ]}
                          onFocus={() =>
                            this.setState({
                              scrollToColumnIndex:
                                columnDefs[props.columnKey].index,
                            })
                          }
                          onBlur={() =>
                            this.setState({ scrollToColumnIndex: null })
                          }
                        />
                      )}
                    </Cell>
                  );
                }}
                width={columnDefs.department.width}
                isResizable={true}
              />
          )}
          <Column
            columnKey="exchangeRate"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.exchangeRate}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    Kurs
                    {/* Missing Translation */}
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="exchangeRate"
                      filterType={FilterTypes.NUMBER}
                    />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);

              return (
                <Cell key={entry.id} {...props} className="text-right">
                  <>
                    {readonly ? (
                      <div>{entry.exchangeRate}</div>
                    ) : (
                      <InlineNumberEdit
                        decimal
                        value={entry.exchangeRate || 0}
                        change={(input) => {
                          this.onChangePropertyValue(
                            entry.id,
                            "exchangeRate",
                            parseFloat(input.value)
                          );
                        }}
                        onEdit={() =>
                          this.setState({
                            scrollToColumnIndex:
                              columnDefs[props.columnKey].index,
                          })
                        }
                        onFinish={() =>
                          this.setState({ scrollToColumnIndex: null })
                        }
                      />
                    )}
                  </>
                </Cell>
              );
            }}
            width={columnDefs.exchangeRate.width}
            isResizable={true}
          />
          <Column
            columnKey="userName"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.userName}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    Bruger
                  </FlexElement>
                  <FlexElement flexGrow={1} className="text-right">
                    <ColumnFilterButtons
                      propertyName="userName"
                      filterType={FilterTypes.STRING}
                    />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="text-right">
                  <div>{entry.userName}</div>
                </Cell>
              );
            }}
            width={columnDefs.userName.width}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              // let confirmDeleting = this.state.confirmDeleteEntryId === entry.id;
              let deleting = this.state.deletingId === entry.id;
              return (
                <Cell key={entry.id} {...props}>
                  {!readonly ? (
                    <>
                      &nbsp;
                      <Icon
                        title={deleteLabel}
                        className="editable"
                        role="button"
                        onClick={() => this.onDeleteEntry(entry)}
                        glyph="icon-fontello-trash-1"
                      />
                      <LoadingIcon show={deleting} />
                      &nbsp;&nbsp;&nbsp;
                      {!isTrashCan ? (
                        <Icon
                          title={copyLabel}
                          className="editable"
                          role="button"
                          onClick={() => onCopy(entry)}
                          glyph="icon-fontello-docs-1"
                        />
                      ) : (
                        <Icon
                          title={restoreLabel}
                          className="editable"
                          role="button"
                          onClick={() =>
                            DataActions.updateBookkeepingDraftEntry({
                              ...entry,
                              trashed: false,
                            })
                          }
                          glyph="icon-fontello-upload-2"
                        />
                        // <AsyncButton onClick={() => DataActions.updateBookkeepingDraftEntry({
                        //   ...entry,
                        //   trashed: false
                        // })}>
                        //   <AxoLocal entity='DocumentTrashTableViewrestore'defaultValue={'Gendan'}/>
                        // </AsyncButton>
                      )}
                    </>
                  ) : (
                    // <ButtonToolbar>
                    //   {/* <LexButton disabled={!entry.financeAccountId || !entry.balanceFinanceAccountId || entry.amount <= 0} onClick={() => this.onChangePropertyValue(entry.id, 'status', 'Approved')}>
                    //     <Icon glyph='icon-fontello-check'/> &nbsp;&nbsp;
                    //     <AxoLocal entity='friendRequestTimelineonApproveRequest'defaultValue={'Godkend'}/>
                    //   </LexButton> */}
                    //   <LexButton disabled={confirmDeleting || deleting} onClick={this.onDeleteEntry.bind(this, entry)}>
                    //     <Icon glyph='icon-fontello-trash-1'/>&nbsp;&nbsp;
                    //       {deleteLabel}
                    //     <LoadingIcon show={deleting} />
                    //   </LexButton>
                    //   { !isTrashCan ? (
                    //     <AsyncButton onClick={() => onCopy(entry)}>
                    //       {copyLabel}
                    //     </AsyncButton>
                    //   ) : (
                    //     <AsyncButton onClick={() => DataActions.updateBookkeepingDraftEntry({
                    //       ...entry,
                    //       trashed: false
                    //     })}>
                    //       <AxoLocal entity='DocumentTrashTableViewrestore'defaultValue={'Gendan'}/>
                    //     </AsyncButton>
                    //   ) }

                    // </ButtonToolbar>
                    <></>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };

  getButtonLabel = (defaultLabel, uploading, scanning) => {
    if (uploading) {
      return (
        <span>
          <AxoLocal
            key="upload"
            entity="updategetAccountName3"
            defaultValue={"Uploader"}
          />{" "}
        </span>
      );
    }
    if (scanning) {
      let { documentsScanned, documentsToBeScanned } = this.state;

      return (
        <span>
          <AxoLocal
            key="scan"
            entity="updategetAccountName4"
            defaultValue={"Scanner"}
          />
          &nbsp;({documentsScanned + 1} / {documentsToBeScanned})
        </span>
      );
    }
    return <span>{defaultLabel}</span>;
  };

  getFileErrorMessage = () => {
    let { showFileMaxSizeWarning, showStorageWarning, showFileUploadError } =
      this.state;
    if (showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    if (showStorageWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarningA"
            defaultValue={"Ikke mere plads. Slet filer."}
          />
        </div>
      );
    }
    if (showFileUploadError) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesError"
            defaultValue={"Upload fejlede"}
          />
        </div>
      );
    }
    return <span></span>;
  };

  onShowAllAccounts = (event) => {
    this.setState({ showAllAccounts: event.target.checked });
  };

  onShowAllBalanceAccounts = (event) => {
    this.setState({ showAllBalanceAccounts: event.target.checked });
  };

  renderGridView = () => {
    let {
      sortedDataList,
      receiptsWhoseDatesCouldNotBeScanned,
      showAllAccounts,
      showAllBalanceAccounts,
      addingPageToId,
      addedPageToId,
    } = this.state;

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;

    let readonly = this.isReadOnly();

    return (
      <div style={{ margin: "auto" }}>
        <BookkeepingGridView
          entries={sortedDataList._data}
          readonly={readonly}
          {...this.getAccountOptions()}
          onDeleteEntry={this.onDeleteEntry}
          onShowReceipt={this.onShowReceiptForEntry}
          onUploadReceiptForExistingEntry={this.onUploadReceiptForExistingEntry}
          onChangePropertyValue={this.onChangePropertyValue}
          updateMainAccountAndVat={this.updateMainAccountAndVat}
          selectedEntries={selectedEntries}
          onSelectEntry={this.onSelectEntry}
          receiptsWhoseDatesCouldNotBeScanned={
            receiptsWhoseDatesCouldNotBeScanned
          }
          updateDate={this.updateDate}
          showAllAccounts={showAllAccounts}
          showAllBalanceAccounts={showAllBalanceAccounts}
          onShowAllAccounts={this.onShowAllAccounts}
          onShowAllBalanceAccounts={this.onShowAllBalanceAccounts}
          updateAmountAndVat={this.updateAmountAndVat}
          onAddImagePage={this.onAddImagePage}
          addingPageToId={addingPageToId}
          addedPageToId={addedPageToId}
        />
      </div>
    );
  };

  onUploadCSV() {
    $("#csvUpload").trigger("click");
  }

  onAddEntry = async (isCredit) => {
    let { selectedContact } = this.props;
    let { searchText } = this.state;

    let newCreationDate = this.getNewCreationDate();

    let response = await this.createNewEntry({
      contactInfoId: selectedContact.id,
      description: searchText,
      balanceFinanceAccountId: this.getDefaultPaymentAccount(),
      isIncome: isCredit,
      creationDate: newCreationDate,
    });

    return response.ok;
  };

  createNewEntry = async (entry) => {
    let { viewType, actions } = this.props;

    if (viewType === ViewType.NORMAL) {
      return actions.createBookkeepingDraftEntry({
        ...entry,
        status: "Approved",
      });
    }
    return actions.createBookkeepingDraftEntry(entry);
  };

  onPrintReceipts = () => {
    this.receiptsModal.open();
  };

  onRunSimulation = async () => {
    let validFiscalYears = this.testFiscalYearForEntries();
    if (!validFiscalYears) {
      return false;
    }

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;

    try {
      let response = await DataStore.fetchDraftSimulation([...selectedEntries]);
      return !!response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  onFinalizeDrafts = async () => {
    let validFiscalYears = await this.testFiscalYearForEntries();
    if (!validFiscalYears) {
      return false;
    }

    let validBalance = await this.testSimulationBalance();
    if (!validBalance) {
      return false;
    }

    let validTaxes = await this.testVatAccounts();
    if (!validTaxes) {
      return false;
    }

    let { selectedStartDate, selectedEndDate, selectedFiscalYear } = this.props;

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    this.setState({
      confirmPosting: true,
      finalizationStartDate: startDateSelection,
      finalizationEndDate: endDateSelection,
    });

    return true;
  };

  testFiscalYearForEntries = () => {
    let { entries, fiscalYears } = this.props;

    let invalidDateEntries = entries.filter((e) => {
      let entryDate = moment.utc(e.creationDate).startOf("day");
      return !fiscalYears.find((f) => {
        //Fiscal year dates are utc, but we pretend they are local dates to avoid time zone problems.
        var fStart = moment
          .utc(moment.utc(f.startDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");
        var fEnd = moment
          .utc(moment.utc(f.endDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");

        return !entryDate.isBefore(fStart) && !entryDate.isAfter(fEnd);
      });
    });
    //All entries must fit into existing fiscal years
    if (invalidDateEntries.length > 0) {
      this.showWarning("showInvalidDatesWarning");
      this.setState({
        invalidDateEntryIds: new Set(invalidDateEntries.map((e) => e.id)),
      });
      return false;
    }
    let lockedDateEntries = entries.filter((e) => {
      let entryDate = moment.utc(e.creationDate).startOf("day");

      return fiscalYears.find((f) => {
        //Fiscal year dates are utc, but we pretend they are local dates to avoid time zone problems.
        var fStart = moment
          .utc(moment.utc(f.startDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");
        var fEnd = moment
          .utc(moment.utc(f.endDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");

        return (
          !entryDate.isBefore(fStart) && !entryDate.isAfter(fEnd) && f.locked
        );
      });
    });
    // Entries must only use active fiscal years
    if (lockedDateEntries.length > 0) {
      this.showWarning("showLockedFiscalYearsWarning");
      this.setState({
        invalidDateEntryIds: new Set(lockedDateEntries.map((e) => e.id)),
      });
      return false;
    }

    return true;
  };

  testSimulationBalance = async () => {
    try {
      let selectedEntries =
        this.props.selectedEntries || this.state.selectedEntries;

      let simulationResult = await DataStore.fetchDraftSimulationWithoutSaving([
        ...selectedEntries,
      ]);

      if (simulationResult.balance !== 0) {
        this.showWarning("showImbalanceWarning");
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  testVatAccounts = async () => {
    let { entries, financeAccountMap } = this.props;

    let vatErrorEntries = entries.filter((e) => {
      if (!e.vat) {
        return false;
      }
      if (!e.financeAccountId) {
        return true;
      }

      let account = financeAccountMap[e.financeAccountId];
      if (!account.taxSpecificationId) {
        return true;
      }

      return false;
    });

    if (vatErrorEntries.length > 0) {
      let vatErrorEntryIds = new Set(vatErrorEntries.map((e) => e.id));
      this.setState({ vatErrorEntries: vatErrorEntryIds });

      DataStore.setState({
        importantEntryIds: vatErrorEntryIds, //Filtering
      });
      // setTimeout(() => {
      //   this.setState({ vatErrorEntries: new Set() });
      // }, 3000);
    }
    return vatErrorEntries.length === 0;
  };

  doFinalizeDrafts = async () => {
    let {
      // entries,
      selectedContact,
      actions,
      allEntries,
      showOnlyImportant,
      importantEntryIds,
      calculateNumbers,
    } = this.props;

    let { finalizationStartDate, finalizationEndDate } = this.state;

    if (calculateNumbers) {
      let updateNumbersCompleted = await this.updateReceiptNumbers();
      if (!updateNumbersCompleted) {
        return false;
      }
    }

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;
    let entryIds = [];
    if (selectedEntries.size > 0) {
      entryIds = [...selectedEntries];
    } else if (showOnlyImportant && importantEntryIds.size > 0) {
      entryIds = [...importantEntryIds];
    } else {
      // entryIds = entries.map(e => e.id);
      entryIds = allEntries
        .filter((e) => !e.trashed)
        .filter(
          (e) =>
            moment
              .utc(e.creationDate)
              .startOf("day")
              .isSameOrAfter(finalizationStartDate.startOf("day")) &&
            moment
              .utc(e.creationDate)
              .startOf("day")
              .isSameOrBefore(finalizationEndDate.startOf("day"))
        )
        .map((e) => e.id);
    }

    let response = await actions.finalizeDraftEntries(
      entryIds,
      selectedContact.id
    );
    if (!response.ok) {
      return false;
    }

    this.setState({ confirmPosting: false });
    DataStore.setState({
      draftSimulationResult: {
        accountResults: {},
        balance: 0,
      },
      importantEntryIds: new Set(),
    });

    DataStore.updateBankProgress({
      [selectedContact.id]: 0,
    });

    return true;
  };

  updateReceiptNumbers = async () => {
    let { entries, selectedContact } = this.props;

    if (entries.length === 0) {
      return false;
    }

    let sortedEntries = entries.sort((l, r) => {
      if (l.creationDate === r.creationDate) {
        return l.id - r.id; //Ensures consistent sorting of receipts from the same day.
        // return 0;
      }
      return moment.utc(l.creationDate).isBefore(moment.utc(r.creationDate))
        ? -1
        : 1;
    });

    return ApiService.getNextReceiptNumber({
      contactId: selectedContact.id,
      date: moment.utc(sortedEntries[0].creationDate).format(),
    })
      .then(async (nextNumber) => {
        if (!nextNumber) {
          return;
        }
        nextNumber = parseInt(nextNumber, 10);
        // let promises = [];
        // sortedEntries.forEach(entry => {
        //   if(nextNumber === entry.receiptNumber) {
        //     nextNumber++;
        //     return;
        //   }
        //   promises.push(ApiService.updateBookkeepingDraftEntry({
        //     ...entry,
        //     receiptNumber: nextNumber
        //   }))
        //   nextNumber++;
        // });

        let results = [];
        for (var entry of sortedEntries) {
          console.log(nextNumber);
          console.log(entry.receiptNumber);
          if (nextNumber === entry.receiptNumber) {
            console.log("Skipped entry " + entry.receiptNumber);
            nextNumber++;
            continue;
          }
          let response = await ApiService.updateBookkeepingDraftEntry({
            ...entry,
            receiptNumber: nextNumber,
          });
          results.push(response);

          if (!response.ok) {
            return results;
          }

          nextNumber++;
        }

        return results;
      })
      .then(async (results) => {
        await DataStore.fetchBookkeepingDraftEntries();

        return !results.find((r) => !r.ok);
      })
      .catch((reason) => {
        console.error(reason);
        return false;
      });
  };

  disableAddition = () => {
    let { fiscalYears, selectedContact } = this.props;

    let { uploadingIncome, scanningIncome, uploadingExpense, scanningExpense } =
      this.state;

    return (
      !selectedContact.id ||
      uploadingIncome ||
      scanningIncome ||
      uploadingExpense ||
      scanningExpense ||
      !fiscalYears ||
      fiscalYears.length === 0
    );
  };

  onDeleteSelectedEntries = async () => {
    let { viewType } = this.props;

    if (viewType !== ViewType.TRASH) {
      let result = await this.doDeleteSelectedEntries();
      return result;
    }

    this.setState({ confirmDeleteSelected: true });

    return true;
  };

  doDeleteSelectedEntries = async () => {
    let { viewType } = this.props;

    let { selectedEntries } = this.state;

    if (selectedEntries.size === 0) {
      return false;
    }

    // let selectedEntryObjects = tableEntries.filter(e => selectedEntries.has(e.id));
    // let promises = selectedEntryObjects.map(entry => this.doDeleteEntry(entry, false));

    try {
      let isTrash = viewType === ViewType.TRASH;
      let response = await ApiService.updateBookkeepingDraftEntryList({
        entryIds: [...selectedEntries],
        trash: !isTrash,
        delete: isTrash,
      });
      return response.ok;
    } finally {
      this.setState({
        confirmDeleteSelected: false,
        selectedEntries: new Set(),
      });

      DataStore.fetchBookkeepingDraftEntries();
    }
  };

  onApproveSelectedEntries = async () => {
    return this.changeStatusOnSelectedEntries(true);
  };

  onUnApproveSelectedEntries = async () => {
    return this.changeStatusOnSelectedEntries(false);
  };

  changeStatusOnSelectedEntries = async (approve) => {
    let { selectedEntries, tableEntries } = this.state;

    if (selectedEntries.size === 0) {
      return false;
    }

    let selectedEntryObjects = tableEntries.filter((e) =>
      selectedEntries.has(e.id)
    );
    let promises = selectedEntryObjects.map((entry) =>
      ApiService.updateBookkeepingDraftEntry({
        ...entry,
        status: approve ? "Approved" : "Created",
      })
    );

    let responses = await Promise.all(promises);

    DataStore.fetchBookkeepingDraftEntries();

    this.setState({ selectedEntries: new Set() });

    return !responses.find((r) => !r.ok);
  };

  onRestoreSelectedeEntries = async () => {
    let { selectedEntries, tableEntries } = this.state;

    if (selectedEntries.size === 0) {
      return false;
    }

    let selectedEntryObjects = tableEntries.filter((e) =>
      selectedEntries.has(e.id)
    );

    let promises = selectedEntryObjects.map((entry) =>
      ApiService.updateBookkeepingDraftEntry({ ...entry, trashed: false })
    );

    let responses = await Promise.all(promises);

    DataStore.fetchBookkeepingDraftEntries();

    this.setState({ selectedEntries: new Set() });

    return !responses.find((r) => !r.ok);
  };

  _rowClassNameGetter = (rowIndex) => {
    let { importantEntryIds, highlightEntryId, viewType } = this.props;

    let {
      sortedDataList,
      vatErrorEntries,
      invalidDateEntryIds,
      displayedEntryId,
    } = this.state;

    let entry = sortedDataList.getObjectAt(rowIndex);
    if (!entry) {
      return;
    }

    if (displayedEntryId === entry.id) {
      return "highlight-row";
    } else if (highlightEntryId === entry.id) {
      return "highlight-row";
    } else if (vatErrorEntries.has(entry.id)) {
      return "highlight-row-rejected";
    } else if (!!importantEntryIds && importantEntryIds.has(entry.id)) {
      return "highlight-row-approved";
    } else if (invalidDateEntryIds.has(entry.id)) {
      return "highlight-row-rejected";
    } else if (viewType === ViewType.SCAN && !entry.inspected) {
      return "highlight-row-warning";
    }
  };

  getSimulationCSV = () => {
    let { draftSimulationResult } = this.props;

    let headerRow = [
      getText("axoAccounting6", "Kontonummer"),
      getText("Enhedspris6", "Beskrivelse"),
      getText("axoidcode133", "Før"),
      getText("axoidcode133", "Ændring"),
      getText("axoidcode141", "Efter"),
    ];

    let rows = this.getFinanceAccountPlan()
      .accounts.filter((a) => !!draftSimulationResult.accountResults[a.id])
      .map((account) => {
        let accountResults = draftSimulationResult.accountResults;
        return [
          account.number,
          account.number.toString() + " - " + account.name,
          this.formatAmount(accountResults[account.id].before),
          this.formatAmount(accountResults[account.id].change),
          this.formatAmount(accountResults[account.id].after),
        ];
      });

    let balanceRow = [
      getText("axoidcode142", "Balancekontrol"),
      "",
      "",
      this.formatAmount(draftSimulationResult.balance),
      "",
    ];

    return [headerRow, ...rows, balanceRow];
  };

  SimulationResult = (props) => {
    let { draftSimulationResult, showAccountHistoryId } = props;

    let { onlyTable } = this.props;

    let { accountHistoryFilter, confirmPosting } = this.state;

    let AccountHistory = this.AccountHistory;
    if (!!showAccountHistoryId) {
      return (
        <AccountHistory
          accountId={showAccountHistoryId}
          accountHistoryFilter={accountHistoryFilter}
        />
      );
    }

    let SimulationRow = this.SimulationRow;
    return (
      <div className="standardMaxWidth">
        <Flexbox responsive>
          <h4 className="rightPadding">
            {" "}
            <AxoLocal
              entity="axoidcode131"
              defaultValue={"Simuleringsresultat"}
            />
          </h4>
          <FlexElement className="rightPadding">
            <LexButton
              onClick={() =>
                DataStore.setState({
                  draftSimulationResult: {
                    accountResults: {},
                    balance: 0,
                  },
                })
              }
            >
              <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
              {/* <AxoLocal entity='axoidcode132' defaultValue={'Skjul simuleringsresultat'}/> */}
            </LexButton>
          </FlexElement>
          <FlexElement className="rightPadding">
            <CSVLink
              data={this.state.csv}
              separator={";"}
              filename={"Simulation.csv"}
              asyncOnClick={true}
              onClick={(event, done) => {
                this.setState({ csv: this.getSimulationCSV() }, () => done());
              }}
            >
              <LexButton>
                <AxoLocal entity="axoAccounting4" defaultValue={"Eksporter"} />{" "}
              </LexButton>
            </CSVLink>
          </FlexElement>
          {!onlyTable ? (
            <FlexElement className="axoFrontPage">
              <AsyncButton
                onClick={this.onFinalizeDrafts}
                hideOkIcon
                disabled={confirmPosting}
              >
                <AxoLocal entity="axoidcode94" defaultValue={"Bogfør kladde"} />
              </AsyncButton>
            </FlexElement>
          ) : null}
        </Flexbox>
        <BootstrapTable bordered condensed hover>
          <thead>
            <tr>
              <th>
                <AxoLocal
                  entity="axoAccounting6"
                  defaultValue={"Kontonummer"}
                />{" "}
              </th>
              <th>
                <AxoLocal entity="Enhedspris6" defaultValue="Beskrivelse" />{" "}
              </th>
              <th>
                <AxoLocal entity="axoidcode133" defaultValue={"Før"} />
              </th>
              <th>
                <AxoLocal entity="axoidcode134" defaultValue={"Ændring"} />
              </th>
              <th>
                <AxoLocal entity="axoidcode141" defaultValue={"Efter"} />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.getFinanceAccountPlan()
              .accounts.filter(
                (a) => !!draftSimulationResult.accountResults[a.id]
              )
              .map((acc) => (
                <SimulationRow
                  key={acc.id}
                  account={acc}
                  accountResults={draftSimulationResult.accountResults}
                />
              ))}
            <tr key="balance">
              <th>
                <span>
                  <AxoLocal
                    entity="axoidcode142"
                    defaultValue={"Balancekontrol"}
                  />
                </span>
              </th>
              <th></th>
              <th></th>
              <th
                className={draftSimulationResult.balance !== 0 ? "axored" : ""}
              >
                {this.formatAmount(draftSimulationResult.balance)}
              </th>
              <th></th>
            </tr>
          </tbody>
        </BootstrapTable>
      </div>
    );
  };

  getAccountEntries = (accountId) => {
    let { entries, entriesWithoutFilter, postedAccountEntries } = this.props;

    let { accountHistoryFilter, hideApprovedSimulationRows } = this.state;

    let draftEntries = entriesWithoutFilter || entries;

    let accountEntries =
      accountHistoryFilter !== HistoryFilter.POSTED
        ? draftEntries.filter(
            (e) =>
              e.financeAccountId === accountId ||
              e.balanceFinanceAccountId === accountId ||
              (!!e.vat && e.taxAccountId === accountId)
          )
        : [];

    let postedEntries =
      accountHistoryFilter !== HistoryFilter.DRAFT
        ? postedAccountEntries.filter(
            (e) =>
              e.financeAccountId === accountId ||
              e.balanceFinanceAccountId === accountId ||
              (!!e.vat && e.taxAccountId === accountId)
          ) //Filter in case entries for an old account are still in the cache
        : [];

    console.log(postedEntries);

    accountEntries = accountEntries.concat(postedEntries).sort((l, r) => {
      let dateSort =
        moment.utc(l.creationDate).valueOf() -
        moment.utc(r.creationDate).valueOf();
      if (dateSort !== 0) {
        return dateSort;
      }
      return l.receiptNumber - r.receiptNumber;
    });

    if (hideApprovedSimulationRows) {
      //Hide matching entries
      let amountToEntryIds = {};
      let entryIdsToBeRemoved = new Set();
      accountEntries.forEach((entry) => {
        let amount = entry.isIncome ? entry.amount : -entry.amount;
        if (entry.financeAccountId === accountId) {
          //Account and balance account should match each other
          amount = -amount;
        }

        if (!!amountToEntryIds[-amount]) {
          let idArray = amountToEntryIds[-amount];
          var matchingEntryId = idArray[0];

          entryIdsToBeRemoved.add(matchingEntryId);
          entryIdsToBeRemoved.add(entry.id);

          amountToEntryIds[-amount] = idArray.filter(
            (id) => id !== matchingEntryId
          );
          if (amountToEntryIds[-amount].length === 0) {
            delete amountToEntryIds[-amount];
          }
        } else {
          if (!amountToEntryIds[amount]) {
            amountToEntryIds[amount] = [];
          }
          amountToEntryIds[amount].push(entry.id);
        }
      });

      accountEntries = accountEntries.filter(
        (e) => !entryIdsToBeRemoved.has(e.id)
      );

      // //Hide entries approved during bank balancing
      // accountEntries = accountEntries.filter(e => !e.approved);
    }

    return accountEntries;
  };

  getFormattedAccountEntries = (accountId, accountEntries) => {
    let formattedEntries = accountEntries.map((entry) => {
      let isVat = entry.taxAccountId === accountId;
      let isPositive =
        (entry.isIncome && entry.balanceFinanceAccountId === accountId) ||
        (!entry.isIncome && (entry.financeAccountId === accountId || isVat));

      let isBalanceEntry = entry.balanceFinanceAccountId === accountId;

      let amount =
        entry.balanceFinanceAccountId !== accountId
          ? entry.amount - entry.vat
          : entry.amount; //Balance account has the full amount including VAT.
      amount = amount * (isPositive ? 1 : -1);
      let vat =
        entry.balanceFinanceAccountId !== accountId
          ? entry.vat * (isPositive ? 1 : -1)
          : 0;

      return {
        ...entry,
        isVat,
        isBalanceEntry,
        amount,
        vat,
      };
    });

    return formattedEntries;
  };

  getAccountHistoryCSV = (accountId) => {
    let { financeAccountMap } = this.props;

    let account = financeAccountMap[accountId];

    let accountEntries = this.getAccountEntries(accountId);
    let formattedEntries = this.getFormattedAccountEntries(
      accountId,
      accountEntries
    );

    let headerEntry = [
      getText("axoidcode220", "Kontohistorik for"),
      +account.number + " - " + account.name,
      "",
      "",
      "",
      "",
    ];

    let headerEntry2 = [
      getText("TimeEntryFormntimeEntry", "Dato"),
      getText("axoidcode64", "Nummer"),
      getText("axoidcode77", "Note"),
      getText("InvoiceInvoicesum", "Beløb") +
        " " +
        getText("paymentDate10a", "u. moms"),
      getText("invoiPaymentattheSubtotalVAT", "Moms"),
    ];

    let rows = formattedEntries.map((entry) => {
      let isVat = entry.taxAccountId === accountId;
      let formattedAmount = NumberService.formatDecimal(entry.amount);
      let formattedVat = NumberService.formatDecimal(entry.vat);

      return [
        moment.utc(entry.creationDate).format("L"),
        entry.receiptNumber,
        entry.description,
        !isVat ? formattedAmount : formattedVat,
        !isVat ? formattedVat : "",
      ];
    });

    return [headerEntry, headerEntry2, ...rows];
  };

  AccountHistory = (props) => {
    let { financeAccountMap } = this.props;

    let { accountId } = props;

    let { hideApprovedSimulationRows } = this.state;

    let accountEntries = this.getAccountEntries(accountId);
    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;
    if (selectedEntries.size > 0) {
      //Show both posted entries and selected draft entries.
      accountEntries = accountEntries.filter(
        (e) => e.status === "Posted" || selectedEntries.has(e.id)
      );
    }

    let formattedEntries = this.getFormattedAccountEntries(
      accountId,
      accountEntries
    );

    let account = financeAccountMap[accountId];

    var total = formattedEntries.reduce((acc, entry) => {
      return acc + (entry.isVat ? entry.vat : entry.amount);
    }, 0);

    var totalVat = formattedEntries.reduce((acc, entry) => {
      return acc + (entry.isVat ? 0 : entry.vat);
    }, 0);

    let accountPlan = this.getFinanceAccountPlan();
    let standardAccounts = accountPlan.accounts.filter(
      (a) => a.type === "Standard"
    );

    let selectOptions = standardAccounts.map((f) => {
      return { value: f.id, label: f.number.toString() + " - " + f.name };
    });

    return (
      <div className="standardMaxWidth">
        <Flexbox responsive>
          <h4 className="rightPadding">
            <AxoLocal
              entity="axoidcode220"
              defaultValue={" Kontohistorik for"}
            />
            &nbsp;{account.number} - {account.name}
          </h4>
          <div className="rightPadding">
            <LexButton
              onClick={() => this.setState({ showAccountHistoryId: 0 })}
            >
              <AxoLocal entity="backButton" defaultValue={"Tilbage"} />
            </LexButton>
          </div>
          <FlexElement className="rightPadding">
            <CSVLink
              data={this.state.csv}
              separator={";"}
              filename={"AccountHistory.csv"}
              asyncOnClick={true}
              onClick={(event, done) => {
                this.setState(
                  { csv: this.getAccountHistoryCSV(accountId) },
                  () => done()
                );
              }}
            >
              <LexButton>
                <AxoLocal entity="axoAccounting4" defaultValue={"Eksporter"} />{" "}
              </LexButton>
            </CSVLink>
          </FlexElement>
          <div className="flexbox-center">
            <AxoCheckbox
              onChange={(event) =>
                this.setState({
                  hideApprovedSimulationRows: event.target.checked,
                })
              }
              checked={hideApprovedSimulationRows}
            />
            <AxoLocal entity="axoidcode20" defaultValue={"Skjul afstemte"} />
          </div>
        </Flexbox>
        <BootstrapTable bordered condensed hover>
          <thead>
            <tr>
              <th>
                <AxoLocal
                  entity="TimeEntryFormntimeEntry"
                  defaultValue={"Dato"}
                />
              </th>
              <th>
                <AxoLocal entity="axoidcode64" defaultValue={"Nummer"} />{" "}
              </th>
              <th>
                <AxoLocal entity="axoidcode77" defaultValue={"Note"} />
              </th>
              <th>
                <AxoLocal
                  entity="AccountingTabViewEntity13"
                  defaultValue={"Konto"}
                />
              </th>
              <th>
                <AxoLocal entity="InvoiceInvoicesum" defaultValue={"Beløb"} />
                &nbsp;
                <AxoLocal entity="paymentDate10a" defaultValue={"u. moms"} />
              </th>
              <th>
                <AxoLocal
                  entity="invoiPaymentattheSubtotalVAT"
                  defaultValue={"Moms"}
                />
              </th>
              <th className="hidden-print">
                <AxoLocal
                  entity="CaseDataTableFixedlabelAddedToCasedocuments"
                  defaultValue={"Bilag"}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {formattedEntries.map((entry, index) => {
              let isVat = entry.taxAccountId === accountId;
              let formattedAmount = NumberService.formatDecimal(entry.amount);
              let formattedVat = NumberService.formatDecimal(entry.vat);
              let editable = entry.status !== "Posted";
              let rawEntry = accountEntries[index];

              return (
                <tr
                  key={entry.id}
                  style={!editable ? { background: "lightgrey" } : null}
                >
                  <td>{moment.utc(entry.creationDate).format("L")}</td>
                  <td>
                    {entry.status === "Posted" ? (
                      <div>#{entry.receiptNumber}</div>
                    ) : (
                      <InlineNumberEdit
                        editStyle={{ width: "50px" }}
                        value={entry.receiptNumber || "---"}
                        format={(value) => <span>#{value}</span>}
                        change={(input) =>
                          this.onChangePropertyValue(
                            entry.id,
                            "receiptNumber",
                            parseInt(input.value, 10)
                          )
                        }
                      />
                    )}
                  </td>
                  <td>
                    {entry.status === "Posted" ? (
                      <div>{entry.description}</div>
                    ) : (
                      <InlineEdit
                        editStyle={{}}
                        value={entry.description || "---"}
                        change={(input) =>
                          this.onChangeDescription(entry.id, input.value)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {(() => {
                      if (!editable || entry.isVat) {
                        return (
                          <span>
                            {account.number} - {account.name}
                          </span>
                        );
                      }
                      return (
                        <ReactSelect
                          name="select"
                          menuPortalTarget={document.body}
                          menuPlacement="auto"
                          closeMenuOnScroll
                          // menuPlacement='top'
                          value={
                            account.id
                              ? selectOptions.find(
                                  (o) => o.value === account.id
                                )
                              : {
                                  value: "",
                                  label: getText(
                                    "axoAccounting6f",
                                    "Vælg konto"
                                  ),
                                }
                          }
                          onChange={async (selectedAccount) => {
                            if (!!selectedAccount) {
                              if (entry.isBalanceEntry) {
                                await this.onChangePropertyValue(
                                  entry.id,
                                  "balanceFinanceAccountId",
                                  selectedAccount.value
                                );
                              } else {
                                await this.updateMainAccountAndVat(
                                  rawEntry,
                                  selectedAccount.value
                                );
                              }
                              DataStore.fetchDraftSimulation([
                                ...selectedEntries,
                              ]);
                            }
                          }}
                          noOptionsMessage={() => ""}
                          options={[
                            {
                              value: "",
                              label: getText("axoAccounting6f", "Vælg konto"),
                            },
                            ...selectOptions,
                          ]}
                        />
                      );
                    })()}
                  </td>
                  <td className="text-right">
                    {!!entry.amount ? (
                      <React.Fragment>
                        {editable ? (
                          <InlineNumberEdit
                            editStyle={{ width: "100px" }}
                            value={!isVat ? rawEntry.amount : rawEntry.vat}
                            format={() => {
                              return (
                                <span>
                                  {!isVat ? formattedAmount : formattedVat}
                                </span>
                              );
                            }}
                            change={(input) =>
                              this.updateAmountAndVat(
                                entry.id,
                                parseFloat(input.value)
                              )
                            }
                          />
                        ) : (
                          <span>{!isVat ? formattedAmount : formattedVat}</span>
                        )}
                      </React.Fragment>
                    ) : null}
                  </td>
                  <td className="text-right">
                    {!!entry.vat ? (
                      <React.Fragment>
                        {!isVat ? formattedVat : ""}
                      </React.Fragment>
                    ) : null}
                  </td>
                  <td className="hidden-print">
                    {entry.receipt ? (
                      <span>
                        <Icon
                          className="editable"
                          role="button"
                          onClick={() => this.onShowReceipt(entry.receipt)}
                          glyph="icon-fontello-attach-7"
                        />
                      </span>
                    ) : null}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>
                  <AxoLocal
                    entity="InvoiceInvoicetotal"
                    defaultValue={"Total"}
                  />{" "}
                </b>
              </td>
              <td className="text-right">
                {!isNaN(total) ? NumberService.formatDecimal(total) : ""}
              </td>
              <td className="text-right">
                {!isNaN(totalVat) && !!totalVat
                  ? NumberService.formatDecimal(totalVat)
                  : ""}
              </td>
              <td></td>
            </tr>
          </tbody>
        </BootstrapTable>
      </div>
    );
  };

  onShowAccountHistory = async (event, accountId, filter) => {
    event.preventDefault();
    this.setState({
      showAccountHistoryId: accountId,
      accountHistoryFilter: filter,
    });

    if (filter !== HistoryFilter.DRAFT) {
      let result = this.props.fetchPostedAccountEntries(accountId);
      if (!result) {
        console.log("Failed to fetch posted entries.");
      }
    }
  };

  SimulationRow = (props) => {
    let { account, accountResults } = props;

    return (
      <tr key={account.id}>
        <td>{<span>{account.number}</span>}</td>
        <td>
          {<span>{account.number.toString() + " - " + account.name}</span>}
        </td>
        <td className="text-right">
          <a
            href={account.name}
            onClick={(event) =>
              this.onShowAccountHistory(event, account.id, HistoryFilter.POSTED)
            }
          >
            {this.formatAmount(accountResults[account.id].before)}
          </a>
        </td>
        <td className="text-right">
          <a
            href={account.name}
            onClick={(event) =>
              this.onShowAccountHistory(event, account.id, HistoryFilter.DRAFT)
            }
          >
            {this.formatAmount(accountResults[account.id].change)}
          </a>
        </td>
        <td className="text-right">
          <a
            href={account.name}
            onClick={(event) =>
              this.onShowAccountHistory(event, account.id, HistoryFilter.ALL)
            }
          >
            {this.formatAmount(accountResults[account.id].after)}
          </a>
        </td>
      </tr>
    );
  };

  onAddImagePage = async (entryId) => {
    this.selectedEntryId = entryId;
    this.addPagesControl.current.click();
  };

  onPageSelected = async (event) => {
    let control = event.target;
    try {
      let entry = this.props.entries.find((e) => e.id === this.selectedEntryId);
      if (!entry) {
        return;
      }

      let files = control.files;
      if (files.length === 0) {
        return;
      }

      let file = files[0];
      if (file.size / Math.pow(1024, 2) > 50) {
        this.showWarning("showFileMaxSizeWarning");
        return;
      }

      let { selectedContact } = this.props;

      this.setState({ addingPageToId: this.selectedEntryId });

      let uploadResponse = await DataActions.addImagesToExisting(files, {
        fileId: entry.receiptId,
        clientId: selectedContact.id,
      });
      this.setState({ addingPageToId: 0 });
      if (!uploadResponse.ok) {
        return this.displayResponseWarnings(uploadResponse);
      }
      this.setState({ addedPageToId: this.selectedEntryId });
      setTimeout(() => this.setState({ addedPageToId: 0 }), 3000);

      return true;
    } finally {
      this.selectedEntryId = null;
      control.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onDeleteReceiptForEntry = (entry) => {
    ModalService.openConfirmModal(
      <>
        <AxoLocal
          entity="axoEntityidcode26"
          defaultValue={"Vil du slette bilagsfilen permanent?"}
        />
      </>,
      async (value) => {
        if (!value) {
          return;
        }
        let receiptId = entry.receiptId;
        let response = await DataActions.updateBookkeepingDraftEntry({
          ...entry,
          receiptId: null,
          receipt: null,
        });
        if (!response.ok) {
          return;
        }
        DataActions.deleteDocument(receiptId);
      }
    );
  };

  onSplitFiles = async (entriesWithPDFS) => {
    let promises = [];
    entriesWithPDFS.forEach(async (entry) => {
      promises.push(this.onSplitEntry(entry));
    });

    let responses = await Promise.all(promises);

    this.setState({ selectedEntries: new Set() });

    DataStore.fetchBookkeepingDraftEntries();

    if (responses.find((response) => response.status === 415)) {
      ModalService.openAlertModal(
        "Én eller flere PDF filer er ugyldige eller korrumperede."
      );
    }

    return !responses.find((response) => !response.ok);
  };

  onSplitEntry = async (entry) => {
    let response = await ApiService.splitDocument(entry.receipt.id);
    if (!response.ok) {
      return response;
    }
    let newFiles = await response.json();
    for (var i = 0; i < newFiles.length; i++) {
      //Process entries sequentially
      let file = newFiles[i];
      let { id, receiptNumber, ...newEntry } = entry;
      if (i === 0) {
        newEntry.receiptNumber = entry.receiptNumber;
      }

      let entryResponse = await ApiService.createBookkeepingDraftEntry({
        ...newEntry,
        receiptId: file.id,
        receipt: file,
      });

      if (!entryResponse.ok) {
        return entryResponse;
      }
    }

    let final = await ApiService.deleteBookkeepingDraftEntryAndReceipt(
      entry.id
    );
    return final;
  };

  onMergeEntries = async (entriesWithPDFs) => {
    if (!entriesWithPDFs || entriesWithPDFs.length < 2) {
      return;
    }

    let response = await ApiService.mergeDocuments(
      entriesWithPDFs.map((e) => e.receiptId)
    );
    if (!response.ok) {
      if (response.status === 415) {
        ModalService.openAlertModal(
          "Én eller flere PDF filer er ugyldige eller korrumperede."
        );
      }
      return false;
    }

    let newFile = await response.json();
    let firstOriginalEntry = entriesWithPDFs[0];
    let { id, receiptNumber, ...newEntry } = firstOriginalEntry;
    let entryResponse = await ApiService.createBookkeepingDraftEntry({
      ...newEntry,
      receiptNumber: Math.min(...entriesWithPDFs.map((e) => e.receiptNumber)),
      receiptId: newFile.id,
      receipt: newFile,
    });

    if (!entryResponse.ok) {
      return false;
    }

    for (let entry of entriesWithPDFs) {
      //Process entries sequentially
      await ApiService.deleteBookkeepingDraftEntryAndReceipt(entry.id);
    }

    this.setState({ selectedEntries: new Set() });

    DataStore.fetchBookkeepingDraftEntries();

    return true;
  };

  isReadOnly = () => {
    let { sharedClient, selectedContact, userProfile } = this.props;

    return (
      sharedClient &&
      (!selectedContact.editor || selectedContact.editor.id !== userProfile.id)
    );
  };

  // onEmptyTrashCan = async () => {
  //   let { entries } = this.props;

  //   //Extra filtering as a security prevaution
  //   let promises= entries
  //     .filter(e => e.trashed)
  //     .map(e => ApiService.deleteBookkeepingDraftEntryAndReceipt(e.id));

  //   let responses = await Promise.all(promises);

  //   DataStore.fetchBookkeepingDraftEntries();

  //   return !responses.find(r => !r.ok);
  // }

  // onRestoreTrashCan = async () => {
  //   let { entries } = this.props;

  //   //Extra filtering as a security prevaution
  //   let promises= entries
  //     .filter(e => e.trashed)
  //     .map(e => ApiService.updateBookkeepingDraftEntry({ ...e, trashed: false }));

  //   let responses = await Promise.all(promises);

  //   DataStore.fetchBookkeepingDraftEntries();

  //   return !responses.find(r => !r.ok);
  // }

  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  onDownloadAll = async () => {
    let store = DataStore.getStore();

    let all = store.bookKeepingDraftEntries
      .concat(store.postingEntries)
      .filter((e) => !!e.receiptId);

    let link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/documents/download";
    for (let i = 0; i < all.length; i++) {
      window.open(link + "?id=" + all[i].receiptId);
      await this.timeout(500);
    }
  };

  getColumnTitle = (columnName) => {
    switch (columnName) {
      case "rowNumber":
        return getText("axoEntityidcode144", "Rækkenummer");
      default:
        return "";
    }
  };

  getCSVData = () => {
    let { financeAccountMap } = this.props;
    let {
      sortedDataList,
      // columnDefs
    } = this.state;

    if (sortedDataList.getSize() === 0) {
      return;
    }

    let headerEntry = [
      "Type",
      "Dato",
      "Nummer",
      "Fakt #",
      "Debet/Kredit",
      "Konto",
      "Tekst",
      "Beløb inkl. moms",
      "Moms",
      "Modkonto",
    ];

    // let columnKeys = [];

    //"Type;Dato;Nummer;Debet/Kredit;Konto;Tekst;Beløb inkl. moms;Moms;Modkonto;"

    // columnKeys.forEach(key => {
    //   headerEntry.push(this.getColumnTitle(key));
    // });
    let defaultAccount = { number: 0 };
    let rows = [];
    for (let i = 0; i < sortedDataList.getSize(); i++) {
      let entry = sortedDataList.getObjectAt(i);

      // let newRow = columnKeys.map(key => {
      //   if(key === 'creationDate') {
      //     return moment.utc(entry[key]).format('L');
      //   }

      //   return entry[key];
      // });

      let account = financeAccountMap[entry.financeAccountId] || defaultAccount;
      let balanceAccount =
        financeAccountMap[entry.balanceFinanceAccountId] || defaultAccount;

      rows.push([
        entry.entryType === 1 ? "Moms" : "Finans",
        moment.utc(entry.creationDate).format("DD/MM/yyyy"),
        entry.receiptNumber.toString(),
        entry.invoiceNumber,
        entry.isIncome ? "C" : "D",
        account.number.toString(),
        entry.description,
        NumberService.formatDecimal(entry.amount),
        NumberService.formatDecimal(entry.vat),
        balanceAccount.number,
      ]);
    }

    return [headerEntry, ...rows];
  };

  render() {
    let {
      entries,
      forceShowCards,
      contactMap,
      handleSelectedClient,
      fiscalYears,
      selectedContact,
      onCSVChange,
      readingCSV,
      showCSVError,
      showCSVSuccess,
      draftSimulationResult,
      scanReceipts,
      splitPDF,
      convertToPDF,
      autoAccounts,
      calculateNumbers,
      selectedStartDate,
      selectedEndDate,
      onSelectFiscalYear,
      onSelectStartDate,
      onSelectEndDate,
      selectedFiscalYear,
      onlyTable,
      importantEntryIds,
      showOnlyImportant,
      onCheckShowOnlyImportant,
      fixedClient,
      userProfile,
      sharedClient,
      onToggleType,
      viewType,
    } = this.props;

    let {
      sortedDataList,
      uploadingIncome,
      scanningIncome,
      uploadingExpense,
      scanningExpense,
      confirmPosting,
      showInvalidDatesWarning,
      showLockedFiscalYearsWarning,
      showImbalanceWarning,
      vatErrorEntries,
      multiPageUpload,
      cachedPageFiles,
      showAccountHistoryId,
      showMissingCurrentFiscalYearError,
      displayedEntryId,
      documentsToBeUploaded,
      documentsUploaded,
      confirmDeleteEntryId,
      deletingId,
      confirmDeleteSelected,
      // entryFilterTexts,
      // selectedEntryColumn,
      finalizationStartDate,
      finalizationEndDate,
      confirmBankBalancing,
    } = this.state;

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;

    let selectedEntryWithReceipt = entries.find(
      (e) => !!e.receipt && displayedEntryId === e.id
    );

    // let invalidEntry = entries.find( entry => !entry.financeAccountId || entry.amount <= 0 );
    let invalidEntry = entries.find((entry) => !entry.financeAccountId);
    let hasMissingAccount = !!invalidEntry && !invalidEntry.financeAccountId;
    // let hasMissingAmount = !!invalidEntry && invalidEntry.amount <= 0;

    let showSimulationResult =
      !!draftSimulationResult &&
      Object.keys(draftSimulationResult.accountResults).length > 0;

    let SimulationResultComponent = this.SimulationResult;
    if (onlyTable) {
      return (
        <>
          {showSimulationResult ? (
            <SimulationResultComponent
              {...{
                draftSimulationResult,
                showAccountHistoryId,
              }}
            />
          ) : (
            <React.Fragment>
              {!!selectedEntryWithReceipt ? (
                <Flexbox>
                  <FlexElement className="rightPadding">
                    {/* {this.renderTable({ allowSpaceForPicture: true, hasMissingAccount, hasMissingAmount })} */}
                    {this.renderTable({
                      allowSpaceForPicture: true,
                      hasMissingAccount,
                    })}
                  </FlexElement>
                  <FlexElement flexGrow={1}>
                    <FileViewer file={selectedEntryWithReceipt.receipt} />
                  </FlexElement>
                </Flexbox>
              ) : (
                this.renderTable()
              )}
            </React.Fragment>
          )}
          <FileViewerModal ref={(c) => (this.fileModal = c)} />
          <TextFilterModal ref={this.textFilterModal} />
          <NumberFilterModal ref={this.numberFilterModal} />
          <DateFilterModal ref={this.dateFilterModal} />
          <input
            name="receipt"
            onChange={this.onReceiptSelected}
            type="file"
            accept="image/*,.pdf"
            id="receiptUpload"
            style={{ display: "none" }}
          />
        </>
      );
    }

    let isTrashCan = viewType === ViewType.TRASH;
    let isIncoming = viewType === ViewType.SCAN;
    let isNormal = viewType === ViewType.NORMAL;

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    let selectedContactString =
      ContactService.getContactFullName(selectedContact);

    let showImportantFilter =
      (!!importantEntryIds && importantEntryIds.size) > 0 &&
      entries.find((e) => importantEntryIds.has(e.id));

    // let searchText = entryFilterTexts[selectedEntryColumn] || '';

    let readonly = this.isReadOnly();

    let entriesWithPDFS = entries.filter(
      (e) =>
        selectedEntries.has(e.id) &&
        e.receipt &&
        e.receipt.fileType.includes("pdf")
    );

    let SimulationResult = this.SimulationResult;
    return (
      <AxoDropzone
        onDrop={this.handleFileDrop}
        className="leftPadding rightPadding"
      >
        <Flexbox
          responsive
          collapseXS
          style={{
            maxwidth: "990px",
            margin: "auto",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          {!fixedClient && (
            <FlexElement className="onToppest rightPadding">
              <AxoLocal
                componentClass={ClientSearchBox}
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                // clearOnSelect
                disabled={false}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          )}
          {selectedContact.id && !!fiscalYears ? (
            <React.Fragment>
              <FlexElement flexBasis="150px" className="onTop rightPadding">
                <AxoSelect
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: getText("axoidcode100", "Vælg regnskabsår"),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <div style={{ maxWidth: "125px" }}>
                  <AxoDateTime
                    utc
                    value={startDateSelection}
                    dateFormat={true}
                    timeFormat={false}
                    onChange={onSelectStartDate}
                    isValidDate={(currentDate) =>
                      !currentDate.isAfter(endDateSelection)
                    }
                  />
                </div>
              </FlexElement>
              <FlexElement className="rightPadding">
                <div style={{ maxWidth: "125px" }}>
                  <AxoDateTime
                    utc
                    value={endDateSelection}
                    dateFormat={true}
                    timeFormat={false}
                    onChange={onSelectEndDate}
                    isValidDate={(currentDate) =>
                      !currentDate.isBefore(startDateSelection)
                    }
                  />
                </div>
              </FlexElement>
              {/* <FlexElement className='rightPadding'>
                <div style={{ minWidth: '200px', maxWidth: '600px' }}>
                  <FormGroup controlId='inputWithIcon'>
                    <InputGroup>
                      <AxoLocal
                        componentClass={FormControl}
                        disabled={!selectedEntryColumn}
                        type='text'
                        value={searchText}
                        onChange={event => {
                          this.updateEntryFilterText(event.target.value)
                        }}
                        componentAttribute="placeholder"
                        entity='ContactTableViewplaceholderonSearch'
                      />
                      <FormControl.Feedback>
                        <Icon glyph='icon-fontello-search' />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
              </FlexElement> */}
              <FlexElement>
                <div style={{ minWidth: "200px", maxWidth: "600px" }}>
                  <FormGroup controlId="inputWithIcon">
                    <InputGroup>
                      {/* Søg...  */}
                      <AxoLocal
                        componentClass={FormControl}
                        type="text"
                        value={this.state.searchText}
                        onChange={this.onSearch}
                        componentAttribute="placeholder"
                        entity="ContactTableViewplaceholderonSearch"
                      />
                      <FormControl.Feedback>
                        <Icon glyph="icon-fontello-search" />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
              </FlexElement>
              {/* { isIncoming && (
                <FlexElement className='rightPadding'>
                  <LexButton 
                    disabled={!selectedContactString || sortedDataList._data.length === 0} 
                    href={ApiService.getEntryExportPath(selectedContact.id, isIncoming)}>
                    <AxoLocal entity='axoAccounting4' defaultValue={'Eksporter'}/>  
                  </LexButton>
                </FlexElement>
              )} */}
              {isIncoming &&
                !!selectedContactString &&
                sortedDataList._data.length > 0 && (
                  <FlexElement className="leftPadding rightPadding">
                    <CSVLink
                      data={this.state.csv}
                      separator={";"}
                      filename={"Bookkeeping.csv"}
                      asyncOnClick={true}
                      onClick={(event, done) => {
                        this.setState({ csv: this.getCSVData() }, () => done());
                      }}
                    >
                      <LexButton>
                        <AxoLocal
                          entity="axoAccounting4"
                          defaultValue={"Eksporter"}
                        />
                      </LexButton>
                    </CSVLink>
                  </FlexElement>
                )}
              {/* <FlexElement>
                <LexButton 
                  style={{ fontSize: '25px' }}
                  disabled={confirmBankBalancing}
                  onClick={() => this.setState({ confirmBankBalancing: true })}
                  >
                  <AxoLocal entity='axoidcode13'defaultValue={'Bankafstemning'}/>
                </LexButton>
              </FlexElement> */}
              {!readonly && !isIncoming && (
                <FlexElement
                  flexGrow={1}
                  className="text-right buttonDropdown"
                  style={{ paddingRight: "75px" }}
                >
                  <DropdownButton
                    id="menuDropdown"
                    title={
                      <div style={{ display: "inline-block" }}>
                        <div className="flexbox-center">
                          <Icon
                            style={{ fontSize: "25px" }}
                            glyph="icon-fontello-align-justify"
                          />
                          &nbsp;&nbsp;&nbsp;
                          <AxoLocal
                            style={{ fontSize: "20px" }}
                            entity="Enhedspris33"
                            defaultValue={"Menu"}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div>
                      <LexButton
                        style={{ minWidth: "150px" }}
                        disabled={this.disableAddition()}
                        onClick={this.onUploadIncome}
                      >
                        {this.getButtonLabel(
                          <AxoLocal
                            entity="updategetAccountName6"
                            defaultValue={"Upload indtægt"}
                          />,
                          uploadingIncome,
                          scanningIncome
                        )}
                        <LoadingIcon show={uploadingIncome || scanningIncome} />
                      </LexButton>
                    </div>
                    <div>
                      <LexButton
                        style={{ minWidth: "150px" }}
                        disabled={this.disableAddition()}
                        onClick={this.onUploadExpense}
                      >
                        {this.getButtonLabel(
                          <AxoLocal
                            entity="updategetAccountName7"
                            defaultValue={"Upload udgift"}
                          />,
                          uploadingExpense,
                          scanningExpense
                        )}
                        <LoadingIcon
                          show={uploadingExpense || scanningExpense}
                        />
                      </LexButton>
                    </div>
                    <div>
                      <AsyncButton
                        style={{ minWidth: "150px" }}
                        disabled={this.disableAddition()}
                        onClick={this.updateReceiptNumbers}
                      >
                        <AxoLocal
                          entity="axoidcode3"
                          defaultValue={"Genberegn numre"}
                        />
                      </AsyncButton>
                    </div>
                    {/* <div>
                      <LexButton 
                        disabled={!selectedContactString || sortedDataList._data.length === 0} 
                        href={ApiService.getEntryExportPath(selectedContact.id)}>
                        <AxoLocal entity='axoAccounting4' defaultValue={'Eksporter'}/>  
                      </LexButton>
                    </div> */}
                    {!!selectedContactString &&
                      sortedDataList._data.length > 0 && (
                        <div>
                          <CSVLink
                            data={this.state.csv}
                            separator={";"}
                            filename={"Bookkeeping.csv"}
                            asyncOnClick={true}
                            onClick={(event, done) => {
                              this.setState({ csv: this.getCSVData() }, () =>
                                done()
                              );
                            }}
                          >
                            <LexButton>
                              <AxoLocal
                                entity="axoAccounting4"
                                defaultValue={"Eksporter"}
                              />
                            </LexButton>
                          </CSVLink>
                        </div>
                      )}

                    <div>
                      <LexButton
                        disabled={
                          !selectedContactString || readingCSV || showCSVError
                        }
                        onClick={this.onUploadCSV}
                      >
                        {(function () {
                          if (showCSVSuccess) {
                            return (
                              <span className="axoblue">
                                <AxoLocal
                                  entity="paymentDate5a"
                                  defaultValue={"Filen blev indlæst"}
                                />
                                <Icon glyph="icon-fontello-ok-3" />
                              </span>
                            );
                          }
                          if (showCSVError) {
                            return (
                              <span>
                                <AxoLocal
                                  entity="paymentDate6a"
                                  defaultValue={"Filen kunne ikke indlæses"}
                                />
                              </span>
                            );
                          }
                          return (
                            <span>
                              <AxoLocal
                                entity="paymentDate7a"
                                defaultValue={"Importer CSV"}
                              />
                              {readingCSV ? (
                                <span>
                                  &nbsp;
                                  <img
                                    alt=""
                                    src="/imgs/app/loading.gif"
                                    className="img-circle"
                                    width="20"
                                    height="20"
                                  />
                                </span>
                              ) : null}
                            </span>
                          );
                        })()}
                      </LexButton>
                    </div>
                    <div>
                      <AxoCheckbox
                        onChange={(event) =>
                          DataStore.setScanReceipts(event.target.checked)
                        }
                        checked={scanReceipts}
                      />
                      <AxoLocal
                        entity="axoidcode4"
                        defaultValue={"Scan bilag"}
                      />
                    </div>
                    <div>
                      <AxoCheckbox
                        onChange={(event) =>
                          DataStore.setSplitPDF(event.target.checked)
                        }
                        checked={splitPDF}
                      />
                      <AxoLocal
                        entity="axoEntityidcode29"
                        defaultValue={" Split PDF filer"}
                      />
                    </div>
                    <div>
                      <AxoCheckbox
                        onChange={(event) =>
                          DataStore.setConvertToPDF(event.target.checked)
                        }
                        checked={convertToPDF}
                      />
                      {/* MissingEntity */}
                      Konverter til PDF
                    </div>
                    <div>
                      <AxoCheckbox
                        onChange={(event) =>
                          this.setState({
                            multiPageUpload: event.target.checked,
                          })
                        }
                        checked={multiPageUpload}
                      />
                      <AxoLocal
                        entity="axoidcode137"
                        defaultValue={"Flere sider"}
                      />
                    </div>
                    <div>
                      <AxoCheckbox
                        onChange={(event) =>
                          DataStore.setAutoAccounts(event.target.checked)
                        }
                        checked={autoAccounts}
                      />
                      <AxoLocal
                        entity="axoidcode181"
                        defaultValue={"Auto konto"}
                      />
                    </div>
                    <div>
                      <AxoCheckbox
                        onChange={(event) =>
                          DataStore.setcalculateNumbers(event.target.checked)
                        }
                        checked={calculateNumbers}
                      />
                      <AxoLocal
                        entity="axoEntityidcode30"
                        defaultValue={"Auto genberegn bilagsnumre"}
                      />
                    </div>
                  </DropdownButton>
                </FlexElement>
              )}
            </React.Fragment>
          ) : null}
        </Flexbox>
        {confirmPosting && (
          <>
            <Flexbox collapseSM className="bottomPadding" responsive>
              <FlexElement className="rightPadding largeText">
                <AxoLocal
                  entity="axoidcode95"
                  defaultValue={
                    "Dette vil bogføre godkendte posteringer endeligt."
                  }
                />
              </FlexElement>
            </Flexbox>
            {!calculateNumbers && (
              <Flexbox className="bottomPadding">
                <AsyncButton
                  style={{ minWidth: "150px" }}
                  disabled={this.disableAddition()}
                  onClick={this.updateReceiptNumbers}
                >
                  <AxoLocal
                    entity="axoEntityidcode31"
                    defaultValue={"Genberegn bilagsnumre"}
                  />
                </AsyncButton>
              </Flexbox>
            )}
            <Flexbox className="bottomPadding">
              {(!showOnlyImportant || importantEntryIds.size === 0) && (
                <>
                  <FlexElement className="rightPadding">
                    <div style={{ maxWidth: "125px" }}>
                      <AxoDateTime
                        utc
                        value={finalizationStartDate}
                        dateFormat={true}
                        timeFormat={false}
                        onChange={(selection) =>
                          this.setState({ finalizationStartDate: selection })
                        }
                        isValidDate={(currentDate) =>
                          !currentDate.isAfter(finalizationEndDate)
                        }
                      />
                    </div>
                  </FlexElement>
                  <FlexElement className="rightPadding">
                    <div style={{ maxWidth: "125px" }}>
                      <AxoDateTime
                        utc
                        value={finalizationEndDate}
                        dateFormat={true}
                        timeFormat={false}
                        onChange={(selection) =>
                          this.setState({ finalizationEndDate: selection })
                        }
                        isValidDate={(currentDate) =>
                          !currentDate.isBefore(finalizationStartDate)
                        }
                      />
                    </div>
                  </FlexElement>
                </>
              )}

              <FlexElement className="rightPadding">
                <ButtonToolbar>
                  <AsyncButton onClick={this.doFinalizeDrafts}>
                    <AxoLocal
                      entity="SidebarRightContainerConfirmPassword"
                      defaultValue={"Bekræft"}
                    />
                  </AsyncButton>
                  <LexButton
                    onClick={() => this.setState({ confirmPosting: false })}
                  >
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </LexButton>
                </ButtonToolbar>
              </FlexElement>
            </Flexbox>
          </>
        )}
        {confirmBankBalancing && (
          <Flexbox className="bottomPadding" responsive>
            <FlexElement className="rightPadding largeText">
              <AxoLocal
                entity="axoEntityidcode32"
                defaultValue={"Vælg periode for bankafstemning"}
              />
            </FlexElement>
            <FlexElement className="rightPadding">
              <div style={{ maxWidth: "125px" }}>
                <AxoDateTime
                  utc
                  value={startDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectStartDate}
                  isValidDate={(currentDate) =>
                    !currentDate.isAfter(endDateSelection)
                  }
                />
              </div>
            </FlexElement>
            <FlexElement className="rightPadding">
              <div style={{ maxWidth: "125px" }}>
                <AxoDateTime
                  utc
                  value={endDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectEndDate}
                  isValidDate={(currentDate) =>
                    !currentDate.isBefore(startDateSelection)
                  }
                />
              </div>
            </FlexElement>
            <FlexElement className="rightPadding">
              <ButtonToolbar>
                <Link to="Bank">
                  <LexButton>
                    <AxoLocal
                      entity="CalendarEventBoxFormStart"
                      defaultValue={"Start"}
                    />
                  </LexButton>
                </Link>
                <LexButton
                  onClick={() => this.setState({ confirmBankBalancing: false })}
                >
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </LexButton>
              </ButtonToolbar>
            </FlexElement>
          </Flexbox>
        )}
        {/* { !showSimulationResult ? (
          <Flexbox collapseXS responsive alignCenter style={{ marginBottom: '10px' }}>
            <FlexElement className='rightPadding'>
              <LexButton style={{ minWidth: '150px'}} disabled={this.disableAddition()} onClick={this.onUploadIncome}>
                { this.getButtonLabel(<AxoLocal entity='updategetAccountName6'defaultValue={'Upload indtægt'}/>, uploadingIncome, scanningIncome) }
                <LoadingIcon show={ uploadingIncome || scanningIncome } />
              </LexButton>
            </FlexElement>
            <FlexElement className='rightPadding'>
              <LexButton style={{ minWidth: '150px'}} disabled={this.disableAddition()} onClick={this.onUploadExpense}>
                { this.getButtonLabel(<AxoLocal entity='updategetAccountName7'defaultValue={'Upload udgift'}/>, uploadingExpense, scanningExpense) }
                <LoadingIcon show={ uploadingExpense || scanningExpense } />
              </LexButton>
            </FlexElement>
            <FlexElement className='rightPadding'>
              <AsyncButton style={{ minWidth: '150px'}} disabled={this.disableAddition()} onClick={this.updateReceiptNumbers}>
              <AxoLocal entity='axoidcode3'defaultValue={'Genberegn numre'}/>   
              </AsyncButton>
            </FlexElement>
            <FlexElement className='rightPadding'>
              <AxoCheckbox
                onChange={event => DataStore.setScanReceipts(event.target.checked)} 
                checked={scanReceipts}
              />
              <AxoLocal entity='axoidcode4'defaultValue={'Scan bilag'}/>
            </FlexElement>
            <FlexElement className='rightPadding'>
              <AxoCheckbox
                onChange={event => this.setState({ multiPageUpload: event.target.checked })} 
                checked={multiPageUpload}
              />
              <AxoLocal entity='axoidcode137'defaultValue={'Flere sider'}/>
            </FlexElement>
            <FlexElement className='rightPadding'>
              <AxoCheckbox
                onChange={event => DataStore.setAutoAccounts(event.target.checked)} 
                checked={autoAccounts}
              />
              Auto konto
            </FlexElement>
          </Flexbox>
        ) : null } */}
        <div className="text-center">{this.getFileErrorMessage()}</div>
        <div>
          {multiPageUpload ? (
            <div className="leftPadding">
              <span style={{ fontSize: "18px" }}>
                {cachedPageFiles.length}
                &nbsp;
                <AxoLocal
                  entity="axoidcode135"
                  defaultValue={"sider uploadet."}
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <AsyncButton
                disabled={cachedPageFiles.length === 0}
                onClick={this.finishMultiPageUpload}
                hideOkIcon
              >
                <AxoLocal
                  entity="axoidcode136"
                  defaultValue={"Kombiner sider"}
                />
              </AsyncButton>
            </div>
          ) : null}
          {!!fiscalYears && selectedContact.id && fiscalYears.length === 0 ? (
            <div>
              <span style={{ fontSize: "18px" }}>
                <Link to="Setup">
                  {!fixedClient ? (
                    <>
                      <AxoLocal
                        entity="axoidcode67"
                        defaultValue={"Opret et regnskabsår for"}
                      />
                      &nbsp;{selectedContact.firstName}{" "}
                      {selectedContact.lastName}
                    </>
                  ) : (
                    <>
                      <AxoLocal
                        entity="axoidcode215"
                        defaultValue={"Opret et regnskabsår"}
                      />
                    </>
                  )}
                </Link>
              </span>
            </div>
          ) : null}
          {showInvalidDatesWarning ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode5"
                  defaultValue={
                    "Posteringerne skal ligge indenfor et gyldigt regnskabsår."
                  }
                />
              </span>
            </div>
          ) : null}
          {!!fiscalYears &&
          !!selectedContact.id &&
          showMissingCurrentFiscalYearError ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode67"
                  defaultValue={"Opret et regnskabsår for"}
                />{" "}
                {moment.utc().year()}
              </span>
            </div>
          ) : null}
          {showLockedFiscalYearsWarning ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode6"
                  defaultValue={
                    " Regnskabsåret er låst. Aktiver regnskabsåret for at fortsætte bogføringen."
                  }
                />
              </span>
            </div>
          ) : null}
          {showImbalanceWarning ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode7"
                  defaultValue={"Kredit og Debet er ikke i balance."}
                />
              </span>
            </div>
          ) : null}
          {vatErrorEntries.size > 0 ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode8"
                  defaultValue={
                    "Der er postereret moms på en konto uden momskode. Fjern momsbeløbet eller vælg en konto med moms."
                  }
                />
                &nbsp;
                <LexButton
                  onClick={() => {
                    this.setState({ vatErrorEntries: new Set() });
                    DataStore.setState({
                      importantEntryIds: new Set(), //Filtering
                    });
                  }}
                >
                  <AxoLocal
                    entity="AlertshowModaldialogOk"
                    defaultValue={"OK"}
                  />
                </LexButton>
              </span>
            </div>
          ) : null}
          {showSimulationResult ? (
            <SimulationResult
              {...{
                draftSimulationResult,
                showAccountHistoryId,
              }}
            />
          ) : null}
          {!!fiscalYears &&
          !showSimulationResult &&
          !!selectedContact.id &&
          fiscalYears.length > 0 ? (
            <div>
              <div className="axobg" style={{ marginBottom: "5px" }}>
                <Flexbox spaceBetween alignCenter style={{ flexWrap: "wrap" }}>
                  <FlexElement>
                    <Flexbox responsive alignCenter>
                      {/* <FlexElement className='rightPadding'>
                  <h4>
                    <Icon glyph="icon-fontello-doc-7"/>&nbsp;&nbsp;
                    { selectedContactString || <AxoLocal entity='updategetAccountName12' defaultValue={'Bogføringskladde'}/>}
                    { entries.length > 0 ? <span>({entries.length})</span> : null }
                  </h4>
                </FlexElement> */}
                      {viewType !== ViewType.TRASH && (
                        <FlexElement className="rightPadding">
                          <AsyncButton
                            onClick={() => this.onAddEntry(true)}
                            hideOkIcon
                            disabled={readonly}
                          >
                            <Icon role="button" glyph="icon-fontello-plus-3" />
                          </AsyncButton>
                        </FlexElement>
                      )}

                      {isIncoming && (
                        <FlexElement className="rightPadding">
                          <AsyncButton
                            disabled={this.disableAddition()}
                            onClick={this.updateReceiptNumbers}
                          >
                            <AxoLocal
                              entity="axoidcode3"
                              defaultValue={"Genberegn numre"}
                            />
                          </AsyncButton>
                        </FlexElement>
                      )}
                      <FlexElement className="rightPadding">
                        <AsyncButton
                          disabled={selectedEntries.size === 0 || readonly}
                          onClick={this.onDeleteSelectedEntries}
                        >
                          <AxoLocal
                            entity="updategetAccountName7a"
                            defaultValue={"Slet valgte"}
                          />
                          {isTrashCan && (
                            <>
                              {" "}
                              <AxoLocal
                                entity="sletpermanent1"
                                defaultValue={"permanent"}
                              />{" "}
                            </>
                          )}
                        </AsyncButton>
                      </FlexElement>
                      {isTrashCan && (
                        <>
                          <FlexElement className="rightPadding">
                            <AsyncButton
                              disabled={selectedEntries.size === 0 || readonly}
                              onClick={this.onRestoreSelectedeEntries}
                            >
                              <AxoLocal
                                entity="DocumentTrashTableViewrestore"
                                defaultValue={"Gendan"}
                              />
                            </AsyncButton>
                          </FlexElement>
                        </>
                      )}
                      {!isTrashCan && (
                        <>
                          {isNormal && (
                            <>
                              <FlexElement className="rightPadding">
                                <AsyncButton
                                  onClick={this.onRunSimulation}
                                  disabled={
                                    !selectedContact.id ||
                                    uploadingIncome ||
                                    uploadingExpense ||
                                    scanningIncome ||
                                    entries.length === 0 ||
                                    hasMissingAccount ||
                                    readonly
                                    // || hasMissingAmount
                                  }
                                >
                                  <AxoLocal
                                    entity="axoidcode130"
                                    defaultValue={"Simulering"}
                                  />
                                </AsyncButton>
                              </FlexElement>
                              <FlexElement className="rightPadding">
                                <AsyncButton
                                  disabled={
                                    selectedEntries.size === 0 || readonly
                                  }
                                  onClick={this.onUnApproveSelectedEntries}
                                >
                                  Flyt valgte til ubehandlede
                                </AsyncButton>
                              </FlexElement>
                              <FlexElement className="axoFrontPage rightPadding">
                                <AsyncButton
                                  onClick={this.onFinalizeDrafts}
                                  hideOkIcon
                                  disabled={
                                    !selectedContact.id ||
                                    uploadingIncome ||
                                    uploadingExpense ||
                                    scanningIncome ||
                                    entries.length === 0 ||
                                    hasMissingAccount ||
                                    // || hasMissingAmount
                                    confirmPosting ||
                                    readonly
                                  }
                                >
                                  <AxoLocal
                                    entity="axoidcode94"
                                    defaultValue={"Bogfør kladde"}
                                  />
                                </AsyncButton>
                              </FlexElement>
                              {/* { userProfile.userType === 'Admin' && (
                        <FlexElement className='axoFrontPage rightPadding'>
                          <LexButton onClick={this.onDownloadAll}>
                            Udskriv alle
                          </LexButton>
                        </FlexElement>
                      )} */}
                              {sharedClient &&
                                !!selectedContact.editor &&
                                selectedContact.editor.id !==
                                  userProfile.id && (
                                  <FlexElement className="largeText rightPadding">
                                    <AxoLocal
                                      entity="Casesharing7"
                                      defaultValue={"Tjekket ud af"}
                                    />{" "}
                                    {UserInfoService.getDisplayName(
                                      selectedContact.editor
                                    )}
                                  </FlexElement>
                                )}
                            </>
                          )}

                          {/* { sharedClient && (
                      <>
                        { !!selectedContact.editor ? (
                          <>
                            <FlexElement className='largeText rightPadding'>
                              <AxoLocal entity='Casesharing7' defaultValue={'Tjekket ud af'}/> {UserInfoService.getDisplayName(selectedContact.editor)}
                            </FlexElement>
                            { selectedContact.editor.id === userProfile.id && (
                              <FlexElement className='rightPadding'>
                                <AsyncButton onClick={this.onCheckin}>
                                  Tjek ind
                                </AsyncButton>
                              </FlexElement>
                            )}
                          </>
                        ) : (
                          <FlexElement className='rightPadding'>
                            <AsyncButton onClick={this.onCheckout}>
                              Tjek ud
                            </AsyncButton>
                          </FlexElement>
                        )}
                      </>
                    )} */}
                          {isIncoming && (
                            <>
                              <FlexElement className="rightPadding">
                                <AsyncButton
                                  disabled={entriesWithPDFS.length === 0}
                                  onClick={() =>
                                    this.onSplitFiles(entriesWithPDFS)
                                  }
                                >
                                  <AxoLocal
                                    entity="axoEntityidcode33"
                                    defaultValue={"Split bilag"}
                                  />
                                </AsyncButton>
                              </FlexElement>
                              <FlexElement className="rightPadding">
                                <AsyncButton
                                  disabled={entriesWithPDFS.length < 2}
                                  onClick={() =>
                                    this.onMergeEntries(entriesWithPDFS)
                                  }
                                >
                                  <AxoLocal
                                    entity="axoEntityidcode34"
                                    defaultValue={"Merge bilag"}
                                  />
                                </AsyncButton>
                              </FlexElement>
                              <FlexElement className="rightPadding">
                                <AsyncButton
                                  disabled={
                                    selectedEntries.size === 0 || readonly
                                  }
                                  onClick={this.onApproveSelectedEntries}
                                >
                                  <AxoLocal
                                    entity="axoEntityidcode35"
                                    defaultValue={
                                      "Overfør valgte til bogføringskladden"
                                    }
                                  />
                                </AsyncButton>
                              </FlexElement>
                              <FlexElement className="rightPadding">
                                <AxoCheckbox
                                  onChange={(event) =>
                                    DataStore.setScanReceipts(
                                      event.target.checked
                                    )
                                  }
                                  checked={scanReceipts}
                                />
                                <AxoLocal
                                  entity="axoidcode4"
                                  defaultValue={"Scan bilag"}
                                />
                              </FlexElement>
                              <FlexElement className="rightPadding">
                                <AxoCheckbox
                                  onChange={(event) =>
                                    DataStore.setAutoAccounts(
                                      event.target.checked
                                    )
                                  }
                                  checked={autoAccounts}
                                />
                                <AxoLocal
                                  entity="axoidcode181"
                                  defaultValue={"Auto konto"}
                                />
                              </FlexElement>
                            </>
                          )}
                        </>
                      )}
                      {!!confirmDeleteEntryId ? (
                        <FlexElement>
                          <Flexbox>
                            <FlexElement className="largeText axored rightPadding">
                              {!isTrashCan ? (
                                <>
                                  <AxoLocal
                                    entity="axoEntityidcode36"
                                    defaultValue={
                                      "Flyt posteringen til skraldespanden?"
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <AxoLocal
                                    entity="axoidcode221"
                                    defaultValue={
                                      "Dette vil slette posteringen permanent"
                                    }
                                  />
                                </>
                              )}
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <LexButton
                                disabled={!!deletingId}
                                onClick={this.doDeleteSelectedEntry}
                              >
                                <AxoLocal
                                  entity="friendRequestTimelineonApproveRequest"
                                  defaultValue={"Godkend"}
                                />{" "}
                                <LoadingIcon show={!!deletingId} />
                              </LexButton>
                            </FlexElement>
                            <FlexElement>
                              <LexButton
                                disabled={!!deletingId}
                                onClick={() =>
                                  this.setState({ confirmDeleteEntryId: 0 })
                                }
                              >
                                <AxoLocal
                                  entity="axoAccounting22"
                                  defaultValue={"Fortryd"}
                                />
                              </LexButton>
                            </FlexElement>
                          </Flexbox>
                        </FlexElement>
                      ) : null}
                      {!!confirmDeleteSelected ? (
                        <FlexElement>
                          <Flexbox>
                            <FlexElement className="largeText axored rightPadding">
                              {!isTrashCan ? (
                                <>
                                  <AxoLocal
                                    entity="axoEntityidcode37"
                                    defaultValue={
                                      "Flyt valgte posteringer til skraldespanden?"
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <AxoLocal
                                    entity="axoidcode223"
                                    defaultValue={
                                      "Dette vil slette de valgte posteringer permanent."
                                    }
                                  />
                                </>
                              )}
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <AsyncButton
                                hideOkIcon
                                onClick={this.doDeleteSelectedEntries}
                              >
                                <AxoLocal
                                  entity="friendRequestTimelineonApproveRequest"
                                  defaultValue={"Godkend"}
                                />
                              </AsyncButton>
                            </FlexElement>
                            <FlexElement>
                              <LexButton
                                onClick={() =>
                                  this.setState({
                                    confirmDeleteSelected: false,
                                  })
                                }
                              >
                                <AxoLocal
                                  entity="axoAccounting22"
                                  defaultValue={"Fortryd"}
                                />
                              </LexButton>
                            </FlexElement>
                          </Flexbox>
                        </FlexElement>
                      ) : null}
                      {documentsToBeUploaded > 0 ? (
                        <FlexElement className="mediumText rightPadding">
                          &nbsp;({documentsUploaded + 1} /{" "}
                          {documentsToBeUploaded})
                          <LoadingIcon show={true} />
                        </FlexElement>
                      ) : null}
                      {showImportantFilter ? (
                        <FlexElement>
                          <AxoCheckbox
                            onChange={onCheckShowOnlyImportant}
                            checked={showOnlyImportant}
                          />
                          <AxoLocal
                            entity="axoEntityidcode38"
                            defaultValue={"Vis kun vigtige"}
                          />
                        </FlexElement>
                      ) : null}
                    </Flexbox>
                  </FlexElement>
                  {/* { (documentOptions.length > 0 || !!documentSearchText || fetchingDocuments) && (!uploadingExpense && !uploadingIncome) ? (
              <FlexElement flexBasis='250px'>
                <ReactSelect
                  name="documentSelect"
                  menuPlacement='auto'
                  className={'onTop' + (!!selectedEntryId ? ' selectOutline' : '')}
                  value=''
                  onChange={this.onDocumentSelect}
                  inputValue={documentSearchText}
                  onInputChange={this.onDocumentSearch}
                  placeholder={!!selectedEntryId ?
                  getText('axoidcode41', 'Vælg ubehandlet bilag') : getText('axoidcode42', 'Tilføj ubehandlet bilag')}
                  noOptionsMessage={() => ''}
                  options={[
                    ...documentOptions
                  ]}
                />
              </FlexElement>
            ) : null } */}
                  <FlexElement className="text-right hidden-xs">
                    <ButtonToolbar>
                      <LexButton
                        onClick={() => {
                          this.setState({ selectedEntries: new Set() });
                          onToggleType();
                        }}
                      >
                        {!isTrashCan ? (
                          <>
                            <Icon glyph="icon-fontello-trash-1" />
                            &nbsp;
                            <AxoLocal
                              entity="DocumentTabViewBin"
                              defaultValue={"Skraldespand"}
                            />
                          </>
                        ) : (
                          <>
                            <AxoLocal
                              entity="axoEntityidcode39"
                              defaultValue={"Luk skraldespand"}
                            />
                          </>
                        )}
                      </LexButton>
                      {/* <LexButton onClick={this.props.onToggleCards}>
                  { this.props.forceShowCards ?  <span> <Icon glyph="icon-fontello-grid"/>&nbsp;&nbsp;
                  <AxoLocal key='showTable' entity='CaseDataTableFixedShowastable'defaultValue={'Vis som tabel'}/> </span> 
                        :  <span> <Icon glyph="icon-fontello-th-thumb-empty"/>&nbsp;&nbsp;
                        <AxoLocal key='showCards' entity='CaseDataTableFixedShowCards'defaultValue={'Vis som kartotekskort'}/></span> }
                </LexButton> */}
                    </ButtonToolbar>
                  </FlexElement>
                </Flexbox>
              </div>
              {forceShowCards ? (
                this.renderGridView()
              ) : (
                <div>
                  <ExtraSmall>{this.renderGridView()}</ExtraSmall>
                  <SmallOrLarger>
                    <Flexbox>
                      <FlexElement>
                        {/* {this.renderTable({ allowSpaceForPicture: !!selectedEntryWithReceipt, hasMissingAccount, hasMissingAmount })} */}
                        {this.renderTable({
                          allowSpaceForPicture: !!selectedEntryWithReceipt,
                          hasMissingAccount,
                        })}
                      </FlexElement>
                      {!!selectedEntryWithReceipt ? (
                        <FlexElement flexGrow={1} className="leftPadding">
                          <FileViewer file={selectedEntryWithReceipt.receipt} />
                        </FlexElement>
                      ) : null}
                    </Flexbox>
                  </SmallOrLarger>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <TextFilterModal ref={this.textFilterModal} />
        <NumberFilterModal ref={this.numberFilterModal} />
        <DateFilterModal ref={this.dateFilterModal} />
        <PrintReceiptsModal
          entries={entries
            .filter((e) => !!e.receiptNumber)
            .sort((e1, e2) => e1.receiptNumber - e2.receiptNumber)}
          selectedContact={selectedContact}
          ref={(c) => (this.receiptsModal = c)}
        />
        {multiPageUpload ? (
          <React.Fragment>
            <input
              name="income"
              onChange={this.onIncomeSelected}
              type="file"
              accept="image/*"
              multiple
              id="incomeUpload"
              style={{ display: "none" }}
            />
            <input
              name="expense"
              onChange={this.onExpenseSelected}
              type="file"
              accept="image/*"
              multiple
              id="expenseUpload"
              style={{ display: "none" }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input
              name="income"
              onChange={this.onIncomeSelected}
              type="file"
              accept="image/*,.pdf"
              multiple
              id="incomeUpload"
              style={{ display: "none" }}
            />
            <input
              name="expense"
              onChange={this.onExpenseSelected}
              type="file"
              accept="image/*,.pdf"
              multiple
              id="expenseUpload"
              style={{ display: "none" }}
            />
          </React.Fragment>
        )}

        <input
          name="receipt"
          onChange={this.onReceiptSelected}
          type="file"
          accept="image/*,.pdf"
          id="receiptUpload"
          style={{ display: "none" }}
        />
        <input
          name="add"
          ref={this.addPagesControl}
          onChange={this.onPageSelected}
          type="file"
          accept="image/*,.pdf"
          multiple
          id="addPages"
          style={{ display: "none" }}
        />
        <input
          name="csv"
          onChange={onCSVChange}
          type="file"
          accept=".csv"
          id="csvUpload"
          style={{ display: "none" }}
        />
      </AxoDropzone>
    );
  }

  handleFileDrop = (files) => {
    if (this.isReadOnly()) {
      return;
    }

    this.processAllReceipts(files, false);
  };

  formatAmount(amount) {
    if (!amount) {
      return "0.00";
    }
    return NumberService.formatDecimal(amount);
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function () {
    return window.innerHeight - 250;
  },
})(BookkeepingView);
