import React from "react";
import screenfull from "screenfull";
import { SmallOrSmaller, MediumOrLarger } from "../../utilities/Responsive";
import * as $ from "jquery";
import "jquery-ui/themes/base/resizable.css";
import "jquery-ui/themes/base/draggable.css";
import "jquery-ui/ui/widgets/resizable";
import "jquery-ui/ui/widgets/draggable";

import "../../resources/css/fileViewer.css";

import {
  ApiService,
  DataActions,
  DataStore,
  FileInfoService,
  ScannerService,
  AuthorizationService,
} from "../../services/AxoServices";

import { Button, ButtonGroup, ButtonToolbar, Modal } from "react-bootstrap";

import {
  Icon,
  LexButton,
  InlineEdit,
  AxoLocal,
  DownloadButton,
  AsyncButton,
} from "../../utilities/LexUtilities";

export default class FileViewerModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.resetState();
    this.name = "FileViewerModal";
  }

  resetState = () => {
    return {
      filesArray: [],
      fileIndex: 0,
      fileExtensions: [],
      savedFileAsDocument: false,
      fileSaveFailed: false,
      showModal: false,
      showDuplicateNameError: false,
      userProfile: {},
      scannedText: "",
      cvr: 0,
      total: 0.0,
      withoutVat: 0.0,
      vat: 0.0,
      scanning: false,
      innerHtml: "",
      messageId: null,
      docXGetter: null,
      summaryText: null
    };
  };

  close = () => {
    this.setState(this.resetState());
    DataStore.unsubscribe(this.name);
  };

  setFileIndex = (fileIndex) => {
    this.setState({ fileIndex, innerHtml: "" }, () => {
      this.fetchFileContents();
    });
  };

  fetchFileContents = () => {
    let { fileIndex, filesArray, messageId, docXGetter } = this.state;
    if (filesArray.length <= fileIndex) {
      return;
    }
    let file = filesArray[fileIndex];

    if (file.fileName.toLowerCase().includes(".docx")) {
      docXGetter(file.id, messageId).then((response) => {
        this.setState({ innerHtml: response });
      });
    } else if (
      file.fileName.toLowerCase().includes(".txt") ||
      file.fileName.toLowerCase().includes(".htm")
    ) {
      ApiService.getRawText(file.id).then((response) => {
        this.setState({ innerHtml: response });
      });
    }
  };

  //Params: { messageId, fileIndex }
  open = (filesArray, params = { messageId: null, fileIndex: 0 }) => {
    let { messageId, fileIndex } = params;

    let newState = {
      filesArray,
      fileExtensions: [],
      fileIndex,
      savedFileAsDocument: false,
      fileSaveFailed: false,
      showDuplicateNameError: false,
      messageId,
      showModal: true,
      docXGetter: messageId
        ? ApiService.getDocXAttachmentAsHTML
        : ApiService.getDocXAsHTML,
      inlineFileGetter: messageId
        ? ApiService.getAttachmentInlinePath
        : ApiService.getFileInlinePath,
      downloadPathGetter: messageId
        ? ApiService.getAttachmentDownloadPath
        : ApiService.getDownloadPath,
      scannedText: "",
      cvr: 0,
      total: 0.0,
      withoutVat: 0.0,
      vat: 0.0,
      scanning: false,
    };
    DataStore.subscribe(this.name, (store) => {
      this.setState({ userProfile: store.userProfile });
    });
    DataStore.initializeUserProfile();

    filesArray.forEach((currentFile) => {
      let fileExtensions = currentFile.fileName.match(/\.[^/.]+$/);
      if (fileExtensions && fileExtensions.length > 0) {
        newState.fileExtensions.push(fileExtensions[0]);
      } else {
        newState.fileExtensions.push("");
      }
    });

    this.setState(newState, () => {
      this.fetchFileContents();
    });
  };

  getFileType = (file) => {
    var fileName = file.fileName.toLowerCase();
    if (fileName.includes(".pdf")) {
      return "pdf";
    } else if (FileInfoService.isImage(file)) {
      return "image";
    } else if (
      fileName.includes(".docx") ||
      fileName.includes(".txt") ||
      fileName.includes(".htm")
    ) {
      return "word";
    } else if (
      fileName.includes(".mp3") ||
      fileName.includes(".m4a") ||
      fileName.includes(".wav")
    ) {
      return "audio";
    } else if (
      fileName.includes(".mp4") ||
      fileName.includes(".avi") ||
      fileName.includes(".ogg") ||
      fileName.includes(".mov")
    ) {
      return "video";
    } else {
      return "other";
    }
  };

  onChangeFileName = async (input) => {
    let { onEditFile } = this.props;
    let { filesArray, fileExtensions, fileIndex } = this.state;
    let file = filesArray[fileIndex];
    let newFile = {
      ...file,
      fileName: input.value + fileExtensions[fileIndex],
    };

    this.setState({
      filesArray: filesArray.map((f, index) =>
        index === fileIndex ? newFile : f
      ),
    });

    let response = await DataActions.updateDocumentName(newFile);
    if (response.ok && !!onEditFile) {
      let resultFile = await response.json();
      onEditFile(resultFile);
    }
  };

  onPreviousClick = () => {
    var index = this.state.fileIndex;
    if (index !== 0) {
      this.setFileIndex(index - 1);
    }
  };

  onNextClick = () => {
    var index = this.state.fileIndex;
    if (index < this.state.filesArray.length - 1) {
      this.setFileIndex(index + 1);
    }
  };

  renderNavigationButtons = () => {
    if (this.state.filesArray.length < 2) {
      return null;
    }
    var enablePrevious = this.state.fileIndex !== 0;
    var enableNext = this.state.fileIndex < this.state.filesArray.length - 1;
    return (
      <ButtonGroup>
        <Button
          disabled={!enablePrevious}
          onClick={this.onPreviousClick}
          className="Lex-button-2"
        >
          <span>
            {" "}
            <Icon glyph="icon-fontello-left-3" />
            &nbsp;&nbsp;Forrige{" "}
          </span>
        </Button>
        <Button
          disabled={!enableNext}
          onClick={this.onNextClick}
          className="Lex-button-2"
        >
          <span>
            {" "}
            <Icon glyph="icon-fontello-right-bold" />
            &nbsp;&nbsp; Næste
          </span>
        </Button>
      </ButtonGroup>
    );
  };

  onImageLoad = () => {
    this.initializeDraggable();
    document.getElementById("loader").style.display = "none";
  };

  getBody = (file, fileType) => {
    if (fileType === "pdf") {
      return (
        <div key={file.id} style={{ flexGrow: 1 }} className="flexbox-column">
          <SmallOrSmaller>
            <object 
              data={this.state.inlineFileGetter(file.id, this.state.messageId)} 
              type="application/pdf" 
              style={{ flexGrow: 1, border: "none", minHeight: "600px" }}
              width="100%" 
              height="100%">
              <div>Your browser does not support PDF viewing.</div>
            </object>
          </SmallOrSmaller>
          <MediumOrLarger>
            <div className="frame-loader">
              <iframe
                title="viewport"
                id="viewport"
                style={{ flexGrow: 1, border: "none", minHeight: "600px" }}
                src={this.state.inlineFileGetter(file.id, this.state.messageId)}
                width="100%"
                height="100%"
              />
            </div>
          </MediumOrLarger>
        </div>
      );
    } else if (fileType === "image") {
      return (
        <div key={file.id}>
          <img
            id="loader"
            alt=""
            src="/imgs/app/loading.gif"
            style={{ maxWidth: "250px", margin: "auto", display: "block" }}
            className="img-circle"
          />
          <img
            alt=""
            id="viewport"
            src={this.state.inlineFileGetter(file.id, this.state.messageId)}
            onLoad={this.onImageLoad}
            style={{ maxWidth: "100%" }}
          />
        </div>
      );
    } else if (fileType === "word") {
      return (
        <div
          key={file.id}
          className="rightPadding leftPadding"
          style={{
            flexGrow: 1,
            height: "500px",
            overflowY: "scroll",
            background: "white",
          }}
          id="viewport"
          dangerouslySetInnerHTML={{ __html: this.state.innerHtml }}
        />
      );
    } else if (fileType === "audio") {
      return (
        <div key={file.id}>
          <audio controls>
            <source
              src={this.state.inlineFileGetter(file.id, this.state.messageId)}
              type="audio/mpeg"
            />
            <AxoLocal
              entity="Yourbrowserdoesnotsupporttheaudioelement"
              defaultValue={"Din browser støtter ikke lydelementet."}
            />
          </audio>
        </div>
      );
    } else if (fileType === "video") {
      return (
        <div key={file.id} className="text-center">
          <video
            id="viewport"
            ref={(c) => (this.video = c)}
            width="90%%"
            height="auto"
            controls
            style={{ display: "inline-block" }}
          >
            <source
              src={this.state.inlineFileGetter(file.id, this.state.messageId)}
              type="video/mp4"
            />
            <AxoLocal
              entity="Yourbrowserdoesnotsupportvideoplayback"
              defaultValue={" Din browser understøtter ikke videoafspilning"}
            />
          </video>
        </div>
      );
    } else if (file.fileName.includes(".doc")) {
      return (
        <div key={file.id}>
          <div>
            <b>
              <AxoLocal
                entity="Docfilescannotbedisplayed"
                defaultValue={".doc filer kan desværre ikke vises."}
              />
            </b>{" "}
          </div>
          <div>
            <AxoLocal
              entity="DocfileNameincludesdoc"
              defaultValue={
                "Upload dokumenter i .docx format hvis du ønsker at læse dem i systemet."
              }
            />{" "}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <AxoLocal
            entity="DocfileThefilecannotbedisplayed"
            defaultValue={
              "Filen kan ikke vises. Download filen hvis du ønsker at se den."
            }
          />
        </div>
      );
    }
  };

  onRemoveFromCase = () => {
    let currentFile = this.state.filesArray[this.state.fileIndex];
    DataActions.deleteCaseDocumentAssignment(
      this.props.selectedCase.id,
      currentFile.id
    );
    if (this.props.onRemoveFromCase) {
      this.props.onRemoveFromCase(currentFile.id);
    }
    this.close();
  };

  renderRemoveFromCaseButton = () => {
    var { selectedCase } = this.props;
    var { userProfile } = this.state;
    if (!selectedCase) {
      return null;
    }
    if (
      selectedCase.sharingUsers.length > 0 &&
      (!selectedCase.editor || selectedCase.editor.id !== userProfile.id)
    ) {
      return null;
    }
    return (
      <Button className="Lex-button-2" onClick={this.onRemoveFromCase}>
        <span>
          {" "}
          <Icon glyph="icon-fontello-scissors" />
          &nbsp;&nbsp;
          <AxoLocal
            className="axored"
            entity="DocfileRemovefromthiscase"
            defaultValue={"Fjern fra denne sag"}
          />
        </span>
      </Button>
    );
  };

  renderSaveCopyAsDocumentButton = () => {
    let { readOnly } = this.props;
    if (readOnly) {
      return null;
    }

    var currentFile = this.state.filesArray[this.state.fileIndex];
    var { userProfile } = this.state;
    var { messageId } = this.state;
    //Social files, email attachments and files with other owners can be saved locally
    if (
      currentFile.sectionFileType !== 1 &&
      !messageId &&
      currentFile.userName === userProfile.userName
    ) {
      return null;
    } else if (this.state.savedFileAsDocument) {
      return (
        <Button disabled style={{ background: "white", color: "green" }}>
          <strong>
            {" "}
            <AxoLocal
              key="wasSaved"
              entity="DocfileThedocumentwassaved"
              defaultValue={"Dokumentet blev gemt"}
            />{" "}
            <Icon
              style={{ color: "#4CAF50", fontSize: "12px" }}
              glyph="icon-fontello-ok-3"
            />
          </strong>
        </Button>
      );
    } else if (this.state.fileSaveFailed) {
      return (
        <Button disabled style={{ background: "white", color: "red" }}>
          <strong>
            {" "}
            <AxoLocal
              key="wasSaved"
              entity="DocFileDocumentSaveFailed"
              defaultValue={"Dokumentet kunne ikke gemmes"}
            />
          </strong>
        </Button>
      );
    }
    //If the file is associated with a case, allow the user to transfer the file to this case
    else if (this.props.targetCase) {
      var caseNumber = this.props.targetCase.caseNumber;
      return (
        <Button className="Lex-button-2" onClick={this.onSaveToCase}>
          <Icon glyph="icon-fontello-floppy-1" />
          &nbsp;&nbsp;
          <AxoLocal
            key="saveDoc"
            entity="DocFileSaveToCase"
            data={{ caseNumber }}
            defaultValue={"Gem på sagen"}
          />
          <span> {caseNumber}</span>
        </Button>
      );
    }
    return (
      <Button className="Lex-button-2" onClick={this.onSaveAsDocument}>
        <Icon glyph="icon-fontello-floppy-1" />
        &nbsp;&nbsp;
        <AxoLocal
          key="saveDoc"
          entity="DocfileSaveasinternaldocument"
          defaultValue={"Gem som internt dokument"}
        />
      </Button>
    );
  };

  onSaveToCase = () => {
    var currentFile = this.state.filesArray[this.state.fileIndex];
    DataActions.copyDocument(currentFile.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject("Document could not be saved");
        }
      })
      .then((newDocument) => {
        DataActions.createCaseDocumentAssignment({
          caseId: this.props.targetCase.id,
          fileInfoModelId: newDocument.id,
        }).then((response) => {
          if (response.ok) {
            this.setState({
              savedFileAsDocument: true,
            });
          } else {
            return Promise.reject("Document could not be saved to case");
          }
        });
      })
      .catch((reason) => {
        console.log(reason);
        this.showDocumentSaveFailureMessage();
      });
  };

  onSaveAsDocument = () => {
    var currentFile = this.state.filesArray[this.state.fileIndex];
    DataActions.copyDocument(currentFile.id)
      .then((response) => {
        if (response.ok) {
          this.setState({
            savedFileAsDocument: true,
          });
        } else {
          this.showDocumentSaveFailureMessage();
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.showDocumentSaveFailureMessage();
      });
  };

  showDocumentSaveFailureMessage = () => {
    this.setState({
      fileSaveFailed: true,
    });
    setTimeout(() => {
      this.setState({
        fileSaveFailed: false,
      });
    }, 3000);
  };

  isNameAvailable = (value) => {
    let { filesArray, fileIndex } = this.state;

    var document = filesArray[fileIndex];
    var newName = value + document.fileType;
    var documentWithName = DataStore.getDocuments()
      .concat(filesArray)
      .find((d) => d.fileName === newName);

    if (documentWithName && documentWithName.id !== document.id) {
      this.setState({ showDuplicateNameError: true });
      return false;
    }
    if (this.state.showDuplicateNameError === true) {
      this.setState({ showDuplicateNameError: false });
    }
    return true;
  };

  onFullScreen = () => {
    const video = document.getElementById("viewport");
    screenfull.toggle(video);
  };

  renderFullscreenButton = (fileType) => {
    if (
      (fileType !== "video" &&
        fileType !== "image" &&
        fileType !== "word" &&
        fileType !== "pdf") ||
      !screenfull.enabled
    ) {
      return null;
    }
    return (
      <Button className="Lex-button-2" onClick={this.onFullScreen}>
        <span>
          <Icon glyph="icon-fontello-resize-full-4" />
          &nbsp;&nbsp;
          <AxoLocal
            entity="FileViewerModalFullscreen"
            defaultValue={"Fuld skærm"}
          />
        </span>
      </Button>
    );
  };

  onScan = (id) => {
    this.setState({ scanning: true });
    ApiService.scanText(id).then((scannedText) => {
      let scanResult = ScannerService.scanReceiptText(scannedText);
      this.setState(scanResult);
      ApiService.summarizeCollaborationString(`Find total pris inklusiv moms, moms, CVR-nummer for sælger og navn på produktet.
      Skriv svaret i JSON format med følgende skema:
      {
        Pris:
        Moms:
        CVR:
        Produktnavn:
      }
      
      ${scannedText}`).then(aIResponse => {
        aIResponse.text().then(aIReponseText => {
          this.setState({ aIReponseText })
        })
      })
    });

  };

  onSummarize = async (id) => {
    this.setState({ scanning: true });
    let summaryText = await ApiService.summarizeFileContent(id);

    this.setState({ 
      summaryText,
      scanning: false
    });
    return true;
  };

  onSummarizeAsList = async (id) => {
    this.setState({ scanning: true });
    let summaryText = await ApiService.summarizeFileContentAsList(id);

    this.setState({ 
      summaryText,
      scanning: false
    });
    return true;
  };

  onEvaluateAsContract = async (id) => {
    this.setState({ scanning: true });
    let summaryText = await ApiService.evaluateFileContentAsContract(id);

    this.setState({ 
      summaryText,
      scanning: false
    });
    return true;
  }
  

  initializeDraggable = () => {
    var minHeight = $(".modal-content").height();
    $(".modal-content").resizable({
      // alsoResize: ".modal-dialog",
      minHeight: minHeight,
      minWidth: 300,
    });
    $(".modal-dialog").draggable();
  };

  createSigningRequest = async () => {
    let doc = this.state.filesArray[this.state.fileIndex];
    let response = await DataActions.createSigningRequest({
      fileInfoModelId: doc.id,
    });

    let request = await response.json();
    this.setState((prevState) => ({
      filesArray: prevState.filesArray.map((f) => {
        if (f.id === request.fileInfoModelId) {
          return {
            ...f,
            signingRequests: f.signingRequests.concat([request]),
          };
        }
        return f;
      }),
    }));

    return response.ok;
  };

  renderToolbar = (file, fileType) => {
    let { userProfile, scanning } = this.state;
    let userType = AuthorizationService.getUserType(userProfile);

    return (
      <ButtonToolbar>
        <Button className="Lex-button-2" onClick={this.close}>
          {" "}
          <span>
            {" "}
            <Icon glyph="icon-fontello-cancel-5" />
            &nbsp;&nbsp;
            <AxoLocal entity="Docfileclose" defaultValue={"Luk"} />{" "}
          </span>{" "}
        </Button>
        {this.renderFullscreenButton(fileType)}
        {this.renderRemoveFromCaseButton()}
        {this.renderSaveCopyAsDocumentButton()}
        <DownloadButton
          href={this.state.downloadPathGetter(file.id, this.state.messageId)}
        >
          <span>
            <Icon glyph="icon-fontello-download-alt" />
            &nbsp;&nbsp;
            <AxoLocal
              entity="DocfileDownloadfile"
              defaultValue={"Download fil"}
            />
          </span>
        </DownloadButton>
        {(userType === "Admin" || userType === "Accountant") &&
        (FileInfoService.isImage(file) || fileType === "pdf") && (
          <>
            <Button
              disabled={scanning}
              className="Lex-button-2"
              onClick={() => {
                this.onScan(file.id);
              }}
            >
              <span>
                <Icon glyph="icon-fontello-spinner" />
                &nbsp;&nbsp;
                <AxoLocal
                  entity="paymentDate8a"
                  defaultValue={"Scan tekst"}
                />{" "}
                &nbsp;
                {scanning ? (
                  <img
                    alt=""
                    src="/imgs/app/loading.gif"
                    className="img-circle"
                    width="20"
                    height="20"
                  />
                ) : null}
              </span>
            </Button>
            <AsyncButton
              disabled={scanning}
              onClick={async () => {
                return await this.onSummarize(file.id);
              }}
            >
              Opsummer
            </AsyncButton>
            <AsyncButton
              disabled={scanning}
              onClick={async () => {
                return await this.onSummarizeAsList(file.id);
              }}
            >
              Liste
            </AsyncButton>
            <AsyncButton
              disabled={scanning}
              onClick={async () => {
                return await this.onEvaluateAsContract(file.id);
              }}
            >
              Kontrakt vurdering
            </AsyncButton>
          </>

        )}
        {/* { (userType === 'Admin' || userType === 'EasyID' ) 
            && fileType === 'pdf' && (!file.signingRequests || file.signingRequests.length === 0) && (
          <AsyncButton 
            onClick={this.createSigningRequest}>
           <AxoLocal entity='axoEntityidcode218' defaultValue={'Send til signering'}/> 
          </AsyncButton>
        ) } */}
        {this.renderNavigationButtons()}
      </ButtonToolbar>
    );
  };

  render() {
    if (this.state.filesArray.length === 0) {
      return null;
    }

    var file = this.state.filesArray[this.state.fileIndex];
    var fileExtension = this.state.fileExtensions[this.state.fileIndex];
    if (!file.fileName) {
      return null;
    }

    let { readOnly } = this.props;
    let fileType = this.getFileType(file);

    let { summaryText, aIReponseText } = this.state;

    return (
      <Modal
        onEntered={this.initializeDraggable}
        show={this.state.showModal}
        onHide={this.close}
        bsSize={this.props.size || "large"}
      >
        <div className="flexbox-column" style={{ height: "100%" }}>
          <Modal.Header
            style={{ flexGrow: 0 }}
            className="backgroundModalHeaderFooter"
            closeButton
          >
            {!readOnly && !file.lockedName ? (
              <div>
                <span>
                  <AxoLocal
                    entity="DocfileonChangeFileName"
                    defaultValue={"Omdøb"}
                  />
                  :{" "}
                </span>
                <InlineEdit
                  value={file.fileName.replace(/\.[^/.]+$/, "") || "---"}
                  change={this.onChangeFileName}
                  validate={this.isNameAvailable}
                />
                <span>{fileExtension}</span>
              </div>
            ) : (
              <div>{file.fileName}</div>
            )}
            {!!file.receiptNumber ? (
              <div>
                <AxoLocal
                  entity="CaseDataTableFixedlabelAddedToCasedocuments"
                  defaultValue={"Bilag"}
                />{" "}
                #{file.receiptNumber}
              </div>
            ) : null}

            {this.state.showDuplicateNameError ? (
              <div className="axored">
                <AxoLocal
                  entity="DocfileThenameistaken"
                  defaultValue={"Navnet er optaget."}
                />
              </div>
            ) : null}
            {this.renderToolbar(file, fileType)}
          </Modal.Header>
          <Modal.Body style={{ flexGrow: 1 }} className="flexbox-column">
            {this.getBody(file, fileType)}
          </Modal.Body>
          <Modal.Footer
            style={{ flexGrow: 0 }}
            className="backgroundModalHeaderFooter"
          >
            <div className="text-left">
              <span style={{ textAlign: "left" }}>
                {" "}
                <AxoLocal
                  entity="DocfilefileType"
                  defaultValue={"Filtype."}
                />: {file.fileType}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span style={{ textAlign: "left" }}>
                <AxoLocal
                  entity="DocfilesizeString"
                  defaultValue={"Størrelse."}
                />
                : {file.sizeString}
              </span>
            </div>
            {/*{ fileType === "image" ? <div style={{ textAlign: "left"}} >Link til billede: {this.state.inlineFileGetter(file.id, this.state.messageId)}</div> : null }*/}
            {this.renderToolbar(file, fileType)}
            {/* { file.signingRequests && file.signingRequests.length > 0 && (
              <div className='text-left'>
                Signering:&nbsp;
                <FormControl 
                  value={window.location.protocol + '//' + window.location.host + '/Signing/' + file.signingRequests[0].guid}
                />
              </div>
            )} */}
            {!!this.state.scannedText && (
              <div className="text-left">
                <h4>
                  <AxoLocal entity="paymentDate9a" defaultValue={"Nøgletal"} />{" "}
                </h4>
                <div>
                  <AxoLocal
                    entity="SidebarRightContainerCompanyCode"
                    defaultValue={"CVR:"}
                  />
                  {this.state.cvr}
                </div>
                <div>
                  <AxoLocal
                    entity="InvoiceInvoicetotal"
                    defaultValue={"Total"}
                  />
                  : {this.state.total.toFixed(2)}
                </div>
                <div>
                  <AxoLocal
                    entity="paymentDate10a"
                    defaultValue={"Uden moms"}
                  />
                  : {this.state.withoutVat.toFixed(2)}
                </div>
                <div>
                  <AxoLocal
                    entity="invoiPaymentattheSubtotalVAT"
                    defaultValue={"Moms"}
                  />{" "}
                  : {this.state.vat.toFixed(2)}
                </div>
                <div>
                  <AxoLocal
                    entity="TimeEntryFormntimeEntry"
                    defaultValue={"Dato"}
                  />{" "}
                  : {!!this.state.date ? this.state.date.format("LL") : ""}
                </div>
                {!!aIReponseText && (
                  <div className="text-left topPadding" style={{ whiteSpace: "pre-wrap" }}>
                    {aIReponseText}
                  </div>
                )}
                <h4>
                  <AxoLocal entity="paymentDate11a" defaultValue={"Råtekst"} />
                </h4>
                <div className="text-left">
                  {this.state.scannedText}
                </div>
              </div>
            )}
            {!!summaryText && (
              <div className="text-left" style={{ whiteSpace: "pre-wrap" }}>
                {summaryText}
              </div>
            )}
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}
