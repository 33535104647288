import React from "react";
// import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import $ from "jquery";
import { Table, Column, Cell } from "fixed-data-table-2";
import { ExtraSmall, SmallOrLarger } from "../../../utilities/Responsive";
import BookkeepingGridView from "./BookkeepingGridView";
import { debounce } from "lodash";
import QueryString from "query-string";

import moment from "moment";

import {
  FormGroup,
  InputGroup,
  FormControl,
  ButtonToolbar,
} from "react-bootstrap";

import {
  ScannerService,
  ApiService,
  DataStore,
  DataActions,
  NumberService,
  RoutingService,
  ModalService,
  ContactService,
} from "../../../services/AxoServices";

import {
  LexButton,
  TableBase,
  SortHeaderCell,
  DataListWrapper,
  Dimensions,
  Icon,
  FileViewerModal,
  InlineEdit,
  InlineNumberEdit,
  LoadingIcon,
  ClientSearchBox,
  Flexbox,
  FlexElement,
  PrintReceiptsModal,
  AxoLocal,
  getText,
  AsyncButton,
  Link,
  AxoCheckbox,
  AxoDropzone,
  FileViewer,
  AxoDateTime,
  AxoDateTimeEdit,
  TextFilterModal,
  NumberFilterModal,
  DateFilterModal,
  AxoSelect,
} from "../../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
    index: 0,
  },
  receiptNumber: {
    width: 65,
    shown: true,
    index: 1,
  },
  isIncome: {
    width: 120,
    shown: true,
    index: 2,
  },
  description: {
    width: 185,
    shown: true,
    index: 3,
  },
  vatNumber: {
    width: 150,
    shown: true,
    index: 4,
  },
  account: {
    width: 200,
    shown: true,
    index: 5,
  },
  amount: {
    width: 100,
    shown: true,
    index: 6,
  },
  vat: {
    width: 100,
    shown: true,
    index: 7,
  },
  balanceAccount: {
    width: 200,
    shown: true,
    index: 8,
  },
  creationDate: {
    width: 165,
    shown: true,
    index: 9,
  },
  receipt: {
    width: 125,
    shown: true,
    index: 10,
  },
  actions: {
    width: 100,
    shown: true,
    index: 11,
  },
};

const ViewType = {
  NORMAL: 0,
  SCAN: 1,
  TRASH: 2,
};

class BookkeepingView extends TableBase {
  static propTypes = {
    contactMap: PropTypes.object.isRequired,
    entries: PropTypes.array.isRequired,
    selectedContact: PropTypes.object.isRequired,
    handleSelectedClient: PropTypes.func.isRequired,
    onToggleCards: PropTypes.func.isRequired,
    onCSVChange: PropTypes.func.isRequired,
    readingCSV: PropTypes.bool.isRequired,
    showCSVError: PropTypes.bool.isRequired,
    showCSVSuccess: PropTypes.bool.isRequired,
    fiscalYears: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.name = "BookkeepingView";

    this._defaultSortIndexes = [];
    if (
      !!props.sortIndexes &&
      props.sortIndexes.length === props.entries.length
    ) {
      this._defaultSortIndexes = props.sortIndexes;
    } else {
      this._defaultSortIndexes = this.generateDefaultSortIndexes(props.entries);
    }

    let tableEntries = props.entries;

    this.defaultColumnDefs = defaultColumnDefs;

    this.state = {
      tableEntries,
      selectedEntries: new Set(),
      displayedEntryId: 0,
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        actions: 200,
      },
      maxColumnWidth: 400,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      entryFilterTexts: {},
      selectedEntryColumn: "",
      deletingId: 0,
      uploadingExpense: false,
      scanningExpense: false,
      uploadingIncome: false,
      scanningIncome: false,
      documentsUploaded: 0,
      documentsToBeUploaded: 0,
      documentsScanned: 0,
      documentsToBeScanned: 0,
      showStorageWarning: false,
      showFileUploadError: false,
      showFileMaxSizeWarning: false,
      showInvalidDatesWarning: false,
      showLockedFiscalYearsWarning: false,
      invalidDateEntryIds: new Set(),
      showImbalanceWarning: false,
      vatErrorEntries: new Set(),

      confirmPosting: false,
      showAllAccounts: true,
      showAllBalanceAccounts: true,

      selectedEntryId: 0,

      receiptsWhoseDatesCouldNotBeScanned: [], //Ids of receipts whose dates could not be scanned
      multiPageUpload: false,
      cachedPageFiles: [],
      multiPageIsIncome: false,
      ...this.getTranslatedTexts(),

      showMissingCurrentFiscalYearError: false,

      confirmDeleteEntryId: 0,
      confirmDeleteSelected: false,

      addingPageToId: 0,
      addedPageToId: 0,

      csv: [[]],

      finalizationStartDate: moment.utc(),
      finalizationEndDate: moment.utc(),

      confirmBankBalancing: false,

      //Ensures that active column is fully in view when being edited
      scrollToColumnIndex: 0,

      columnFilters: {}, //Map from column names to filters,

      creditDebitFilter: 0, //0: All, 1: Credit. 2: Debit
      hasReceiptFilter: 0,
    };

    this.selectedEntryId = 0;
    this.finishingComposite = false;
    this.addPagesControl = React.createRef();
    this.tableContainer = React.createRef();
    this.textFilterModal = React.createRef();
    this.numberFilterModal = React.createRef();
    this.dateFilterModal = React.createRef();
  }

  onDisplayEntry = (entryId) => {
    this.setState((prevState) => ({
      displayedEntryId: prevState.displayedEntryId !== entryId ? entryId : 0,
    }));
  };

  clearSelectedEntries = () => {
    let { clearSelectedEntries } = this.props;
    if (!!clearSelectedEntries) {
      clearSelectedEntries();
      return;
    }

    this.setState({ selectedEntries: new Set() });
  };

  onSelectEntry = (entryId, event) => {
    let { onSelectEntry } = this.props;
    if (!!onSelectEntry) {
      onSelectEntry(entryId, event);
      return;
    }

    let selectedEntries = new Set(this.state.selectedEntries);
    if (event.target.checked) {
      selectedEntries.add(entryId);
    } else {
      selectedEntries.delete(entryId);
    }
    this.setState({ selectedEntries });
  };

  onSelectAll = () => {
    let { onSelectAll } = this.props;
    let { selectedEntries, sortedDataList } = this.state;

    if (!!onSelectAll) {
      onSelectAll(sortedDataList);
      return;
    }

    let allSelected = selectedEntries.size === sortedDataList.getSize();

    if (allSelected) {
      this.setState({ selectedEntries: new Set() });
    } else {
      this.setState({
        selectedEntries: new Set(sortedDataList._data.map((d) => d.id)),
      });
    }
  };

  componentDidMount = () => {
    this.checkForCurrentFiscalYear();

    let query = QueryString.parse(this.props.location.search);
    if (!!query.finalize) {
      this.onFinalizeDrafts();
    }
  };

  componentDidUpdate = (prevProps) => {
    let {
      locale,
      selectedFiscalYearId,
      selectedStartDate,
      selectedEndDate,
      updateSortIndexes,
    } = this.props;

    let { sortedDataList } = this.state;

    if (prevProps.locale !== locale) {
      this.setState(this.getTranslatedTexts());
    }

    if (
      selectedFiscalYearId !== prevProps.selectedFiscalYearId ||
      selectedStartDate !== prevProps.selectedStartDate ||
      selectedEndDate !== prevProps.selectedEndDate
    ) {
      //Ensure that entries outside current time interval cannot be selected.
      this.setState({ selectedEntries: new Set() });
    }

    if (!!updateSortIndexes) {
      //Cache sorting in parent component
      updateSortIndexes(sortedDataList.getIndexMap());
    }

    this.checkForCurrentFiscalYear();

    if (prevProps !== this.props) {
      if (prevProps.entries.length !== this.props.entries.length) {
        this.updateFiltering();
        return;
      }
      for (let i = 0; i < prevProps.entries.length; i++) {
        if (prevProps.entries[i] !== this.props.entries[i]) {
          this.updateFiltering();
          return;
        }
      }
      this.debouncedFiltering();
    }
  };

  debouncedFiltering = debounce(() => {
    this.updateFiltering();
  }, 500);

  updateFiltering = () => {
    let { entries } = this.props;
    let { searchText } = this.state;
    let filteredEntries = this.getFilteredEntries(entries, searchText);

    this.updateEntries(entries, filteredEntries);
  };

  checkForCurrentFiscalYear = () => {
    let { fiscalYears, selectedContact } = this.props;

    if (!fiscalYears || !selectedContact.id) {
      return;
    }

    let now = moment.utc();

    if (
      !fiscalYears.find(
        (f) =>
          moment.utc(f.startDate).isSameOrBefore(now) &&
          moment.utc(f.endDate).isSameOrAfter(now)
      )
    ) {
      this.setState({ showMissingCurrentFiscalYearError: true });
    } else {
      this.setState({ showMissingCurrentFiscalYearError: false });
    }
  };

  getTranslatedTexts = () => {
    return {
      creditLabel: getText("AccountingTabViewEntity41"),
      debitLabel: getText("AccountingTabViewEntity42"),
      uploadLabel: getText("DocumentTabViewUpload"),
      deleteLabel: getText("axoidcode179"),
      copyLabel: getText("axoAccounting24"),
      editLabel: getText("presentationMarketing6"),
      chooseLabel: getText("ClientBookingSelectLawyer"),
      selectAccountLabel: getText("axoAccounting6f", "Vælg konto"),
    };
  };

  onSearch = (event) => {
    let { tableEntries } = this.state;

    let entries = this.getFilteredEntries(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(entries);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, entries),
    });
  };

  getFilteredEntries = (entries, searchText) => {
    return entries.filter((e) =>
      (e.description || "").toLowerCase().includes(searchText.toLowerCase())
    );
  };

  onUploadIncome = () => {
    $("#incomeUpload").trigger("click");
  };

  onUploadExpense = () => {
    $("#expenseUpload").trigger("click");
  };

  onUploadReceiptForExistingEntry = (entryId) => {
    this.selectedEntryId = entryId;
    $("#receiptUpload").trigger("click");
  };

  onIncomeSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, true);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onExpenseSelected = (event) => {
    let files = event.target.files;
    try {
      this.processAllReceipts(files, false);
    } finally {
      event.target.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  finishMultiPageUpload = async () => {
    let { cachedPageFiles, multiPageIsIncome } = this.state;

    this.finishingComposite = true;
    await this.processAllReceipts(cachedPageFiles, multiPageIsIncome);
    this.setState({
      cachedPageFiles: [],
    });
    this.finishingComposite = false;
    return true;
  };

  onReceiptSelected = async (event) => {
    let { selectedContact, actions } = this.props;

    let entry = this.props.entries.find((e) => e.id === this.selectedEntryId);
    if (!entry) {
      return;
    }

    let files = event.target.files;
    if (files.length === 0) {
      return;
    }

    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    let uploadResponse = await actions.uploadDocuments(files, {
      clientId: selectedContact.id,
      convertToPdf: true,
      skipOptimisticUpdate: true,
    });

    let addedFiles = [];
    if (uploadResponse.ok) {
      addedFiles = await uploadResponse.json();
      if (addedFiles.length === 0) {
        return;
      }
    } else {
      return this.displayResponseWarnings(uploadResponse);
    }

    let addedFile = addedFiles[0];
    entry.receiptId = addedFile.id;
    entry.receipt = addedFile;
    return actions.updateBookkeepingDraftEntry(entry);
  };

  async processAllReceipts(files, isIncome) {
    let {
      // scanReceipts,
      actions,
    } = this.props;

    let { multiPageUpload } = this.state;

    if (files.length === 0) {
      return;
    }
    let file = files[0];
    if (file.size / Math.pow(1024, 2) > 50) {
      this.showWarning("showFileMaxSizeWarning");
      return;
    }

    let fileArray = Array.from(files);

    if (multiPageUpload && !this.finishingComposite) {
      this.setState((prevState) => ({
        multiPageIsIncome: isIncome,
        cachedPageFiles: prevState.cachedPageFiles.concat(fileArray),
      }));
      return;
    }

    if (isIncome) {
      this.setState({ uploadingIncome: true });
    } else {
      this.setState({ uploadingExpense: true });
    }

    let { selectedContact, splitPDF } = this.props;

    this.setState({
      documentsToBeUploaded: fileArray.length,
      documentsUploaded: 0,
    });

    if (this.finishingComposite) {
      let response = await actions.uploadCompositeImage(fileArray, {
        clientId: selectedContact.id,
      });
      this.setState((oldState) => ({
        documentsUploaded: oldState.documentsUploaded + 1,
      }));
      await this.processFileResponse(response, isIncome);
    } else {
      for (let file of fileArray) {
        //Process files in sequence
        let response = await actions.uploadDocuments([file], {
          clientId: selectedContact.id,
          convertToPdf: true,
          splitPDF,
          skipOptimisticUpdate: true,
        });

        await this.processFileResponse(response, isIncome);
        this.setState((oldState) => ({
          documentsUploaded: oldState.documentsUploaded + 1,
        }));
      }
    }

    this.setState({
      documentsToBeUploaded: 0,
      documentsUploaded: 0,
      uploadingIncome: false,
      uploadingExpense: false,
    });
  }

  processFileResponse = async (response, isIncome) => {
    let addedFiles = [];
    if (response.ok) {
      addedFiles = await response.json();
    } else {
      return this.displayResponseWarnings(response);
    }
    for (let file of addedFiles) {
      await this.processFile(file, isIncome);
    }
  };

  processFile = async (file, isIncome) => {
    let { scanReceipts } = this.props;
    if (scanReceipts) {
      this.setState({
        scanningIncome: isIncome,
        scanningExpense: !isIncome,
      });
    }

    if (scanReceipts) {
      await this.processReceipt(file, isIncome);
    } else {
      await this.processReceiptWithoutScan(file, isIncome);
    }

    this.setState({
      documentsScanned: 0,
      documentsToBeScanned: 0,
      scanningIncome: false,
      scanningExpense: false,
    });
  };

  displayResponseWarnings = async (response) => {
    if (response.status === 400) {
      let text = await response.text();
      if (text === "Storage") {
        this.showWarning("showStorageWarning");
        return;
      } else {
        this.showWarning("showFileUploadError");
        return;
      }
    } else {
      this.showWarning("showFileUploadError");
      return;
    }
  };

  getStartAndEndDate = () => {
    let { selectedFiscalYear, selectedStartDate, selectedEndDate } = this.props;

    let startDate =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDate = selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    return { startDate, endDate };
  };

  getNewCreationDate = () => {
    let { startDate, endDate } = this.getStartAndEndDate();
    let newCreationDate = moment.utc();
    newCreationDate = moment.max([startDate, newCreationDate]);
    newCreationDate = moment.min([newCreationDate, endDate]);

    return newCreationDate;
  };

  processReceipt = (file, isIncome) => {
    let { selectedContact } = this.props;

    let newCreationDate = this.getNewCreationDate();

    return ApiService.scanText(file.id)
      .then((scannedText) => {
        return {
          fileId: file.id,
          scannedText,
        };
      })
      .then((scanResult) => {
        let scanProcessingResult = ScannerService.scanReceiptText(
          scanResult.scannedText,
          parseInt(selectedContact.identityCode, 10)
        );
        let entry = {
          isIncome,
          description:
            scanProcessingResult.cvr || file.fileName.replace(/\.[^/.]+$/, ""),
          amount: scanProcessingResult.total,
          vat: scanProcessingResult.vat,
          creationDate: !!scanProcessingResult.date
            ? scanProcessingResult.date.format()
            : newCreationDate.format(),
          receipt: file,
          receiptId: scanResult.fileId,
          contactInfoId: selectedContact.id,
          balanceFinanceAccountId: this.getDefaultPaymentAccount(),
        };
        if (!scanProcessingResult.date) {
          this.setState((prevState) => ({
            receiptsWhoseDatesCouldNotBeScanned: [
              ...prevState.receiptsWhoseDatesCouldNotBeScanned,
              entry.receiptId,
            ],
          }));
        }
        if (!!scanProcessingResult.cvr) {
          return ApiService.getCVRName(scanProcessingResult.cvr)
            .then((companyInfo) => {
              entry.vatNumber = scanProcessingResult.cvr || "";
              entry.description = companyInfo.name;
              return this.createNewEntry(entry);
            })
            .catch((reason) => {
              console.log(reason);
              entry.description = "";
              return this.createNewEntry(entry);
            });
        } else {
          return this.createNewEntry(entry);
        }
      })
      .catch((reason) => {
        this.setState({
          uploadingIncome: false,
          uploadingExpense: false,
          scanningIncome: false,
          scanningExpense: false,
        });
      });
  };

  processReceiptWithoutScan = (file, isIncome) => {
    let { selectedContact } = this.props;

    let entry = {
      description: file.fileName.replace(/\.[^/.]+$/, ""), //Regex removes file extension
      isIncome,
      receipt: file,
      receiptId: file.id,
      contactInfoId: selectedContact.id,
      balanceFinanceAccountId: this.getDefaultPaymentAccount(),
      creationDate: this.getNewCreationDate().format(),
    };

    return this.createNewEntry(entry);
  };

  showWarning = (warningName) => {
    this.setState({ [warningName]: true });
    setTimeout(() => {
      this.setState({ [warningName]: false });
    }, 3000);
  };

  onDeleteEntry = (entry) => {
    this.doDeleteEntry(entry);

    // this.setState({ confirmDeleteEntryId: entry.id });
  };

  doDeleteSelectedEntry = () => {
    let { entries } = this.props;
    let entry = entries.find((e) => e.id === this.state.confirmDeleteEntryId);
    if (!entry) {
      return;
    }

    this.doDeleteEntry(entry);
  };

  doDeleteEntry = async (entry, reload = true) => {
    this.setState({ deletingId: entry.id });

    let response = null;

    if (reload) {
      response = await this.props.actions.deleteBookkeepingDraftEntryAndReceipt(
        entry
      );
    } else {
      response = await ApiService.deleteBookkeepingDraftEntryAndReceipt(
        entry.id
      );
    }

    if (reload) {
      this.setState({
        selectedEntries: new Set(),
        deletingId: 0,
        confirmDeleteEntryId: 0,
      });
    }

    return response;
  };

  onShowReceiptForEntry = (entryId) => {
    let entry = this.props.entries.find((p) => p.id === entryId);
    this.onShowReceipt(entry.receipt);
  };

  onShowReceipt = (receipt) => {
    this.fileModal.open([receipt]);
  };

  onChangePropertyValue = async (id, propertyName, value, reload = true) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    let newEntry = {
      ...entry,
      [propertyName]: value,
    };

    if (reload) {
      return this.props.actions.updateBookkeepingDraftEntry(newEntry);
    } else {
      return ApiService.updateBookkeepingDraftEntry(newEntry);
    }
  };

  onChangePropertyValueDebounced = (id, propertyName, value) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }

    let newValue = {
      ...entry,
      [propertyName]: value,
    };

    this.props.actions.updateBookkeepingDraftEntryState(newValue);
    this.debouncedUpdate(newValue);
  };

  debouncedUpdate = debounce((model) => {
    this.props.actions.updateBookkeepingDraftEntry(model);
  }, 1000);

  onChangeVatNumber = (id, value) => {
    let { actions, autoAccounts, viewType } = this.props;

    let newEntry = this.getEntryById(id);
    newEntry.vatNumber = value;
    actions.updateBookkeepingDraftEntry(newEntry);

    if (
      autoAccounts &&
      (viewType === ViewType.SCAN || !newEntry.financeAccountId)
    ) {
      this.fetchStoredAccount(newEntry, newEntry.vatNumber);
    }

    if (!newEntry.description && ScannerService.isValidCVR(value)) {
      return ApiService.getCVRName(value)
        .then((companyInfo) => {
          newEntry.description = companyInfo.name;
          actions.updateBookkeepingDraftEntry(newEntry);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };

  onChangeDescription = (id, value) => {
    let { actions, autoAccounts, viewType } = this.props;

    let newEntry = this.getEntryById(id);
    newEntry.description = value;
    actions.updateBookkeepingDraftEntry(newEntry);

    if (
      autoAccounts &&
      (viewType === ViewType.SCAN || !newEntry.financeAccountId)
    ) {
      this.fetchStoredAccount(newEntry, newEntry.description);
    }
  };

  getEntryById = (id) => {
    let { tableEntries } = this.state;
    let entry = tableEntries.find((e) => e.id === id);
    if (!entry) {
      return;
    }
    let newEntry = { ...entry };
    return newEntry;
  };

  fetchStoredAccount = (entry, key) => {
    let { selectedContact, actions } = this.props;

    ApiService.getStoredAccount({ clientId: selectedContact.id, key })
      .then((accountId) => {
        let accountNumber = parseInt(accountId, 10);
        if (!accountNumber || isNaN(accountNumber)) {
          return;
        }
        actions.updateBookkeepingDraftEntry({
          ...entry,
          financeAccountId: accountNumber,
        });
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  getFinanceAccountPlan = () => {
    let { clientPlan } = this.props;

    return clientPlan;
  };

  updateVat = (entry) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;
    var account = financeAccountMap[entry.financeAccountId];
    if (!account) {
      return entry;
    }

    if (entry.amount <= 0) {
      entry.vat = 0;
      return entry;
    }

    var taxSpec = taxSpecificationMap[account.taxSpecificationId];
    if (!taxSpec) {
      entry.vat = 0;
      return entry;
    }

    let isReverseVat =
      taxSpec.taxType === "ForeignGoods" ||
      taxSpec.taxType === "ServiceReverseCharge";

    if (isReverseVat) {
      //Amount is without vat
      entry.vat = (entry.amount * taxSpec.taxPercentage) / 100;
    } else {
      entry.vat =
        entry.amount - entry.amount / (1 + taxSpec.taxPercentage / 100);
    }

    return entry;
  };

  isReverseVat = (entry) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;
    let account = financeAccountMap[entry.financeAccountId];
    if (!account) {
      return false;
    }

    let taxSpec = taxSpecificationMap[account.taxSpecificationId];
    if (
      !!taxSpec &&
      (taxSpec.taxType === "ForeignGoods" ||
        taxSpec.taxType === "ServiceReverseCharge")
    ) {
      return true;
    }

    return false;
  };

  updateMainAccountAndVat = async (entry, accountId, reload = true) => {
    let { financeAccountMap, taxSpecificationMap } = this.props;

    let newEntry = { ...entry };
    newEntry.financeAccountId = accountId;
    let account = financeAccountMap[accountId];
    if (!!account && account.taxSpecificationId) {
      newEntry.taxAccountId = (
        taxSpecificationMap[account.taxSpecificationId] || {}
      ).receivingAccountId;
    }

    newEntry = this.updateVat(newEntry);

    let { autoAccounts } = this.props;

    let { vatErrorEntries } = this.state;

    if (vatErrorEntries.has(entry.id)) {
      this.setState({
        vatErrorEntries: new Set(
          [...vatErrorEntries].filter((id) => id !== entry.id)
        ),
      });
    }

    let response = {};
    if (reload) {
      response = await this.props.actions.updateBookkeepingDraftEntry(newEntry);
    } else {
      response = await ApiService.updateBookkeepingDraftEntry(newEntry);
    }

    if (!!autoAccounts && reload) {
      if (!!newEntry.vatNumber) {
        this.updateLinkAccounts(
          "vatNumber",
          newEntry.id,
          newEntry.vatNumber,
          accountId
        );
      } else if (!!newEntry.description) {
        this.updateLinkAccounts(
          "description",
          newEntry.id,
          newEntry.description,
          accountId
        );
      }
    }

    return response;
  };

  updateLinkAccounts = async (key, currentEntryId, value, accountId) => {
    let { entries, viewType } = this.props;

    let updateEntries = entries.filter(
      (e) => e[key] === value && e.id !== currentEntryId
    );

    if (viewType === ViewType.NORMAL) {
      updateEntries = updateEntries.filter((e) => !e.financeAccountId);
    }

    let promises = [];
    updateEntries.forEach((entry) => {
      console.log("Link update");
      promises.push(
        ApiService.updateBookkeepingDraftEntry({
          ...entry,
          financeAccountId: accountId,
        })
      );
    });

    await Promise.all(promises);
    if (promises.length > 0) {
      DataStore.fetchBookkeepingDraftEntries();
    }
  };

  updateAmountAndVat = (entryId, amount) => {
    let { entries, actions } = this.props;

    let entry = entries.find((e) => e.id === entryId);
    if (!entry) {
      return;
    }

    let newEntry = { ...entry };
    newEntry.amount = amount;
    newEntry = this.updateVat(newEntry);

    let { vatErrorEntries } = this.state;

    if (vatErrorEntries.has(entry.id)) {
      this.setState({
        vatErrorEntries: new Set(
          [...vatErrorEntries].filter((id) => id !== entry.id)
        ),
      });
    }

    actions.updateBookkeepingDraftEntry(newEntry);
  };

  getDefaultPaymentAccount = () => {
    let accountPlan = this.getFinanceAccountPlan();

    let standardAccounts = accountPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    if (accountPlan.customerBankAccountId) {
      let bankAccount = standardAccounts.find(
        (a) => a.id === accountPlan.customerBankAccountId
      );
      if (!!bankAccount) {
        return bankAccount.id;
      }
    }

    let standardPaymentAccounts =
      accountPlan.bankAccountIntervalStart > 0
        ? standardAccounts.filter(
            (a) =>
              a.number >= accountPlan.bankAccountIntervalStart &&
              a.number < accountPlan.bankAccountIntervalEnd
          )
        : [];

    return standardPaymentAccounts.length > 0
      ? standardPaymentAccounts[0].id
      : null;
  };

  getAccountOptions = () => {
    let { showAllAccounts, showAllBalanceAccounts } = this.state;

    let accountPlan = this.getFinanceAccountPlan();
    let standardAccounts = accountPlan.accounts.filter(
      (a) => a.type === "Standard"
    );
    let incomeAccounts = standardAccounts;
    let expenseAccounts = standardAccounts;
    let incomePaymentAccounts = standardAccounts;
    let expensePaymentAccounts = standardAccounts;

    if (!showAllAccounts || !showAllBalanceAccounts) {
      //Only show the most common accounts
      let resultAccounts = standardAccounts.filter(
        (a) =>
          a.number >= accountPlan.resultStart &&
          a.number <= accountPlan.resultEnd
      );
      let balanceAccounts = standardAccounts.filter(
        (a) =>
          a.number >= accountPlan.balanceStart &&
          a.number <= accountPlan.balanceEnd
      );

      if (!showAllAccounts) {
        incomeAccounts = resultAccounts.filter((a) => a.isCredit);
        expenseAccounts = resultAccounts.filter((a) => !a.isCredit);
      }
      if (!showAllBalanceAccounts) {
        expensePaymentAccounts = balanceAccounts.filter(
          (a) =>
            a.number >= accountPlan.bankAccountIntervalStart &&
            a.number <= accountPlan.bankAccountIntervalEnd
        );
        incomePaymentAccounts = expensePaymentAccounts;
        if (
          !!accountPlan.customerReceivablesAccountId &&
          !incomePaymentAccounts.find(
            (a) => a.id === accountPlan.customerReceivablesAccountId
          )
        ) {
          incomePaymentAccounts = [
            ...expensePaymentAccounts,
            balanceAccounts.find(
              (a) => a.id === accountPlan.customerReceivablesAccountId
            ),
          ];
        }
        if (
          !!accountPlan.customerPrivateAccountId &&
          !expensePaymentAccounts.find(
            (a) => a.id === accountPlan.customerPrivateAccountId
          )
        ) {
          expensePaymentAccounts = [
            ...expensePaymentAccounts,
            balanceAccounts.find(
              (a) => a.id === accountPlan.customerPrivateAccountId
            ),
          ];
        }
      }
    }

    return {
      standardAccounts,
      incomeAccounts,
      expenseAccounts,
      incomePaymentAccounts,
      expensePaymentAccounts,
    };
  };

  onSelectReceiptEntry = (selectedEntryId, checked) => {
    this.setState({ selectedEntryId: checked ? selectedEntryId : 0 });
  };

  updateDate = (entry, inputDate) => {
    if (!inputDate) {
      return;
    }

    let newDate = moment.utc(inputDate);
    if (newDate.year() > 9999) {
      return;
    }

    let { invalidDateEntryIds } = this.state;
    if (invalidDateEntryIds.has(entry.id)) {
      this.setState({
        invalidDateEntryIds: new Set(
          [...invalidDateEntryIds].filter((id) => id !== entry.id)
        ),
      });
    }
    // this.onChangePropertyValueDebounced(entry.id, 'creationDate', newDate.format());
    this.onChangePropertyValue(entry.id, "creationDate", newDate.format());
    this.setState((prevState) => ({
      receiptsWhoseDatesCouldNotBeScanned:
        prevState.receiptsWhoseDatesCouldNotBeScanned.filter(
          (id) => id !== entry.receiptId
        ),
    }));
  };

  updateDateFromMoment = (entry, newDate) => {
    let { invalidDateEntryIds } = this.state;
    if (invalidDateEntryIds.has(entry.id)) {
      this.setState({
        invalidDateEntryIds: new Set(
          [...invalidDateEntryIds].filter((id) => id !== entry.id)
        ),
      });
    }
    // this.onChangePropertyValueDebounced(entry.id, 'creationDate', newDate.format());
    this.onChangePropertyValue(entry.id, "creationDate", newDate.format());
    this.setState((prevState) => ({
      receiptsWhoseDatesCouldNotBeScanned:
        prevState.receiptsWhoseDatesCouldNotBeScanned.filter(
          (id) => id !== entry.receiptId
        ),
    }));
  };

  //If main account amounts are balanced, balance accounts are not needed.
  getPrimaryAccountBalance = () => {
    let { entries } = this.props;

    return entries.reduce((acc, entry) => {
      return acc + (entry.isIncome ? entry.amount : -entry.amount);
    }, 0);
  };

  sortByAccount = (l, r) => {
    let { financeAccountMap } = this.props;

    let acc1 = financeAccountMap[l.financeAccountId];
    let acc2 = financeAccountMap[r.financeAccountId];

    return (!!acc1 ? acc1.number : 0) - (!!acc2 ? acc2.number : 0);
  };

  sortByBalanceAccount = (l, r) => {
    let { financeAccountMap } = this.props;

    let acc1 = financeAccountMap[l.balanceFinanceAccountId];
    let acc2 = financeAccountMap[r.balanceFinanceAccountId];

    return (!!acc1 ? acc1.number : 0) - (!!acc2 ? acc2.number : 0);
  };

  filterConfig = {
    matchFrom: "label",
  };

  colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let { financeAccountMap } = this.props;

      let accountPlan = this.getFinanceAccountPlan();
      let account = financeAccountMap[data.value];
      let isBalance =
        !!account &&
        account.number >= accountPlan.balanceStart &&
        account.number <= accountPlan.balanceEnd;

      let background = styles.backgroundColor;
      if (isBalance && !isDisabled && !isFocused && !isSelected) {
        background = "lightblue";
      }
      return {
        ...styles,
        backgroundColor: background,
      };
    },
  };

  // renderTable = (params = { allowSpaceForPicture: false, hasMissingAccount: false, hasMissingAmount: false} ) => {
  renderTable = (
    params = { allowSpaceForPicture: false, hasMissingAccount: false }
  ) => {
    let {
      sortedDataList,
      colSortDirs,
      columnDefs,
      showAllAccounts,
      showAllBalanceAccounts,
      // selectedEntryId,
      receiptsWhoseDatesCouldNotBeScanned,
      creditLabel,
      debitLabel,
      // uploadLabel,
      deleteLabel,
      copyLabel,
      selectAccountLabel,
      // chooseLabel,
      vatErrorEntries,
      // selectedEntryColumn,
      scrollToColumnIndex,
      // creditDebitFilter
      // columnFilters
    } = this.state;

    const {
      containerHeight,
      containerWidth,
      onCopy,
      locale,
      // viewType,
      // userProfile,
      // onlyTable
    } = this.props;

    let tableHeight = containerHeight;
    let tableWidth = Math.max(750, containerWidth - 25);

    if (params.allowSpaceForPicture) {
      tableWidth = (containerWidth - 25) * 0.66;
    }

    let {
      standardAccounts,
      incomeAccounts,
      expenseAccounts,
      incomePaymentAccounts,
      expensePaymentAccounts,
    } = this.getAccountOptions();

    let balanceControl = this.getPrimaryAccountBalance();
    let showBalanceAccountWarnings = Math.abs(balanceControl) > 0.1;

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;

    // let isTrashCan = viewType === ViewType.TRASH;

    // let textOptions = tableEntries
    //   .filter(e => !!e.description)
    //   .map(e => ({ value: e.id, label: e.description }));

    let readonly = this.isReadOnly();
    return (
      <div ref={this.tableContainer}>
        <Table
          rowHeight={40}
          onRowDoubleClick={(event, index) =>
            this.onDisplayEntry(sortedDataList.getObjectAt(index).id)
          }
          rowsCount={sortedDataList.getSize()}
          rowClassNameGetter={this._rowClassNameGetter}
          height={tableHeight}
          width={tableWidth}
          isColumnResizing={false}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          scrollToColumn={scrollToColumnIndex}
          headerHeight={40}
        >
          {!readonly && (
            <Column
              columnKey="select"
              header={
                <Cell className="text-center">
                  <AxoCheckbox
                    checked={
                      selectedEntries.size > 0 &&
                      selectedEntries.size === sortedDataList.getSize()
                    }
                    onChange={this.onSelectAll}
                  />
                </Cell>
              }
              cell={(props) => {
                var entry = sortedDataList.getObjectAt(props.rowIndex);
                return (
                  <Cell key={entry.id} className="text-center" {...props}>
                    <AxoCheckbox
                      checked={selectedEntries.has(entry.id)}
                      onChange={this.onSelectEntry.bind(this, entry.id)}
                    />
                  </Cell>
                );
              }}
              width={columnDefs.select.width}
              isResizable={true}
            />
          )}
          <Column
            columnKey="receiptNumber"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.receiptNumber}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;#
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="text-center">
                  {readonly ? (
                    <div>#{entry.receiptNumber}</div>
                  ) : (
                    <InlineNumberEdit
                      value={entry.receiptNumber || "---"}
                      format={(value) => {
                        return <span>#{value}</span>;
                      }}
                      change={(input) =>
                        this.onChangePropertyValue(
                          entry.id,
                          "receiptNumber",
                          parseInt(input.value, 10)
                        )
                      }
                      onEdit={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onFinish={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.receiptNumber.width}
            isResizable={true}
          />
          <Column
            columnKey="isIncome"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.isIncome}
              >
                <Flexbox alignCenter>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;Type
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>
                      {entry.isIncome ? (
                        <span>{creditLabel}</span>
                      ) : (
                        <span>{debitLabel}</span>
                      )}
                    </div>
                  ) : (
                    <div>
                      <select
                        value={entry.isIncome ? "credit" : "debit"}
                        onChange={(event) =>
                          this.onChangePropertyValue(
                            entry.id,
                            "isIncome",
                            event.target.value === "credit" ? true : false
                          )
                        }
                      >
                        <option value="credit">{creditLabel}</option>
                        <option value="debit">{debitLabel}</option>
                      </select>
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.isIncome.width}
            isResizable={true}
          />
          <Column
            columnKey="description"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.description}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal entity="axoidcode77" defaultValue={"Fritekst"} />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="axoTableText">
                  <InlineEdit
                    value={entry.description || "---"}
                    change={(input) =>
                      this.onChangeDescription(entry.id, input.value)
                    }
                  />
                </Cell>
              );
            }}
            width={columnDefs.description.width}
            isResizable={true}
          />
          <Column
            columnKey="creationDate"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.creationDate}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="TimeEntryFormntimeEntry"
                      defaultValue={"Dato"}
                    />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let showScanwarning =
                receiptsWhoseDatesCouldNotBeScanned.includes(entry.receiptId);

              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>{moment.utc(entry.creationDate).format("L")}</div>
                  ) : (
                    <div
                      style={{
                        border: showScanwarning ? "1px solid red" : "none",
                      }}
                    >
                      <AxoDateTimeEdit
                        locale={locale}
                        date={moment.utc(entry.creationDate)}
                        onChange={(newMoment) =>
                          this.updateDateFromMoment(entry, newMoment)
                        }
                      />
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.creationDate.width}
            isResizable={true}
          />
          <Column
            columnKey="account"
            header={
              <SortHeaderCell
                key={showAllAccounts.toString()}
                onSortChange={(columnKey, sortDir) =>
                  this._onSortChange(columnKey, sortDir, this.sortByAccount)
                }
                sortDir={colSortDirs.account}
              >
                <Flexbox>
                  <FlexElement flexGrow={1}>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="AccountingTabViewEntity13"
                      defaultValue={"Konto"}
                    />
                  </FlexElement>
                  <FlexElement
                    className="almfontcolor"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <input
                      id="accountCheck"
                      type="checkbox"
                      checked={showAllAccounts}
                      onChange={(event) =>
                        this.setState({ showAllAccounts: event.target.checked })
                      }
                    />
                    <label htmlFor="accountCheck" style={{ margin: "0px" }}>
                      &nbsp;
                      <AxoLocal
                        entity="axoidcode124"
                        defaultValue={"Vis alle"}
                      />
                    </label>
                    &nbsp;
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let account = standardAccounts.find(
                (a) => a.id === entry.financeAccountId
              );
              let accountOptions = entry.isIncome
                ? incomeAccounts
                : expenseAccounts;

              //Ensure current account remains an option
              if (
                !!account &&
                !accountOptions.find((a) => a.id === account.id)
              ) {
                accountOptions = [...accountOptions, account];
              }

              let selectOptions = accountOptions.map((f) => {
                return {
                  value: f.id,
                  label: f.number.toString() + " - " + f.name,
                };
              });

              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>
                      {!!account
                        ? account.number.toString() + " - " + account.name
                        : ""}
                    </div>
                  ) : (
                    <AxoSelect
                      name="select"
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      closeMenuOnScroll
                      styles={this.colourStyles}
                      // menuPlacement='top'
                      className={!entry.financeAccountId ? "selectBorder" : ""}
                      value={
                        entry.financeAccountId
                          ? selectOptions.find(
                              (o) => o.value === entry.financeAccountId
                            )
                          : { value: "", label: selectAccountLabel }
                      }
                      onChange={async (selectedAccount) => {
                        if (!!selectedAccount) {
                          this.updateMainAccountAndVat(
                            entry,
                            selectedAccount.value
                          );
                        }
                      }}
                      noOptionsMessage={() => ""}
                      options={[
                        { value: "", label: selectAccountLabel },
                        ...selectOptions,
                      ]}
                      onFocus={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onBlur={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.account.width}
            isResizable={true}
          />
          <Column
            columnKey="amount"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.amount}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal entity="InvoiceInvoicesum" defaultValue="Beløb" />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props} className="text-right">
                  {readonly ? (
                    <div>{entry.amount}</div>
                  ) : (
                    <InlineNumberEdit
                      decimal
                      value={entry.amount || 0}
                      change={(input) =>
                        this.updateAmountAndVat(
                          entry.id,
                          parseFloat(input.value)
                        )
                      }
                      onEdit={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onFinish={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.amount.width}
            isResizable={true}
          />
          <Column
            columnKey="vat"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.vat}
              >
                <Flexbox>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="invoiPaymentattheSubtotalVAT"
                      defaultValue={"Moms"}
                    />
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let isReverseVat = this.isReverseVat(entry);

              return (
                <Cell key={entry.id} {...props} className="text-right">
                  <>
                    {isReverseVat && <>((</>}
                    {readonly ? (
                      <div>{entry.vat}</div>
                    ) : (
                      <InlineNumberEdit
                        decimal
                        className={
                          !!entry.taxAccountId && !entry.vat
                            ? "selectBorder"
                            : ""
                        }
                        value={entry.vat || 0}
                        change={(input) => {
                          this.onChangePropertyValue(
                            entry.id,
                            "vat",
                            parseFloat(input.value)
                          );
                          if (vatErrorEntries.has(entry.id)) {
                            this.setState({
                              vatErrorEntries: new Set(
                                [...vatErrorEntries].filter(
                                  (id) => id !== entry.id
                                )
                              ),
                            });
                          }
                        }}
                        onEdit={() =>
                          this.setState({
                            scrollToColumnIndex:
                              columnDefs[props.columnKey].index,
                          })
                        }
                        onFinish={() =>
                          this.setState({ scrollToColumnIndex: null })
                        }
                      />
                    )}
                    {isReverseVat && <>))</>}
                  </>
                </Cell>
              );
            }}
            width={columnDefs.vat.width}
            isResizable={true}
          />
          <Column
            columnKey="balanceAccount"
            header={
              <SortHeaderCell
                key={showAllBalanceAccounts.toString()}
                onSortChange={(columnKey, sortDir) =>
                  this._onSortChange(
                    columnKey,
                    sortDir,
                    this.sortByBalanceAccount
                  )
                }
                sortDir={colSortDirs.balanceAccount}
              >
                <Flexbox>
                  <FlexElement flexGrow={1}>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                    <AxoLocal
                      entity="updategetAccountName1"
                      defaultValue={"Modkonto"}
                    />
                  </FlexElement>
                  <FlexElement
                    className="almfontcolor"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <input
                      id="balanceAccountCheck"
                      type="checkbox"
                      checked={showAllBalanceAccounts}
                      onChange={(event) => {
                        event.stopPropagation();
                        this.setState({
                          showAllBalanceAccounts: event.target.checked,
                        });
                      }}
                    />
                    <label
                      htmlFor="balanceAccountCheck"
                      style={{ margin: "0px" }}
                    >
                      &nbsp;
                      <AxoLocal
                        entity="axoidcode124"
                        defaultValue={"Vis alle"}
                      />
                    </label>
                    &nbsp;
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              let account = standardAccounts.find(
                (a) => a.id === entry.balanceFinanceAccountId
              );
              let accountOptions = entry.isIncome
                ? incomePaymentAccounts
                : expensePaymentAccounts;

              //Ensure current account remains an option
              if (
                !!account &&
                !accountOptions.find((a) => a.id === account.id)
              ) {
                accountOptions = [...accountOptions, account];
              }

              let selectOptions = accountOptions
                .filter((a) => a.id !== entry.financeAccountId)
                .map((f) => {
                  return {
                    value: f.id,
                    label: f.number.toString() + " - " + f.name,
                  };
                });

              return (
                <Cell key={entry.id} {...props}>
                  {readonly ? (
                    <div>
                      {!!account
                        ? account.number.toString() + " - " + account.name
                        : ""}
                    </div>
                  ) : (
                    <AxoSelect
                      name="select"
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      styles={this.colourStyles}
                      // menuPlacement='top'
                      className={
                        !entry.balanceFinanceAccountId &&
                        showBalanceAccountWarnings
                          ? "selectBorder"
                          : ""
                      }
                      value={
                        !!entry.balanceFinanceAccountId
                          ? selectOptions.find(
                              (o) => o.value === entry.balanceFinanceAccountId
                            )
                          : { value: "", label: selectAccountLabel }
                      }
                      onChange={async (selectedAccount) => {
                        if (!!selectedAccount) {
                          this.onChangePropertyValue(
                            entry.id,
                            "balanceFinanceAccountId",
                            selectedAccount.value
                          );

                          //Set balance account on all selected entries
                          if (selectedEntries.size > 0) {
                            let promises = [];
                            [...selectedEntries]
                              .filter((id) => id !== entry.id)
                              .forEach((id) =>
                                promises.push(
                                  this.onChangePropertyValue(
                                    id,
                                    "balanceFinanceAccountId",
                                    selectedAccount.value,
                                    false
                                  )
                                )
                              );
                            await Promise.all(promises);
                            DataStore.fetchBookkeepingDraftEntries();
                          }
                        }
                      }}
                      noOptionsMessage={() => ""}
                      options={[
                        { value: "", label: selectAccountLabel },
                        ...selectOptions,
                      ]}
                      onFocus={() =>
                        this.setState({
                          scrollToColumnIndex:
                            columnDefs[props.columnKey].index,
                        })
                      }
                      onBlur={() =>
                        this.setState({ scrollToColumnIndex: null })
                      }
                    />
                  )}
                </Cell>
              );
            }}
            width={columnDefs.balanceAccount.width}
            isResizable={true}
          />
          <Column
            columnKey="receipt"
            header={
              <SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={colSortDirs.receipt}
              >
                <Flexbox alignCenter>
                  <FlexElement>
                    <Icon
                      className="editable"
                      glyph="icon-fontello-arrow-combo"
                    />
                    &nbsp;
                  </FlexElement>
                </Flexbox>
              </SortHeaderCell>
            }
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              return (
                <Cell key={entry.id} {...props}>
                  {!!entry.receipt ? (
                    <div className="inlineBlock">
                      {readonly ? (
                        <>
                          <div className="inlineBlock">
                            #{entry.receiptNumber}
                          </div>
                          &nbsp;
                          <Icon
                            className="editable"
                            role="button"
                            onClick={() => this.onShowReceiptForEntry(entry.id)}
                            glyph="icon-fontello-attach-7"
                          />
                        </>
                      ) : (
                        <>
                          <InlineNumberEdit
                            value={entry.receiptNumber || "---"}
                            format={(value) => {
                              return <span>#{value}</span>;
                            }}
                            change={(input) =>
                              this.onChangePropertyValue(
                                entry.id,
                                "receiptNumber",
                                input.value
                              )
                            }
                            onEdit={() =>
                              this.setState({
                                scrollToColumnIndex:
                                  columnDefs[props.columnKey].index,
                              })
                            }
                            onFinish={() =>
                              this.setState({ scrollToColumnIndex: null })
                            }
                          />
                          &nbsp;
                          <Icon
                            className="editable"
                            role="button"
                            onClick={() => this.onShowReceiptForEntry(entry.id)}
                            glyph="icon-fontello-attach-7"
                          />
                          &nbsp;
                          <Icon
                            title={getText("axoidcode9", "Fjern bilagsfil")}
                            className="editable"
                            role="button"
                            onClick={() => this.onDeleteReceiptForEntry(entry)}
                            glyph="icon-fontello-trash-1"
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {!readonly && (
                        <div className="inlineBlock">
                          &nbsp;
                          <Icon
                            title="Upload bilagsfil"
                            className="editable"
                            role="button"
                            onClick={() =>
                              this.onUploadReceiptForExistingEntry(entry.id)
                            }
                            glyph="icon-fontello-upload-4"
                          />
                        </div>
                      )}
                    </>
                  )}
                  {!!entry.invoiceId && (
                    <div className="inlineBlock">
                      &nbsp;
                      <Icon
                        title={getText("axoEntityidcode28", "Gå til faktura")}
                        className="editable"
                        role="button"
                        onClick={() =>
                          this.props.history.push(
                            RoutingService.getPath(
                              "Invoices/Single/" + entry.invoiceId
                            )
                          )
                        }
                        glyph="icon-simple-line-icons-user-following"
                      />
                    </div>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.receipt.width}
            isResizable={true}
          />
          <Column
            columnKey="actions"
            header={<Cell></Cell>}
            cell={(props) => {
              let entry = sortedDataList.getObjectAt(props.rowIndex);
              // let confirmDeleting = this.state.confirmDeleteEntryId === entry.id;
              let deleting = this.state.deletingId === entry.id;
              return (
                <Cell key={entry.id} {...props}>
                  {!readonly ? (
                    <>
                      &nbsp;
                      <Icon
                        title={deleteLabel}
                        className="editable"
                        role="button"
                        onClick={() => this.onDeleteEntry(entry)}
                        glyph="icon-fontello-trash-1"
                      />
                      <LoadingIcon show={deleting} />
                      &nbsp;&nbsp;&nbsp;
                      <Icon
                        title={copyLabel}
                        className="editable"
                        role="button"
                        onClick={() => onCopy(entry)}
                        glyph="icon-fontello-docs-1"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Cell>
              );
            }}
            width={columnDefs.actions.width}
            isResizable={true}
          />
        </Table>
      </div>
    );
  };

  getButtonLabel = (defaultLabel, uploading, scanning) => {
    if (uploading) {
      return (
        <span>
          <AxoLocal
            key="upload"
            entity="updategetAccountName3"
            defaultValue={"Uploader"}
          />{" "}
        </span>
      );
    }
    if (scanning) {
      let { documentsScanned, documentsToBeScanned } = this.state;

      return (
        <span>
          <AxoLocal
            key="scan"
            entity="updategetAccountName4"
            defaultValue={"Scanner"}
          />
          &nbsp;({documentsScanned + 1} / {documentsToBeScanned})
        </span>
      );
    }
    return <span>{defaultLabel}</span>;
  };

  getFileErrorMessage = () => {
    let { showFileMaxSizeWarning, showStorageWarning, showFileUploadError } =
      this.state;
    if (showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    if (showStorageWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarningA"
            defaultValue={"Ikke mere plads. Slet filer."}
          />
        </div>
      );
    }
    if (showFileUploadError) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesError"
            defaultValue={"Upload fejlede"}
          />
        </div>
      );
    }
    return <span></span>;
  };

  onShowAllAccounts = (event) => {
    this.setState({ showAllAccounts: event.target.checked });
  };

  onShowAllBalanceAccounts = (event) => {
    this.setState({ showAllBalanceAccounts: event.target.checked });
  };

  renderGridView = () => {
    let {
      sortedDataList,
      receiptsWhoseDatesCouldNotBeScanned,
      showAllAccounts,
      showAllBalanceAccounts,
      addingPageToId,
      addedPageToId,
    } = this.state;

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;

    let readonly = this.isReadOnly();

    return (
      <div style={{ margin: "auto" }}>
        <BookkeepingGridView
          entries={sortedDataList._data}
          readonly={readonly}
          {...this.getAccountOptions()}
          onDeleteEntry={this.onDeleteEntry}
          onShowReceipt={this.onShowReceiptForEntry}
          onUploadReceiptForExistingEntry={this.onUploadReceiptForExistingEntry}
          onChangePropertyValue={this.onChangePropertyValue}
          updateMainAccountAndVat={this.updateMainAccountAndVat}
          selectedEntries={selectedEntries}
          onSelectEntry={this.onSelectEntry}
          receiptsWhoseDatesCouldNotBeScanned={
            receiptsWhoseDatesCouldNotBeScanned
          }
          updateDate={this.updateDate}
          showAllAccounts={showAllAccounts}
          showAllBalanceAccounts={showAllBalanceAccounts}
          onShowAllAccounts={this.onShowAllAccounts}
          onShowAllBalanceAccounts={this.onShowAllBalanceAccounts}
          updateAmountAndVat={this.updateAmountAndVat}
          onAddImagePage={this.onAddImagePage}
          addingPageToId={addingPageToId}
          addedPageToId={addedPageToId}
        />
      </div>
    );
  };

  onUploadCSV() {
    $("#csvUpload").trigger("click");
  }

  onAddEntry = async (isCredit) => {
    let { selectedContact } = this.props;
    let { searchText } = this.state;

    let newCreationDate = this.getNewCreationDate();

    let response = await this.createNewEntry({
      contactInfoId: selectedContact.id,
      description: searchText,
      balanceFinanceAccountId: this.getDefaultPaymentAccount(),
      isIncome: isCredit,
      creationDate: newCreationDate,
    });

    return response.ok;
  };

  createNewEntry = async (entry) => {
    let { viewType, actions } = this.props;

    if (viewType === ViewType.NORMAL) {
      return actions.createBookkeepingDraftEntry({
        ...entry,
        status: "Approved",
      });
    }
    return actions.createBookkeepingDraftEntry(entry);
  };

  onPrintReceipts = () => {
    this.receiptsModal.open();
  };

  onFinalizeDrafts = async () => {
    let validFiscalYears = await this.testFiscalYearForEntries();
    if (!validFiscalYears) {
      return false;
    }

    let validBalance = await this.testSimulationBalance();
    if (!validBalance) {
      return false;
    }

    let validTaxes = await this.testVatAccounts();
    if (!validTaxes) {
      return false;
    }

    let { selectedStartDate, selectedEndDate, selectedFiscalYear } = this.props;

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    this.setState({
      confirmPosting: true,
      finalizationStartDate: startDateSelection,
      finalizationEndDate: endDateSelection,
    });

    return true;
  };

  testFiscalYearForEntries = () => {
    let { entries, fiscalYears } = this.props;

    let invalidDateEntries = entries.filter((e) => {
      let entryDate = moment.utc(e.creationDate).startOf("day");
      return !fiscalYears.find((f) => {
        //Fiscal year dates are utc, but we pretend they are local dates to avoid time zone problems.
        var fStart = moment
          .utc(moment.utc(f.startDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");
        var fEnd = moment
          .utc(moment.utc(f.endDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");

        return !entryDate.isBefore(fStart) && !entryDate.isAfter(fEnd);
      });
    });
    //All entries must fit into existing fiscal years
    if (invalidDateEntries.length > 0) {
      this.showWarning("showInvalidDatesWarning");
      this.setState({
        invalidDateEntryIds: new Set(invalidDateEntries.map((e) => e.id)),
      });
      return false;
    }
    let lockedDateEntries = entries.filter((e) => {
      let entryDate = moment.utc(e.creationDate).startOf("day");

      return fiscalYears.find((f) => {
        //Fiscal year dates are utc, but we pretend they are local dates to avoid time zone problems.
        var fStart = moment
          .utc(moment.utc(f.startDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");
        var fEnd = moment
          .utc(moment.utc(f.endDate).format("DD-MM-YYYY"), "DD-MM-YYYY")
          .startOf("day");

        return (
          !entryDate.isBefore(fStart) && !entryDate.isAfter(fEnd) && f.locked
        );
      });
    });
    // Entries must only use active fiscal years
    if (lockedDateEntries.length > 0) {
      this.showWarning("showLockedFiscalYearsWarning");
      this.setState({
        invalidDateEntryIds: new Set(lockedDateEntries.map((e) => e.id)),
      });
      return false;
    }

    return true;
  };

  testSimulationBalance = async () => {
    try {
      let selectedEntries =
        this.props.selectedEntries || this.state.selectedEntries;

      let simulationResult = await DataStore.fetchDraftSimulationWithoutSaving([
        ...selectedEntries,
      ]);

      if (simulationResult.balance !== 0) {
        this.showWarning("showImbalanceWarning");
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  testVatAccounts = async () => {
    let { entries, financeAccountMap } = this.props;

    let vatErrorEntries = entries.filter((e) => {
      if (!e.vat) {
        return false;
      }
      if (!e.financeAccountId) {
        return true;
      }

      let account = financeAccountMap[e.financeAccountId];
      if (!account.taxSpecificationId) {
        return true;
      }

      return false;
    });

    if (vatErrorEntries.length > 0) {
      this.setState({
        vatErrorEntries: new Set(vatErrorEntries.map((e) => e.id)),
      });
    }
    return vatErrorEntries.length === 0;
  };

  doFinalizeDrafts = async () => {
    let {
      // entries,
      selectedContact,
      actions,
      allEntries,
      calculateNumbers,
    } = this.props;

    let { finalizationStartDate, finalizationEndDate } = this.state;

    if (calculateNumbers) {
      let updateNumbersCompleted = await this.updateReceiptNumbers();
      if (!updateNumbersCompleted) {
        return false;
      }
    }

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;
    let entryIds = [];
    if (selectedEntries.size > 0) {
      entryIds = [...selectedEntries];
    } else {
      // entryIds = entries.map(e => e.id);
      entryIds = allEntries
        .filter((e) => !e.trashed)
        .filter(
          (e) =>
            moment
              .utc(e.creationDate)
              .startOf("day")
              .isSameOrAfter(finalizationStartDate.startOf("day")) &&
            moment
              .utc(e.creationDate)
              .startOf("day")
              .isSameOrBefore(finalizationEndDate.startOf("day"))
        )
        .map((e) => e.id);
    }

    let response = await actions.finalizeDraftEntries(
      entryIds,
      selectedContact.id
    );
    if (!response.ok) {
      return false;
    }

    this.setState({ confirmPosting: false });
    DataStore.setState({
      importantEntryIds: new Set(),
    });

    DataStore.updateBankProgress({
      [selectedContact.id]: 0,
    });

    return true;
  };

  updateReceiptNumbers = async () => {
    let { entries, selectedContact } = this.props;

    if (entries.length === 0) {
      return false;
    }

    let sortedEntries = entries.sort((l, r) => {
      if (l.creationDate === r.creationDate) {
        return 0;
      }
      return moment.utc(l.creationDate).isBefore(moment.utc(r.creationDate))
        ? -1
        : 1;
    });

    return ApiService.getNextReceiptNumber({
      contactId: selectedContact.id,
      date: moment.utc(sortedEntries[0].creationDate).format(),
    })
      .then((nextNumber) => {
        if (!nextNumber) {
          return;
        }
        nextNumber = parseInt(nextNumber, 10);
        let promises = [];
        sortedEntries.forEach((entry) => {
          if (nextNumber === entry.receiptNumber) {
            nextNumber++;
            return;
          }
          promises.push(
            ApiService.updateBookkeepingDraftEntry({
              ...entry,
              receiptNumber: nextNumber,
            })
          );
          nextNumber++;
        });

        return Promise.all(promises);
      })
      .then(async (results) => {
        await DataStore.fetchBookkeepingDraftEntries();

        return !results.find((r) => !r.ok);
      })
      .catch((reason) => {
        console.error(reason);
        return false;
      });
  };

  disableAddition = () => {
    let { fiscalYears, selectedContact } = this.props;

    let { uploadingIncome, scanningIncome, uploadingExpense, scanningExpense } =
      this.state;

    return (
      !selectedContact.id ||
      uploadingIncome ||
      scanningIncome ||
      uploadingExpense ||
      scanningExpense ||
      !fiscalYears ||
      fiscalYears.length === 0
    );
  };

  onDeleteSelectedEntries = async () => {
    let { viewType } = this.props;

    if (viewType !== ViewType.TRASH) {
      let result = await this.doDeleteSelectedEntries();
      console.log(result);
      return result;
    }

    this.setState({ confirmDeleteSelected: true });

    return true;
  };

  doDeleteSelectedEntries = async () => {
    let { selectedEntries, tableEntries } = this.state;

    if (selectedEntries.size === 0) {
      return false;
    }

    let selectedEntryObjects = tableEntries.filter((e) =>
      selectedEntries.has(e.id)
    );
    let promises = selectedEntryObjects.map((entry) =>
      this.doDeleteEntry(entry, false)
    );

    let responses = await Promise.all(promises);

    this.setState({
      confirmDeleteSelected: false,
      selectedEntries: new Set(),
    });

    await DataStore.fetchBookkeepingDraftEntries();

    console.log(responses);
    return !responses.find((r) => !r.ok);
  };

  onApproveSelectedEntries = async () => {
    let { selectedEntries, tableEntries } = this.state;

    if (selectedEntries.size === 0) {
      return false;
    }

    let selectedEntryObjects = tableEntries.filter((e) =>
      selectedEntries.has(e.id)
    );
    let promises = selectedEntryObjects.map((entry) =>
      ApiService.updateBookkeepingDraftEntry({
        ...entry,
        status: "Approved",
      })
    );

    let responses = await Promise.all(promises);

    DataStore.fetchBookkeepingDraftEntries();

    this.setState({ selectedEntries: new Set() });

    return !responses.find((r) => !r.ok);
  };

  onRestoreSelectedeEntries = async () => {
    let { selectedEntries, tableEntries } = this.state;

    if (selectedEntries.size === 0) {
      return false;
    }

    let selectedEntryObjects = tableEntries.filter((e) =>
      selectedEntries.has(e.id)
    );

    let promises = selectedEntryObjects.map((entry) =>
      ApiService.updateBookkeepingDraftEntry({ ...entry, trashed: false })
    );

    let responses = await Promise.all(promises);

    DataStore.fetchBookkeepingDraftEntries();

    this.setState({ selectedEntries: new Set() });

    return !responses.find((r) => !r.ok);
  };

  _rowClassNameGetter = (rowIndex) => {
    let { highlightEntryId } = this.props;

    let {
      sortedDataList,
      vatErrorEntries,
      invalidDateEntryIds,
      displayedEntryId,
    } = this.state;

    let entry = sortedDataList.getObjectAt(rowIndex);
    if (!entry) {
      return;
    }

    if (displayedEntryId === entry.id) {
      return "highlight-row";
    }
    if (highlightEntryId === entry.id) {
      return "highlight-row";
    } else if (vatErrorEntries.has(entry.id)) {
      return "highlight-row-rejected";
    } else if (invalidDateEntryIds.has(entry.id)) {
      return "highlight-row-rejected";
    }
  };

  onAddImagePage = async (entryId) => {
    this.selectedEntryId = entryId;
    this.addPagesControl.current.click();
  };

  onPageSelected = async (event) => {
    let control = event.target;
    try {
      let entry = this.props.entries.find((e) => e.id === this.selectedEntryId);
      if (!entry) {
        return;
      }

      let files = control.files;
      if (files.length === 0) {
        return;
      }

      let file = files[0];
      if (file.size / Math.pow(1024, 2) > 50) {
        this.showWarning("showFileMaxSizeWarning");
        return;
      }

      let { selectedContact } = this.props;

      this.setState({ addingPageToId: this.selectedEntryId });

      let uploadResponse = await DataActions.addImagesToExisting(files, {
        fileId: entry.receiptId,
        clientId: selectedContact.id,
      });
      this.setState({ addingPageToId: 0 });
      if (!uploadResponse.ok) {
        return this.displayResponseWarnings(uploadResponse);
      }
      this.setState({ addedPageToId: this.selectedEntryId });
      setTimeout(() => this.setState({ addedPageToId: 0 }), 3000);

      return true;
    } finally {
      this.selectedEntryId = null;
      control.value = ""; //onChange handler should be triggered when uploading the same file twice.
    }
  };

  onDeleteReceiptForEntry = (entry) => {
    ModalService.openConfirmModal(
      <>
        <AxoLocal
          entity="axoEntityidcode26"
          defaultValue={"Vil du slette bilagsfilen permanent?"}
        />
      </>,
      async (value) => {
        if (!value) {
          return;
        }
        let receiptId = entry.receiptId;
        let response = await DataActions.updateBookkeepingDraftEntry({
          ...entry,
          receiptId: null,
          receipt: null,
        });
        if (!response.ok) {
          return;
        }
        DataActions.deleteDocument(receiptId);
      }
    );
  };

  onSplitFiles = async (entriesWithPDFS) => {
    let promises = [];
    entriesWithPDFS.forEach(async (entry) => {
      promises.push(this.onSplitEntry(entry));
    });

    let responses = await Promise.all(promises);

    this.setState({ selectedEntries: new Set() });

    DataStore.fetchBookkeepingDraftEntries();

    if (responses.find((response) => response.status === 415)) {
      ModalService.openAlertModal(
        "Én eller flere PDF filer er ugyldige eller korrumperede."
      );
    }

    return !responses.find((response) => !response.ok);
  };

  onSplitEntry = async (entry) => {
    let response = await ApiService.splitDocument(entry.receipt.id);
    if (!response.ok) {
      return response;
    }
    let newFiles = await response.json();
    for (var i = 0; i < newFiles.length; i++) {
      //Process entries sequentially
      let file = newFiles[i];
      let { id, receiptNumber, ...newEntry } = entry;
      if (i === 0) {
        newEntry.receiptNumber = entry.receiptNumber;
      }

      let entryResponse = await ApiService.createBookkeepingDraftEntry({
        ...newEntry,
        receiptId: file.id,
        receipt: file,
      });

      if (!entryResponse.ok) {
        return entryResponse;
      }
    }

    let final = await ApiService.deleteBookkeepingDraftEntryAndReceipt(
      entry.id
    );
    return final;
  };

  onMergeEntries = async (entriesWithPDFs) => {
    if (!entriesWithPDFs || entriesWithPDFs.length < 2) {
      return;
    }

    let response = await ApiService.mergeDocuments(
      entriesWithPDFs.map((e) => e.receiptId)
    );
    if (!response.ok) {
      if (response.status === 415) {
        ModalService.openAlertModal(
          "Én eller flere PDF filer er ugyldige eller korrumperede."
        );
      }
      return false;
    }

    let newFile = await response.json();
    let firstOriginalEntry = entriesWithPDFs[0];
    let { id, receiptNumber, ...newEntry } = firstOriginalEntry;
    let entryResponse = await ApiService.createBookkeepingDraftEntry({
      ...newEntry,
      receiptNumber: Math.min(...entriesWithPDFs.map((e) => e.receiptNumber)),
      receiptId: newFile.id,
      receipt: newFile,
    });

    if (!entryResponse.ok) {
      return false;
    }

    for (let entry of entriesWithPDFs) {
      //Process entries sequentially
      await ApiService.deleteBookkeepingDraftEntryAndReceipt(entry.id);
    }

    this.setState({ selectedEntries: new Set() });

    DataStore.fetchBookkeepingDraftEntries();

    return true;
  };

  isReadOnly = () => {
    let { sharedClient, selectedContact, userProfile } = this.props;

    return (
      sharedClient &&
      (!selectedContact.editor || selectedContact.editor.id !== userProfile.id)
    );
  };

  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  onDownloadAll = async () => {
    let store = DataStore.getStore();

    let all = store.bookKeepingDraftEntries
      .concat(store.postingEntries)
      .filter((e) => !!e.receiptId);

    let link =
      window.location.protocol +
      "//" +
      window.location.host +
      "/documents/download";
    for (let i = 0; i < all.length; i++) {
      window.open(link + "?id=" + all[i].receiptId);
      await this.timeout(500);
    }
  };

  render() {
    let {
      entries,
      forceShowCards,
      contactMap,
      handleSelectedClient,
      fiscalYears,
      selectedContact,
      onCSVChange,
      selectedStartDate,
      selectedEndDate,
      onSelectFiscalYear,
      onSelectStartDate,
      onSelectEndDate,
      selectedFiscalYear,
      onlyTable,
      fixedClient,
      viewType,
    } = this.props;

    let {
      sortedDataList,
      uploadingIncome,
      scanningIncome,
      uploadingExpense,
      scanningExpense,
      confirmPosting,
      showInvalidDatesWarning,
      showLockedFiscalYearsWarning,
      showImbalanceWarning,
      vatErrorEntries,
      multiPageUpload,
      cachedPageFiles,
      showMissingCurrentFiscalYearError,
      displayedEntryId,
      documentsToBeUploaded,
      documentsUploaded,
      confirmDeleteEntryId,
      deletingId,
      confirmDeleteSelected,
      confirmBankBalancing,
      searchText,
    } = this.state;

    let selectedEntries =
      this.props.selectedEntries || this.state.selectedEntries;

    let selectedEntryWithReceipt = entries.find(
      (e) => !!e.receipt && displayedEntryId === e.id
    );

    let invalidEntry = entries.find((entry) => !entry.financeAccountId);
    let hasMissingAccount = !!invalidEntry && !invalidEntry.financeAccountId;

    if (onlyTable) {
      return (
        <>
          {!!selectedEntryWithReceipt ? (
            <Flexbox>
              <FlexElement className="rightPadding">
                {/* {this.renderTable({ allowSpaceForPicture: true, hasMissingAccount, hasMissingAmount })} */}
                {this.renderTable({
                  allowSpaceForPicture: true,
                  hasMissingAccount,
                })}
              </FlexElement>
              <FlexElement flexGrow={1}>
                <FileViewer file={selectedEntryWithReceipt.receipt} />
              </FlexElement>
            </Flexbox>
          ) : (
            this.renderTable()
          )}
          <FileViewerModal ref={(c) => (this.fileModal = c)} />
          <TextFilterModal ref={this.textFilterModal} />
          <NumberFilterModal ref={this.numberFilterModal} />
          <DateFilterModal ref={this.dateFilterModal} />
          <input
            name="receipt"
            onChange={this.onReceiptSelected}
            type="file"
            accept="image/*,.pdf"
            id="receiptUpload"
            style={{ display: "none" }}
          />
        </>
      );
    }

    let isTrashCan = viewType === ViewType.TRASH;
    let isIncoming = viewType === ViewType.SCAN;
    let isNormal = viewType === ViewType.NORMAL;

    let startDateSelection =
      selectedStartDate || moment.utc(selectedFiscalYear.startDate);
    let endDateSelection =
      selectedEndDate || moment.utc(selectedFiscalYear.endDate);

    let selectedContactString =
      ContactService.getContactFullName(selectedContact);

    let readonly = this.isReadOnly();

    return (
      <AxoDropzone
        onDrop={this.handleFileDrop}
        className="leftPadding rightPadding"
      >
        <Flexbox
          responsive
          collapseXS
          style={{
            maxwidth: "990px",
            margin: "auto",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          {!fixedClient && (
            <FlexElement className="onToppest rightPadding">
              <AxoLocal
                componentClass={ClientSearchBox}
                startValue={ContactService.getContactAccountingName(
                  selectedContact
                )}
                clients={contactMap.contacts}
                count={contactMap.count}
                handleSelectedClient={handleSelectedClient}
                // clearOnSelect
                disabled={false}
                componentAttribute="placeholder"
                entity="composeSelectClientPlaceholder1"
              />
            </FlexElement>
          )}
          {selectedContact.id && !!fiscalYears ? (
            <React.Fragment>
              <FlexElement flexBasis="150px" className="onTop rightPadding">
                <AxoSelect
                  name="fiscalYear"
                  menuPlacement="auto"
                  value={{
                    value: selectedFiscalYear.id.toString(),
                    label: selectedFiscalYear.name,
                  }}
                  onChange={(selection, arg) => {
                    onSelectFiscalYear(
                      !!selection && selection.value !== "0"
                        ? parseInt(selection.value, 10)
                        : null
                    );
                  }}
                  noOptionsMessage={() => ""}
                  options={[
                    {
                      value: "0",
                      label: getText("axoidcode100", "Vælg regnskabsår"),
                    },
                    ...fiscalYears.map((year) => {
                      return { value: year.id.toString(), label: year.name };
                    }),
                  ]}
                />
              </FlexElement>
              <FlexElement className="rightPadding">
                <div style={{ maxWidth: "125px" }}>
                  <AxoDateTime
                    utc
                    value={startDateSelection}
                    dateFormat={true}
                    timeFormat={false}
                    onChange={onSelectStartDate}
                    isValidDate={(currentDate) =>
                      !currentDate.isAfter(endDateSelection)
                    }
                  />
                </div>
              </FlexElement>
              <FlexElement className="rightPadding">
                <div style={{ maxWidth: "125px" }}>
                  <AxoDateTime
                    utc
                    value={endDateSelection}
                    dateFormat={true}
                    timeFormat={false}
                    onChange={onSelectEndDate}
                    isValidDate={(currentDate) =>
                      !currentDate.isBefore(startDateSelection)
                    }
                  />
                </div>
              </FlexElement>
              <FlexElement className="rightPadding">
                <div style={{ minWidth: "200px", maxWidth: "600px" }}>
                  <FormGroup controlId="inputWithIcon">
                    <InputGroup>
                      <AxoLocal
                        componentClass={FormControl}
                        type="text"
                        value={searchText}
                        onChange={this.onSearch}
                        componentAttribute="placeholder"
                        entity="ContactTableViewplaceholderonSearch"
                      />
                      <FormControl.Feedback>
                        <Icon glyph="icon-fontello-search" />
                      </FormControl.Feedback>
                    </InputGroup>
                  </FormGroup>
                </div>
              </FlexElement>
              {isIncoming && (
                <FlexElement className="rightPadding">
                  <LexButton
                    disabled={
                      !selectedContactString ||
                      sortedDataList._data.length === 0
                    }
                    href={ApiService.getEntryExportPath(
                      selectedContact.id,
                      isIncoming
                    )}
                  >
                    <AxoLocal
                      entity="axoAccounting4"
                      defaultValue={"Eksporter"}
                    />
                  </LexButton>
                </FlexElement>
              )}
            </React.Fragment>
          ) : null}
        </Flexbox>
        {confirmPosting && (
          <>
            <Flexbox collapseSM className="bottomPadding" responsive>
              <FlexElement className="rightPadding largeText">
                Bogfør posteringer?
              </FlexElement>
            </Flexbox>
            <Flexbox className="bottomPadding">
              <FlexElement className="rightPadding">
                <ButtonToolbar>
                  <AsyncButton onClick={this.doFinalizeDrafts}>
                    <AxoLocal
                      entity="SidebarRightContainerConfirmPassword"
                      defaultValue={"Bekræft"}
                    />
                  </AsyncButton>
                  <LexButton
                    onClick={() => this.setState({ confirmPosting: false })}
                  >
                    <AxoLocal
                      entity="axoAccounting22"
                      defaultValue={"Fortryd"}
                    />
                  </LexButton>
                </ButtonToolbar>
              </FlexElement>
            </Flexbox>
          </>
        )}
        {confirmBankBalancing && (
          <Flexbox className="bottomPadding" responsive>
            <FlexElement className="rightPadding largeText">
              <AxoLocal
                entity="axoEntityidcode32"
                defaultValue={"Vælg periode for bankafstemning"}
              />
            </FlexElement>
            <FlexElement className="rightPadding">
              <div style={{ maxWidth: "125px" }}>
                <AxoDateTime
                  utc
                  value={startDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectStartDate}
                  isValidDate={(currentDate) =>
                    !currentDate.isAfter(endDateSelection)
                  }
                />
              </div>
            </FlexElement>
            <FlexElement className="rightPadding">
              <div style={{ maxWidth: "125px" }}>
                <AxoDateTime
                  utc
                  value={endDateSelection}
                  dateFormat={true}
                  timeFormat={false}
                  onChange={onSelectEndDate}
                  isValidDate={(currentDate) =>
                    !currentDate.isBefore(startDateSelection)
                  }
                />
              </div>
            </FlexElement>
            <FlexElement className="rightPadding">
              <ButtonToolbar>
                <Link to="Bank">
                  <LexButton>
                    <AxoLocal
                      entity="CalendarEventBoxFormStart"
                      defaultValue={"Start"}
                    />
                  </LexButton>
                </Link>
                <LexButton
                  onClick={() => this.setState({ confirmBankBalancing: false })}
                >
                  <AxoLocal entity="axoAccounting22" defaultValue={"Fortryd"} />
                </LexButton>
              </ButtonToolbar>
            </FlexElement>
          </Flexbox>
        )}
        <div className="text-center">{this.getFileErrorMessage()}</div>
        <div>
          {multiPageUpload ? (
            <div className="leftPadding">
              <span style={{ fontSize: "18px" }}>
                {cachedPageFiles.length}
                &nbsp;
                <AxoLocal
                  entity="axoidcode135"
                  defaultValue={"sider uploadet."}
                />
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <AsyncButton
                disabled={cachedPageFiles.length === 0}
                onClick={this.finishMultiPageUpload}
                hideOkIcon
              >
                <AxoLocal
                  entity="axoidcode136"
                  defaultValue={"Kombiner sider"}
                />
              </AsyncButton>
            </div>
          ) : null}
          {!!fiscalYears && selectedContact.id && fiscalYears.length === 0 ? (
            <div>
              <span style={{ fontSize: "18px" }}>
                <Link to="Setup">
                  {!fixedClient ? (
                    <>
                      <AxoLocal
                        entity="axoidcode67"
                        defaultValue={"Opret et regnskabsår for"}
                      />
                      &nbsp;{selectedContact.firstName}{" "}
                      {selectedContact.lastName}
                    </>
                  ) : (
                    <>
                      <AxoLocal
                        entity="axoidcode215"
                        defaultValue={"Opret et regnskabsår"}
                      />
                    </>
                  )}
                </Link>
              </span>
            </div>
          ) : null}
          {showInvalidDatesWarning ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode5"
                  defaultValue={
                    "Posteringerne skal ligge indenfor et gyldigt regnskabsår."
                  }
                />
              </span>
            </div>
          ) : null}
          {!!fiscalYears &&
          !!selectedContact.id &&
          showMissingCurrentFiscalYearError ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode67"
                  defaultValue={"Opret et regnskabsår for"}
                />{" "}
                {moment.utc().year()}
              </span>
            </div>
          ) : null}
          {showLockedFiscalYearsWarning ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode6"
                  defaultValue={
                    " Regnskabsåret er låst. Aktiver regnskabsåret for at fortsætte bogføringen."
                  }
                />
              </span>
            </div>
          ) : null}
          {showImbalanceWarning ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode7"
                  defaultValue={"Kredit og Debet er ikke i balance."}
                />
              </span>
            </div>
          ) : null}
          {vatErrorEntries.size > 0 ? (
            <div className="axored">
              <span style={{ fontSize: "18px" }}>
                <AxoLocal
                  entity="axoidcode8"
                  defaultValue={
                    "Der er postereret moms på en konto uden momskode. Fjern momsbeløbet eller vælg en konto med moms."
                  }
                />
                &nbsp;
                <LexButton
                  onClick={() => this.setState({ vatErrorEntries: new Set() })}
                >
                  <AxoLocal
                    entity="AlertshowModaldialogOk"
                    defaultValue={"OK"}
                  />
                </LexButton>
              </span>
            </div>
          ) : null}
          {!!fiscalYears && !!selectedContact.id && fiscalYears.length > 0 ? (
            <div>
              <div className="axobg" style={{ marginBottom: "5px" }}>
                <Flexbox spaceBetween alignCenter style={{ flexWrap: "wrap" }}>
                  <FlexElement>
                    <Flexbox responsive alignCenter>
                      <FlexElement className="rightPadding">
                        <AsyncButton
                          onClick={() => this.onAddEntry(true)}
                          hideOkIcon
                          disabled={readonly}
                        >
                          Tilføj indtægt&nbsp;
                          <Icon role="button" glyph="icon-fontello-plus-3" />
                        </AsyncButton>
                      </FlexElement>
                      <FlexElement className="rightPadding">
                        <AsyncButton
                          onClick={() => this.onAddEntry(false)}
                          hideOkIcon
                          disabled={readonly}
                        >
                          Tilføj udgift&nbsp;
                          <Icon role="button" glyph="icon-fontello-plus-3" />
                        </AsyncButton>
                      </FlexElement>
                      <FlexElement className="rightPadding">
                        <LexButton
                          style={{ minWidth: "150px" }}
                          disabled={this.disableAddition()}
                          onClick={this.onUploadIncome}
                        >
                          {this.getButtonLabel(
                            <AxoLocal
                              entity="updategetAccountName6"
                              defaultValue={"Upload indtægt"}
                            />,
                            uploadingIncome,
                            scanningIncome
                          )}
                          <LoadingIcon
                            show={uploadingIncome || scanningIncome}
                          />
                        </LexButton>
                      </FlexElement>
                      <FlexElement className="rightPadding">
                        <LexButton
                          style={{ minWidth: "150px" }}
                          disabled={this.disableAddition()}
                          onClick={this.onUploadExpense}
                        >
                          {this.getButtonLabel(
                            <AxoLocal
                              entity="updategetAccountName7"
                              defaultValue={"Upload udgift"}
                            />,
                            uploadingExpense,
                            scanningExpense
                          )}
                          <LoadingIcon
                            show={uploadingExpense || scanningExpense}
                          />
                        </LexButton>
                      </FlexElement>
                      <FlexElement className="rightPadding">
                        <AsyncButton
                          disabled={selectedEntries.size === 0 || readonly}
                          onClick={this.onDeleteSelectedEntries}
                        >
                          <AxoLocal
                            entity="updategetAccountName7a"
                            defaultValue={"Slet valgte"}
                          />
                          {isTrashCan && (
                            <>
                              {" "}
                              <AxoLocal
                                entity="sletpermanent1"
                                defaultValue={"permanent"}
                              />{" "}
                            </>
                          )}
                        </AsyncButton>
                      </FlexElement>
                      {isTrashCan && (
                        <>
                          <FlexElement className="rightPadding">
                            <AsyncButton
                              disabled={selectedEntries.size === 0 || readonly}
                              onClick={this.onRestoreSelectedeEntries}
                            >
                              <AxoLocal
                                entity="DocumentTrashTableViewrestore"
                                defaultValue={"Gendan"}
                              />
                            </AsyncButton>
                          </FlexElement>
                        </>
                      )}
                      {!isTrashCan && (
                        <>
                          {isNormal && (
                            <>
                              <FlexElement className="axoFrontPage rightPadding">
                                <AsyncButton
                                  onClick={this.onFinalizeDrafts}
                                  hideOkIcon
                                  disabled={
                                    !selectedContact.id ||
                                    uploadingIncome ||
                                    uploadingExpense ||
                                    scanningIncome ||
                                    entries.length === 0 ||
                                    hasMissingAccount ||
                                    // || hasMissingAmount
                                    confirmPosting ||
                                    readonly
                                  }
                                >
                                  <AxoLocal
                                    entity="axoidcode94"
                                    defaultValue={"Bogfør kladde"}
                                  />
                                </AsyncButton>
                              </FlexElement>
                            </>
                          )}
                        </>
                      )}
                      {!!confirmDeleteEntryId ? (
                        <FlexElement>
                          <Flexbox>
                            <FlexElement className="largeText axored rightPadding">
                              {!isTrashCan ? (
                                <>
                                  <AxoLocal
                                    entity="axoEntityidcode36"
                                    defaultValue={
                                      "Flyt posteringen til skraldespanden?"
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <AxoLocal
                                    entity="axoidcode221"
                                    defaultValue={
                                      "Dette vil slette posteringen permanent"
                                    }
                                  />
                                </>
                              )}
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <LexButton
                                disabled={!!deletingId}
                                onClick={this.doDeleteSelectedEntry}
                              >
                                <AxoLocal
                                  entity="friendRequestTimelineonApproveRequest"
                                  defaultValue={"Godkend"}
                                />{" "}
                                <LoadingIcon show={!!deletingId} />
                              </LexButton>
                            </FlexElement>
                            <FlexElement>
                              <LexButton
                                disabled={!!deletingId}
                                onClick={() =>
                                  this.setState({ confirmDeleteEntryId: 0 })
                                }
                              >
                                <AxoLocal
                                  entity="axoAccounting22"
                                  defaultValue={"Fortryd"}
                                />
                              </LexButton>
                            </FlexElement>
                          </Flexbox>
                        </FlexElement>
                      ) : null}
                      {!!confirmDeleteSelected ? (
                        <FlexElement>
                          <Flexbox>
                            <FlexElement className="largeText axored rightPadding">
                              {!isTrashCan ? (
                                <>
                                  <AxoLocal
                                    entity="axoEntityidcode37"
                                    defaultValue={
                                      "Flyt valgte posteringer til skraldespanden?"
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <AxoLocal
                                    entity="axoidcode223"
                                    defaultValue={
                                      "Dette vil slette de valgte posteringer permanent."
                                    }
                                  />
                                </>
                              )}
                            </FlexElement>
                            <FlexElement className="rightPadding">
                              <AsyncButton
                                hideOkIcon
                                onClick={this.doDeleteSelectedEntries}
                              >
                                <AxoLocal
                                  entity="friendRequestTimelineonApproveRequest"
                                  defaultValue={"Godkend"}
                                />
                              </AsyncButton>
                            </FlexElement>
                            <FlexElement>
                              <LexButton
                                onClick={() =>
                                  this.setState({
                                    confirmDeleteSelected: false,
                                  })
                                }
                              >
                                <AxoLocal
                                  entity="axoAccounting22"
                                  defaultValue={"Fortryd"}
                                />
                              </LexButton>
                            </FlexElement>
                          </Flexbox>
                        </FlexElement>
                      ) : null}
                      {documentsToBeUploaded > 0 ? (
                        <FlexElement className="mediumText rightPadding">
                          &nbsp;({documentsUploaded + 1} /{" "}
                          {documentsToBeUploaded})
                          <LoadingIcon show={true} />
                        </FlexElement>
                      ) : null}
                    </Flexbox>
                  </FlexElement>
                </Flexbox>
              </div>
              {forceShowCards ? (
                this.renderGridView()
              ) : (
                <div>
                  <ExtraSmall>{this.renderGridView()}</ExtraSmall>
                  <SmallOrLarger>
                    <Flexbox>
                      <FlexElement>
                        {this.renderTable({
                          allowSpaceForPicture: !!selectedEntryWithReceipt,
                          hasMissingAccount,
                        })}
                      </FlexElement>
                      {!!selectedEntryWithReceipt ? (
                        <FlexElement flexGrow={1} className="leftPadding">
                          <FileViewer file={selectedEntryWithReceipt.receipt} />
                        </FlexElement>
                      ) : null}
                    </Flexbox>
                  </SmallOrLarger>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <FileViewerModal ref={(c) => (this.fileModal = c)} />
        <TextFilterModal ref={this.textFilterModal} />
        <NumberFilterModal ref={this.numberFilterModal} />
        <DateFilterModal ref={this.dateFilterModal} />
        <PrintReceiptsModal
          entries={entries
            .filter((e) => !!e.receiptNumber)
            .sort((e1, e2) => e1.receiptNumber - e2.receiptNumber)}
          selectedContact={selectedContact}
          ref={(c) => (this.receiptsModal = c)}
        />
        {multiPageUpload ? (
          <React.Fragment>
            <input
              name="income"
              onChange={this.onIncomeSelected}
              type="file"
              accept="image/*"
              multiple
              id="incomeUpload"
              style={{ display: "none" }}
            />
            <input
              name="expense"
              onChange={this.onExpenseSelected}
              type="file"
              accept="image/*"
              multiple
              id="expenseUpload"
              style={{ display: "none" }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input
              name="income"
              onChange={this.onIncomeSelected}
              type="file"
              accept="image/*,.pdf"
              multiple
              id="incomeUpload"
              style={{ display: "none" }}
            />
            <input
              name="expense"
              onChange={this.onExpenseSelected}
              type="file"
              accept="image/*,.pdf"
              multiple
              id="expenseUpload"
              style={{ display: "none" }}
            />
          </React.Fragment>
        )}

        <input
          name="receipt"
          onChange={this.onReceiptSelected}
          type="file"
          accept="image/*,.pdf"
          id="receiptUpload"
          style={{ display: "none" }}
        />
        <input
          name="add"
          ref={this.addPagesControl}
          onChange={this.onPageSelected}
          type="file"
          accept="image/*,.pdf"
          multiple
          id="addPages"
          style={{ display: "none" }}
        />
        <input
          name="csv"
          onChange={onCSVChange}
          type="file"
          accept=".csv"
          id="csvUpload"
          style={{ display: "none" }}
        />
      </AxoDropzone>
    );
  }

  handleFileDrop = (files) => {
    if (this.isReadOnly()) {
      return;
    }

    this.processAllReceipts(files, false);
  };

  formatAmount(amount) {
    if (!amount) {
      return "0.00";
    }
    return NumberService.formatDecimal(amount);
  }
}

export default Dimensions({
  elementResize: true,
  getHeight: function () {
    return window.innerHeight - 250;
  },
})(BookkeepingView);
