import React from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import ClientDocumentGridView from "./ClientDocumentGridView";
import Conditional from "../../utilities/Conditional";
import { ExtraSmall, SmallOrLarger } from "../../utilities/Responsive";
import moment from "moment";
import * as $ from "jquery";
import { withRouter } from "react-router-dom";

import {
  Row,
  Col,
  Grid,
  FormControl,
  FormGroup,
  Button,
  InputGroup,
} from "react-bootstrap";

import {
  DataStore,
  DataActions,
  RoutingService,
  ModalService,
  FileInfoService,
  MessageService,
} from "../../services/AxoServices";

import {
  Icon,
  AlertModal,
  ConfirmModal,
  FileViewerModal,
  TableBase,
  InlineEdit,
  SortHeaderCell,
  DataListWrapper,
  AxoLocal,
  Dimensions,
  LoadingIcon,
  LexButton,
} from "../../utilities/LexUtilities";

const defaultColumnDefs = {
  select: {
    width: 50,
    shown: true,
  },
  fileName: {
    width: 250,
    shown: true,
  },
  // fileType: {
  //   width: 150,
  //   shown: true,
  // },
  signingRequest: {
    width: 50,
    shown: true,
  },
  signingStatus: {
    width: 100,
    shown: true,
  },
  notes: {
    width: 200,
    shown: true,
  },
  uploadDate: {
    width: 100,
    shown: true,
  },
  casesCol: {
    width: 170,
    shown: true,
  },
  send: {
    width: 200,
    shown: true,
  },
  delete: {
    width: 50,
    shown: true,
  },
};

class ClientDocumentTableComponent extends TableBase {
  constructor(props) {
    super(props);
    this.name = "ClientDocumentTableComponent";
    this.defaultColumnDefs = defaultColumnDefs;

    this._defaultSortIndexes = this.generateDefaultSortIndexes(props.documents);
    let tableEntries = this.addDataStringsToDocuments(props.documents);
    this.state = {
      tableEntries: tableEntries,
      selectedDocuments: new Set(),
      newLabelName: "",
      columnDefs: this.retrieveColumnDefs(),
      minColWidths: {
        status: 110,
        priority: 100,
        casesCol: 110,
        send: 100,
        delete: 50,
      },
      maxColumnWidth: 300,
      sortedDataList: new DataListWrapper(
        this._defaultSortIndexes,
        tableEntries
      ),
      colSortDirs: {},
      searchText: "",
      uploading: false,
      validationState: {
        showFileUploadedMessage: false,
        showFileMaxSizeWarning: false,
        showFileUploadError: false,
        showStorageWarning: false,
        showPdfWarning: false,
        deletingdocumentId: 0,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    let tableEntries = this.addDataStringsToDocuments(nextProps.documents);
    let filteredEntries = this.getFilteredDocuments(
      tableEntries,
      this.state.searchText
    );

    this.updateEntries(tableEntries, filteredEntries);
  }

  addDataStringsToDocuments = (documents) => {
    documents.forEach((document) => {
      document.labelString = "";
      document.labels.map((label) => {
        document.labelString += label.name + ", ";
        return true;
      });

      document.nameString = document.fileName;
      var lastPeriod = document.fileName.lastIndexOf(".");
      if (lastPeriod > -1) {
        document.nameString = document.fileName.substring(
          0,
          document.fileName.lastIndexOf(".")
        );
      }
      document.nameString =
        document.nameString.length > 30
          ? document.nameString.substring(0, 30) + "..."
          : document.nameString;

      return true;
    });
    return documents;
  };

  getRelationIcon(documentId) {
    var { userProfile } = this.props;
    if (userProfile.logo && userProfile.logo.id === documentId) {
      return <span style={{ fontSize: "20px" }}>&#9733; </span>;
    }
    if (
      userProfile.profileImage &&
      userProfile.profileImage.id === documentId
    ) {
      return (
        <span style={{ fontSize: "20px" }}>
          <Icon glyph="icon-fontello-user-7" />{" "}
        </span>
      );
    }
  }

  onTrashFile = (documentId) => {
    this.props.onTrashFile(documentId);
  };

  onRestoreFile = (documentId) => {
    this.props.onRestoreFile(documentId);
  };

  onSendFile = (documentId) => {
    this.props.onSendFile(documentId);
  };

  onClickFileName = (document, event) => {
    event.preventDefault();
    this.props.onActivate(document.id);
  };

  onClickSigningRequest = (guid, event) => {
    event.preventDefault();
    this.props.goToSigningRequest(guid);
  };

  renderDocumentTypeField = (document) => {
    var fileType = document.fileType.substring(1, document.fileType.length);
    if (fileType === "pdf") {
      return <Icon glyph="icon-fontello-acrobat" />;
    }
    if (fileType === "doc" || fileType === "docx") {
      return (
        <span>
          {" "}
          &nbsp; <Icon glyph="icon-fontello-doc-7" />
        </span>
      );
    }
    if (FileInfoService.isImage(document)) {
      return (
        <span>
          {" "}
          &nbsp; <Icon glyph="icon-fontello-picture-1" /> {fileType}
        </span>
      );
    }
    return fileType;
  };

  onSearch = (event) => {
    let { tableEntries } = this.state;

    let documents = this.getFilteredDocuments(tableEntries, event.target.value);
    this._defaultSortIndexes = this.generateDefaultSortIndexes(documents);
    this.setState({
      searchText: event.target.value,
      sortedDataList: new DataListWrapper(this._defaultSortIndexes, documents),
    });
  };

  getFilteredDocuments = (documents, searchText) => {
    if (!searchText) {
      return documents;
    }
    return documents.filter((d) => {
      return d.fileName.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  _rowClassNameGetter = (rowIndex) => {
    var activeDocument = this.state.sortedDataList.getObjectAt(rowIndex);
    if (activeDocument && activeDocument.id === this.props.activeDocumentId) {
      return "highlight-row";
    }
  };

  onUploadFiles = () => {
    var component = this;
    $("#documentUpload").trigger("click");
    $("#documentUpload").on("change", function () {
      if (this.files.length === 0) {
        return;
      }
      try {
        var file = this.files[0];
        if (file.size / Math.pow(1024, 2) > 50) {
          component.showMessage("showFileMaxSizeWarning");
          return;
        }

        component.setState({ uploading: true });
        DataActions.uploadDocuments(this.files, { validatePDF: true })
          .then((response) => {
            component.setState({ uploading: false });
            if (response.ok) {
              component.showMessage("showFileUploadedMessage");
            } else if (response.status === 400) {
              response.text().then((text) => {
                if (text === "Storage") {
                  component.showMessage("showStorageWarning");
                } else if (text === "CorruptedPDF") {
                  component.showMessage("showPdfWarning");
                  ModalService.openAlertModal(
                    <>
                      {/* MissingEntity */}
                      <div>PDF filen er korrumperet.</div>
                      <div>
                        Dette kan ofte afhjælpes ved at åbne filen i Adobe PDF
                        læseren og gemme den på ny.
                      </div>
                    </>
                  );
                } else {
                  component.showMessage("showFileUploadError");
                }
              });
            } else {
              component.showMessage("showFileUploadError");
            }
          })
          .catch((reason) => {
            console.log(reason);
            component.showMessage("showFileUploadError");
            component.setState({ uploading: false });
          });
      } finally {
        $("#documentUpload").off("change");
        this.value = ""; //onChange handler should be triggered when uploading the same file twice.
      }
    });
  };

  showMessage = (propertyName) => {
    var validationState = Object.assign({}, this.state.validationState);
    validationState[propertyName] = true;
    this.setState({ validationState });

    setTimeout(() => {
      var validationState = Object.assign({}, this.state.validationState);
      validationState[propertyName] = false;
      this.setState({ validationState });
    }, 3000);
  };

  renderFileButtonContent = () => {
    let { uploading, validationState } = this.state;
    if (validationState.showFileMaxSizeWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarning"
            defaultValue={"Max 50 mb"}
          />
        </div>
      );
    }
    if (validationState.showStorageWarning) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSizeWarningA"
            defaultValue={"Ikke mere plads. Slet filer."}
          />
        </div>
      );
    }
    if (validationState.showPdfWarning) {
      return (
        <div className="axored" style={{ background: "white" }}>
          PDF filen er korrumperet.
        </div>
      );
    }
    if (validationState.showFileUploadError) {
      return (
        <div className="axored">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesError"
            defaultValue={"Upload fejlede"}
          />
        </div>
      );
    }
    if (validationState.showFileUploadedMessage) {
      return (
        <div className="axoblue">
          <AxoLocal
            entity="ClientDocumentTableUploadFilesSuccess"
            defaultValue={"Filen blev uploaded"}
          />
        </div>
      );
    }
    return (
      <span>
        <Icon glyph="icon-fontello-upload-4" />
        &nbsp; &nbsp;
        <AxoLocal
          entity="ClientDocumentTableUploadFiles"
          defaultValue={"Upload filer"}
        />
        <LoadingIcon show={uploading} />
      </span>
    );
  };

  onChangeNotes = (document, input) => {
    document.notes = input.value;
    DataActions.updateDocument(document);
  };

  render() {
    const { forceShowCards } = this.props;
    let { uploading } = this.state;

    return (
      <div>
        <div>
          <div className="axobg" style={{ marginBottom: "5px" }}>
            <Grid fluid>
              <Row>
                <Col sm={3}>
                  <h4>
                    <Icon glyph="icon-fontello-doc-7" />
                    &nbsp;&nbsp;
                    <AxoLocal
                      entity="DocumentTableFixdViewdocuments"
                      defaultValue={"Dokumenter"}
                    />
                  </h4>
                </Col>
                <Conditional show={this.props.showUploadButton}>
                  <Col sm={3} style={{ paddingTop: "10px" }}>
                    <Button
                      disabled={uploading}
                      className="Lex-button-2"
                      onClick={this.onUploadFiles}
                    >
                      {this.renderFileButtonContent()}
                    </Button>
                  </Col>
                </Conditional>
                <Col sm={3}>
                  <div style={{ maxWidth: "600px", paddingTop: "10px" }}>
                    <FormGroup controlId="inputWithIcon">
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon glyph="icon-fontello-search" />
                        </InputGroup.Addon>
                        {/* Søg...  */}
                        <AxoLocal
                          componentClass={FormControl}
                          type="text"
                          value={this.state.searchText}
                          onChange={this.onSearch}
                          componentAttribute="placeholder"
                          entity="ContactTableViewplaceholderonSearch"
                        />
                        <FormControl.Feedback>
                          <Icon glyph="icon-fontello-search" />
                        </FormControl.Feedback>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
                <Col
                  sm={3}
                  className="text-right"
                  style={{ paddingTop: "10px" }}
                >
                  {/* <Button className='Lex-button-2' onClick={this.props.onToggleCards}>
                  { this.props.forceShowCards ?  <span> <Icon glyph="icon-fontello-grid"/>&nbsp;&nbsp;
                  <AxoLocal key='showTable' entity='CaseDataTableFixedShowastable'defaultValue={'Vis som tabel'}/> </span> 
                        :  <span> <Icon glyph="icon-fontello-th-thumb-empty"/>&nbsp;&nbsp;
                        <AxoLocal key='showCards' entity='CaseDataTableFixedShowCards'defaultValue={'Vis som kartotekskort'}/></span> }
                </Button> */}
                </Col>
              </Row>
            </Grid>
          </div>
          {forceShowCards ? (
            this.renderGridView()
          ) : (
            <div>
              <ExtraSmall>{this.renderGridView()}</ExtraSmall>
              <SmallOrLarger>{this.renderTable()}</SmallOrLarger>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderGridView = () => {
    return (
      <div>
        <ClientDocumentGridView
          documents={this.state.sortedDataList._data}
          activeDocumentId={this.props.activeDocumentId}
          onDocumentClick={this.onClickFileName}
          l
          onTrashFile={this.props.onTrashFile}
          onSendFile={this.props.onSendFile}
        />
      </div>
    );
  };

  onSignFile = (document) => {
    this.props.history.push(`SigningRequest/${document.id}?showSending=true`);
  };

  onShowSigners = (document) => {
    this.props.history.push(`SigningRequest/${document.id}?showSending=false`);
  };

  renderTable = () => {
    let { sortedDataList, colSortDirs, columnDefs } = this.state;
    const { containerHeight, containerWidth } = this.props;
    let tableHeight = this.props.height || containerHeight;
    let tableWidth = Math.max(750, containerWidth - 25);

    return (
      <div style={{ paddingTop: "0px" }}>
        <Grid fluid>
          <Row style={{ paddingLeft: "10px" }}>
            <Col xs={12} style={{ padding: "0px" }}>
              <Table
                rowHeight={40}
                rowsCount={sortedDataList.getSize()}
                rowClassNameGetter={this._rowClassNameGetter}
                height={tableHeight}
                width={tableWidth}
                isColumnResizing={false}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                headerHeight={40}
              >
                <Column
                  columnKey="fileName"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-docs-1" />}
                      showAltIcon={columnDefs.fileName.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.fileName}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="CaseDataTableFixedFilename"
                        defaultValue={"Filnavn"}
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={document.id} {...props}>
                        <a
                          href="document"
                          onClick={this.onClickFileName.bind(this, document)}
                        >
                          &nbsp;{document.nameString}
                          {this.getRelationIcon(document.id)}
                        </a>
                      </Cell>
                    );
                  }}
                  width={columnDefs.fileName.width}
                  isResizable={true}
                />
                {/* <Column
                    columnKey="fileType"
                    header={<SortHeaderCell
                        altIcon={<Icon glyph="icon-fontello-acrobat" />}
                        showAltIcon={columnDefs.fileType.width < 75}
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.fileType}>
                        <Icon className='editable' glyph="icon-fontello-arrow-combo" />&nbsp;<AxoLocal entity='DocumentGridViewType'defaultValue={'Type'}/>
                      </SortHeaderCell>}
                    cell={props => {
                      var document = sortedDataList.getObjectAt(props.rowIndex);
                      return (
                        <Cell key={document.id} {...props}>
                          {this.renderDocumentTypeField(document)}
                        </Cell> )}}
                    width={columnDefs.fileType.width}
                    isResizable={true}
                  /> */}
                <Column
                  columnKey="signingRequest"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-acrobat" />}
                      showAltIcon={columnDefs.signingRequest.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.signingRequest}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="axoEntityidcode276"
                        defaultValue="Signeringsside"
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={document.id} {...props}>
                        {document.signingRequests.length > 0 && (
                          <div>
                            <a
                              href="signingStatus"
                              onClick={this.onClickSigningRequest.bind(
                                this,
                                document.signingRequests[0].guid
                              )}
                            >
                              &nbsp;
                              <Icon glyph="icon-fontello-acrobat" />
                              &nbsp;
                            </a>
                          </div>
                        )}
                      </Cell>
                    );
                  }}
                  width={columnDefs.signingRequest.width}
                  isResizable={true}
                />
                <Column
                  columnKey="signingStatus"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-acrobat" />}
                      showAltIcon={columnDefs.signingStatus.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.signingStatus}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        key="statusleft"
                        entity="CaseEditInlineStatusSelect"
                        defaultValue={"Status"}
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    let document = sortedDataList.getObjectAt(props.rowIndex);
                    let signingRequest =
                      document.signingRequests.length > 0
                        ? document.signingRequests[0]
                        : null;
                    let signers = !!signingRequest
                      ? signingRequest.signers || []
                      : [];
                    let completedSigners = signers.filter((s) => s.signed);

                    return (
                      <Cell key={document.id} {...props}>
                        {!!signingRequest && (
                          <div
                            title={
                              "Underskrevet: (" +
                              completedSigners.length +
                              " / " +
                              signers.length +
                              ")"
                            }
                          >
                            <span
                              role="button"
                              className="axoblue"
                              onClick={() => this.onShowSigners(document)}
                            >
                              &nbsp;
                              <Icon glyph="icon-fontello-info" />
                              &nbsp;&nbsp;&nbsp;
                              {completedSigners.length} / {signers.length}
                            </span>
                          </div>
                        )}
                      </Cell>
                    );
                  }}
                  width={columnDefs.signingStatus.width}
                  isResizable={true}
                />
                <Column
                  columnKey="notes"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-acrobat" />}
                      showAltIcon={columnDefs.notes.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.notes}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="axoidcode77"
                        defaultValue={"Fritekst"}
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={document.id} {...props}>
                        &nbsp;
                        <InlineEdit
                          value={document.notes || "---"}
                          change={this.onChangeNotes.bind(this, document)}
                        />
                      </Cell>
                    );
                  }}
                  width={columnDefs.notes.width}
                  isResizable={true}
                />
                <Column
                  columnKey="uploadDate"
                  header={
                    <SortHeaderCell
                      altIcon={<Icon glyph="icon-fontello-calendar-8" />}
                      showAltIcon={columnDefs.uploadDate.width < 75}
                      onSortChange={this._onSortChange}
                      sortDir={colSortDirs.uploadDate}
                    >
                      <Icon
                        className="editable"
                        glyph="icon-fontello-arrow-combo"
                      />
                      &nbsp;
                      <AxoLocal
                        entity="DocumentGridViewuploadDate"
                        defaultValue={"Upload dato"}
                      />
                    </SortHeaderCell>
                  }
                  cell={(props) => {
                    var document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={document.id} {...props}>
                        &nbsp;{moment(document.uploadDate).format("L")}
                      </Cell>
                    );
                  }}
                  width={columnDefs.uploadDate.width}
                  isResizable={true}
                />
                <Column
                  columnKey="send"
                  header={
                    <Cell>
                      <AxoLocal
                        entity="currentMessageisSendButtonContent"
                        defaultValue="Send"
                      />{" "}
                      til signering
                    </Cell>
                  }
                  cell={(props) => {
                    var document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={document.id} {...props}>
                        <LexButton onClick={() => this.onSignFile(document)}>
                          &nbsp;
                          <AxoLocal
                            key="icon"
                            componentClass={Icon}
                            glyph="icon-fontello-mail-1"
                            componentAttribute="title"
                            entity="ClientDocumentTableSendFileToLawyer"
                          />
                          &nbsp;
                          <AxoLocal
                            entity="axoEntityidcode218"
                            defaultValue={"Send til signering"}
                          />
                        </LexButton>
                      </Cell>
                    );
                  }}
                  width={columnDefs.send.width}
                  isResizable={true}
                />
                <Column
                  columnKey="delete"
                  header={
                    <Cell>
                      <AxoLocal entity="axoidcode179" defaultValue="Slet" />
                    </Cell>
                  }
                  cell={(props) => {
                    var document = sortedDataList.getObjectAt(props.rowIndex);
                    return (
                      <Cell key={document.id} {...props}>
                        &nbsp;
                        {this.props.deletingId === document.id ? (
                          <img
                            alt=""
                            src="/imgs/app/loading.gif"
                            className="img-circle"
                            width="20"
                            height="20"
                          />
                        ) : (
                          <Icon
                            role="button"
                            onClick={this.onTrashFile.bind(this, document.id)}
                            className="editable"
                            glyph="icon-fontello-trash-1"
                          />
                        )}
                      </Cell>
                    );
                  }}
                  width={columnDefs.delete.width}
                  isResizable={true}
                />
              </Table>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
}

class ClientDocumentTableView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deletingId: 0,
    };
  }

  onGetFile = (id) => {
    var selectedFile = this.props.documents.find(
      (file) => file.id === parseInt(id, 10)
    );
    this.fileModal.open([selectedFile]);
  };

  removeImageReference = (userProfile, documentId) => {
    if (userProfile.logo && userProfile.logo.id === documentId) {
      userProfile.logo = null;
    }
    if (
      userProfile.profileImage &&
      userProfile.profileImage.id === documentId
    ) {
      userProfile.profileImage = null;
    }
    DataActions.updateUserProfile(userProfile);
  };

  onTrashFile = (documentId) => {
    this.setState({ deletingId: documentId });
    let document = this.props.documents.find(
      (d) => d.id === parseInt(documentId, 10)
    );
    if (document.trashed) {
      this.deleteFile(document.id);
    }
    let newDoc = { ...document };
    newDoc.trashed = true;
    DataActions.updateDocument(newDoc).then((response) => {
      if (!response.ok) {
        this.setState({ deletingId: 0 });
      }
    });
  };

  deleteFile = (documentId) => {
    ModalService.openConfirmModal(
      <AxoLocal
        entity="CaseDataTableFixedopenConfirmModal"
        defaultValue={"Vil du slette dokumentet permanent?"}
      />,
      (value) => {
        if (value) {
          DataActions.deleteDocument(documentId);
        }
      }
    );
  };

  onRestoreFile = (documentId) => {
    let document = {
      ...this.props.documents.find((d) => d.id === parseInt(documentId, 10)),
    };
    document.trashed = false;
    DataActions.updateDocument(document);
  };

  onCreateLabel = () => {
    this.props.history.push(RoutingService.getPath("DocumentLabels/Create"));
  };
  onGoToLabels = () => {
    this.props.history.push(RoutingService.getPath("DocumentLabels"));
  };
  onGoToCase = (caseId) => {
    this.props.history.push(RoutingService.getPath("Cases/case/" + caseId));
  };
  onSendFile = (documentId) => {
    var document = this.props.documents.find((d) => d.id === documentId);
    DataStore.setCachedMessage(MessageService.makeAttachmentMessage(document));
    this.props.history.push(RoutingService.getPath("mailbox/compose/"));
  };

  goToSigningRequest = (guid) => {
    this.props.history.push("Signing/" + guid);
  };

  renderDocumentTableComponent = () => {
    return (
      <ClientDocumentTableComponent
        {...this.props}
        onActivate={this.onGetFile}
        onTrashFile={this.onTrashFile}
        onRestoreFile={this.onRestoreFile}
        onSendFile={this.onSendFile}
        deletingId={this.state.deletingId}
        goToSigningRequest={this.goToSigningRequest}
      />
    );
  };

  render() {
    return (
      <Row>
        <Col xs={12} style={{ paddingTop: "10px" }}>
          {this.renderDocumentTableComponent()}
          <FileViewerModal size="large" ref={(c) => (this.fileModal = c)} />
          <input
            name="document"
            type="file"
            accept="application/pdf"
            multiple
            id="documentUpload"
            style={{ display: "none" }}
          />
          <AlertModal ref={(c) => (this.alertModal = c)} />
          <ConfirmModal ref={(c) => (this.confirmModal = c)} />
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  Dimensions({
    getHeight: function (element) {
      return window.innerHeight - 225;
    },
  })(ClientDocumentTableView)
);
